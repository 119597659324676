import React,{ useEffect, useState} from 'react';
import axios from 'axios';
import Navbar from '../components/navbar';
import { API_BASE_URL } from '../apiConfig';
import { Link } from "react-router-dom";
export default function CompoTable(){



    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };


    const [candidat, setCandidat] = useState([]) 
    
        
    
    useEffect(()=>{
        
        axios.get(API_BASE_URL +'/compoTable', { headers})
        .then(res => setCandidat(res.data))
        .catch(err => console.log(err));
    }, [])
    
     const handleDelete = async(id) => {
       try {
        await axios.delete(API_BASE_URL +'/candidat/'+id, { headers})
        window.location.reload()
       }catch(err) {
        console.log(err);
       }
     }

     const [query, setQuery]= useState(""); 
   
         
    

    return(
        <>
        
        
        
        
        <div className="search">
             <input type="text" className="search" name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value.toLowerCase())}></input>
        </div>
            
        


    <div className=''>
        <div className=''>
             <Link to="/create" className='menu-bars'> Ajouter un candidat </Link>
             <table className='table' >
                 <thead >
                    <tr>
                    <th>id</th>
                    <th>Nom</th>
                    <th>Prenom</th>
                    <th>Contact</th>
                    <th>Sexe</th>
                    <th>Age</th>
                    <th>Adresse</th>
                    <th>Montant payer</th>
                    <th>Date</th>
                    <th>Statut_Payement</th>
                    <th>Resultat_code</th>
                    <th>Resultat_conduite</th>
                    <th>Observation</th>
                    <th>Action</th>
                    </tr>
                 </thead>
                 <tbody>
                    
                      {
                        candidat.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).map((data, i)=> (
                            <tr >
                                <td>{data.id}</td>
                                <td>{data.nom}</td>
                                <td>{data.prenom}</td>
                                <td>{data.contact}</td>
                                <td>{data.sexe}</td>
                                <td>{data.age}</td>
                                <td>{data.adresse}</td>
                                <td>{data.montant_payer}</td>
                                <td>{data.date}</td>
                                <td>{data.statut_payement}</td>
                                <td>{data.resultat_code}</td>
                                <td>{data.resultat_conduite}</td>
                                <td>{data.observation}</td>
                                <td>
                                
                                
                               <button className='bs'> <Link to={'/update/'+ data.id} >Modifier</Link></button>
                                    <button onClick={e => handleDelete(data.id)} className='bx'>Supprimer</button>
                                </td>
                            </tr>
                        ))
                      }
                 </tbody>
             </table>
        </div>
        
    </div>
    
    </>
    )
}