import {Routes,Route} from 'react-router-dom';

import Auth from './pages/auth';
import AuthV from './pages/authV';
import Accueil from './pages/accueil';
import './css/App.css';
import Candidat from './pages/candidat';

import Navbar from './components/navbar';
import Depence from './pages/depense';
import Payement from './pages/payement';
import Resultat from './pages/resultat';
import Create from './pages/create';
import Update from './pages/update';
import CreateDepense from './pages/createDepense';
import Depense from './pages/depense';
import UpdateDepense from './pages/updateDepense';
import UpdatePayement from './pages/updatePayement'
import CreatePayement from './pages/createPayement';
import MONI from './pages/moni';
import Presence from './pages/presence';
import Cond from './pages/cond';
import PageCandidatMoni from './pages/pageCandidatMoni';
import Observation from './pages/observation';

import CandidatDir from './pages/candidatDir';
import CompoTable from './pages/compoTable';
import DepenseDir from './pages/depenceDir';
import PayementDir from './pages/payementDir';
import SecretaireResultCode from './pages/secretaireResultCode';
import UpResult from './pages/upResultCode';
import SecretaireResultConduite from './pages/secretaireResultConduite';
import UpResultConduite from './pages/upResultConduite';
import ResultatDir from './pages/resultatDir';
import DirResult from './pages/dirResult';
import MoniResult from './pages/moniResult';
import CreateDepenseDir from './pages/createDepenceDir';
import Depense2Dir from './pages/depence2Dir';
import Bilan from './pages/bilan';
import PageCandidat from './pages/pageCandidat';
import PageCandidatPaye from './pages/pageCandidatPaye';
import VoirCond from './pages/voirCond';
import AjoutPresence from './pages/ajoutPresence';
import OldCandidat from './pages/oldCandidat';
import Code from './pages/code';
import VoirCode from './pages/voirCode';
import AjoutCode from './pages/ajoutCode';
import DirOldCand from './pages/dirOldCand';
import Versement from './components/versement';
import Accueil2 from './pages/accueil2';
import PageCandidatDir from './pages/pageCandidatDir';
import Retrait from './pages/retrait';
import LesObservations from './pages/lesObservations';
import LesObservationsDir from './pages/lesObservationsDir';
import CreateCandidatDir from './pages/createCandidatDir';
import CreatePayementDir from './pages/createPayementDir';
import Footer from './components/footer'
import Profil2 from './components/profile2';
import PageCandidatCand from './pages/pageCandidatCand'
import Option2 from './components/option2';
import Loader from './components/loader';
import OptPresenceDir from './pages/optPresenceDir';
import CondDir from './pages/condDir';
import VoirCondDir from './pages/voirCondDir';
import AjoutPresenceDir from './pages/ajoutPresenceDir';
import CodeDir from './pages/codeDir';
import VoirCodeDir from './pages/voirCodeDir';
import AjoutCodeDir from './pages/ajoutCodeDir';
import UpdateDepenseDir from './pages/updateDepenceDir';
import UpdatePayementDir from './pages/updatePayementDir';
import DirResultCond from './pages/dirResultCond';
import UpDirResultCode from './pages/upDirResultCode';
import UpDirResultCond from './pages/upDirResultCond';
import Inscrit from './pages/inscrit';
import Admis from './pages/admis';
import AdmisNon from './pages/admisNon';
import StatDepenses from './pages/statDepenses';
import StatPaye from './pages/statPaye';
import Permis from './pages/permis';
import AdmisDir from './pages/admisDir';
import AdmisNonDir from './pages/admisNonDir';
import PermisDir from './pages/permisDir';
import ProtectedRoute from './components/protectedRoute';
import CandidatesPage from './pages/navTabs';
import CandidatesPageDir from './pages/navTabsDir';
import CandidatesPageOui from './pages/ouiNavTabs';
import OuiCandidatesPageDir from './pages/ouiNavTabsDir';
import Employe from './pages/employe';
import CreateDepenseDirFixe from './pages/createDepenseDirFixe';
import UpdateDepenseFixe from './pages/updateDepFixe';
/* import TelechargementPDF from './pages/telechargement'; */
function App() {
  return (
    <>
   
    
    <Routes>



      
    <Route path='/ath' element={<Auth/>}/>
          <Route path='/' element={<AuthV/>}/>
         
          <Route path='/accueil' element={<Accueil/>}/>
          <Route path='/pageCandidat/:id' element={<PageCandidat/>}/>
          <Route path='/navbar' element={<Navbar/>}/>
          <Route path='/moni' element={<MONI/>}/>
          <Route path='/compoTable' element={<CompoTable/>}/>
          <Route path='/observation/:id' element={<Observation/>}/>
          <Route path='/loader' element={<Loader/>}/>
          <Route path='/pageCandidatPaye/:id' element={<PageCandidatPaye/>}/>
          <Route path='/oldCandidat'  element={<OldCandidat/>}/>
          <Route path='/versement' element={<Versement/>}/>
          <Route path='/pageCandidatCand/:id' element={<PageCandidatCand/>}/>
          <Route path='/retrait/:id' element={<Retrait/>}/>
          <Route path='/lesObservations/:id' element={<LesObservations/>}/>
          <Route path='/lesObservationsDir/:id' element={<LesObservationsDir/>}/>
          <Route path='/footer' element={<Footer/>}/>
          <Route path='/profile2' element={<Profil2/>}/>
          <Route path='/option2' element={<Option2/>}/>
          <Route path='/inscrit' element={<Inscrit/>}/>
          <Route path='/admis' element={<Admis/>}/>
          <Route path='/admisNon' element={<AdmisNon/>}/>
          <Route path='/admisDir/:id' element={<AdmisDir/>}/>
          <Route path='/candidatesPages' element={<CandidatesPage/>}/>
          <Route path='/candidatesPagesOui' element={<CandidatesPageOui/>}/>
          
     {/* SECRETAIRE */}

     <Route
        path="/upResultConduite/:id"
        element={
          <ProtectedRoute element={UpResultConduite} allowedRoles={['secretaire']} />
        }
      />

     <Route
        path="/secretaireResultConduite"
        element={
          <ProtectedRoute element={SecretaireResultConduite} allowedRoles={['secretaire']} />
        }
      />

     <Route
        path="/upResult/:id"
        element={
          <ProtectedRoute element={UpResult} allowedRoles={['secretaire']} />
        }
      />

     <Route
        path="/secretaireResultCode"
        element={
          <ProtectedRoute element={SecretaireResultCode} allowedRoles={['secretaire']} />
        }
      />

     <Route
        path="/ajout"
        element={
          <ProtectedRoute element={CreateDepense} allowedRoles={['secretaire']} />
        }
      />

     <Route
        path="/candidat"
        element={
          <ProtectedRoute element={Candidat} allowedRoles={['secretaire']} />
        }
      />

     <Route
        path="/payement"
        element={
          <ProtectedRoute element={Payement} allowedRoles={['secretaire']} />
        }
      />

     <Route
        path="/resultat"
        element={
          <ProtectedRoute element={Resultat} allowedRoles={['secretaire']} />
        }
      />

     <Route
        path="/create"
        element={
          <ProtectedRoute element={Create} allowedRoles={['secretaire']} />
        }
      />
     <Route
        path="/update/:id"
        element={
          <ProtectedRoute element={Update} allowedRoles={['secretaire']} />
        }
      />
     
     <Route
        path="/depense"
        element={
          <ProtectedRoute element={Depense} allowedRoles={['secretaire']} />
        }
      />

     <Route
        path="/updateDepense/:id"
        element={
          <ProtectedRoute element={UpdateDepense} allowedRoles={['secretaire']} />
        }
      />

    
   <Route
        path="/updatePayement/:id"
        element={
          <ProtectedRoute element={UpdatePayement} allowedRoles={['secretaire']} />
        }
      />

   <Route
        path="/createPayement"
        element={
          <ProtectedRoute element={CreatePayement} allowedRoles={['secretaire']} />
        }
      />      

{/* MONITEUR */}

<Route
        path="/ajoutCode"
        element={
          <ProtectedRoute element={AjoutCode} allowedRoles={['moniteur']} />
        }
      />

<Route
        path="/voirCode"
        element={
          <ProtectedRoute element={VoirCode} allowedRoles={['moniteur']} />
        }
      />

<Route
        path="/code"
        element={
          <ProtectedRoute element={Code} allowedRoles={['moniteur']} />
        }
      />

<Route
        path="/ajoutPresence"
        element={
          <ProtectedRoute element={AjoutPresence} allowedRoles={['moniteur']} />
        }
      />

<Route
        path="/voirCond"
        element={
          <ProtectedRoute element={VoirCond} allowedRoles={['moniteur']} />
        }
      />

<Route
        path="/moniResult"
        element={
          <ProtectedRoute element={MoniResult} allowedRoles={['moniteur']} />
        }
      />

<Route
        path="/presence"
        element={
          <ProtectedRoute element={Presence} allowedRoles={['moniteur']} />
        }
      />

<Route
        path="/cond"
        element={
          <ProtectedRoute element={Cond} allowedRoles={['moniteur']} />
        }
      />
            
<Route
        path="/candidatMoni"
        element={
          <ProtectedRoute element={PageCandidatMoni} allowedRoles={['moniteur']} />
        }
      />    


{/*  DIRECTEUR */}

<Route
        path="/pageCandidatDir/:id"
        element={
          <ProtectedRoute element={PageCandidatDir} allowedRoles={['directeur']} />
        }
      />

<Route
        path="/accueil2/:id"
        element={
          <ProtectedRoute element={Accueil2} allowedRoles={['directeur']} />
        }
      />

      
<Route
        path="/candidatesPagesDir/:id"
        element={
          <ProtectedRoute element={CandidatesPageDir} allowedRoles={['directeur']} />
        }
      />

<Route
        path="/ouiCandidatesPagesDir/:id"
        element={
          <ProtectedRoute element={OuiCandidatesPageDir} allowedRoles={['directeur']} />
        }
      />

<Route
        path="/dirOldCand/:id"
        element={
          <ProtectedRoute element={DirOldCand} allowedRoles={['directeur']} />
        }
      />

<Route
        path="/bilan/:id"
        element={
          <ProtectedRoute element={Bilan} allowedRoles={['directeur']} />
        }
      />

<Route
        path="/depense2Dir/:id"
        element={
          <ProtectedRoute element={Depense2Dir} allowedRoles={['directeur']} />
        }
      />

<Route
        path="/createPayementDir/:id"
        element={
          <ProtectedRoute element={CreatePayementDir} allowedRoles={['directeur']} />
        }
      />

<Route
        path="/createCandidatDir/:id"
        element={
          <ProtectedRoute element={CreateCandidatDir} allowedRoles={['directeur']} />
        }
      />


<Route
        path="/createDepenseDir/:id"
        element={
          <ProtectedRoute element={CreateDepenseDir} allowedRoles={['directeur']} />
        }
      />

<Route
        path="/dirResultCond/:id"
        element={
          <ProtectedRoute element={DirResultCond} allowedRoles={['directeur']} />
        }
      />

<Route
        path="/resultatDir/:id"
        element={
          <ProtectedRoute element={ResultatDir} allowedRoles={['directeur']} />
        }
      />

<Route
        path="/upDirResultCond/:id"
        element={
          <ProtectedRoute element={UpDirResultCond} allowedRoles={['directeur']} />
        }
      />

<Route
        path="/upDirResultCode/:id"
        element={
          <ProtectedRoute element={UpDirResultCode} allowedRoles={['directeur']} />
        }
      />

<Route
        path="/payementDir/:id"
        element={
          <ProtectedRoute element={PayementDir} allowedRoles={['directeur']} />
        }
      />

<Route
        path="/depenseDir/:id"
        element={
          <ProtectedRoute element={DepenseDir} allowedRoles={['directeur']} />
        }
      />

<Route
        path="/optPresenceDir/:id"
        element={
          <ProtectedRoute element={OptPresenceDir} allowedRoles={['directeur']} />
        }
      />

<Route
        path="/updateDepenseDir/:id"
        element={
          <ProtectedRoute element={UpdateDepenseDir} allowedRoles={['directeur']} />
        }
      />

<Route
        path="/updatePayementDir/:id"
        element={
          <ProtectedRoute element={UpdatePayementDir} allowedRoles={['directeur']} />
        }
      />

<Route
        path="/condDir/:id"
        element={
          <ProtectedRoute element={CondDir} allowedRoles={['directeur']} />
        }
      />

<Route
        path="/codeDir/:id"
        element={
          <ProtectedRoute element={CodeDir} allowedRoles={['directeur']} />
        }
      />

<Route
        path="/voirCondDir/:id"
        element={
          <ProtectedRoute element={VoirCondDir} allowedRoles={['directeur']} />
        }
      />

<Route
        path="/voirCodeDir/:id"
        element={
          <ProtectedRoute element={VoirCodeDir} allowedRoles={['directeur']} />
        }
      />

<Route
        path='/ajoutPresenceDir/:id'
        element={
          <ProtectedRoute element={AjoutPresenceDir} allowedRoles={['directeur']} />
        }
      />
       
       <Route
        path="/ajoutCodeDir/:id"
        element={
          <ProtectedRoute element={AjoutCodeDir} allowedRoles={['directeur']} />
        }
      />

      <Route
        path="/candidatDir/:id"
        element={
          <ProtectedRoute element={CandidatDir} allowedRoles={['directeur']} />
        }
      />


<Route
        path="/dirResult/:id"
        element={
          <ProtectedRoute element={DirResult} allowedRoles={['directeur']} />
        }
      />


<Route
        path="/createDepenseDirFixe/:id"
        element={
          <ProtectedRoute element={CreateDepenseDirFixe} allowedRoles={['directeur']} />
        }
      />

<Route
        path="/updateDepFixe/:id"
        element={
          <ProtectedRoute element={UpdateDepenseFixe} allowedRoles={['directeur']} />
        }
      />

<Route
        path="/employe/:id"
        element={
          <ProtectedRoute element={Employe} allowedRoles={['directeur']} />
        }
      />


{/* <Route
        path="/download/:id"
        element={
          <ProtectedRoute element={TelechargementPDF} allowedRoles={['directeur']} />
        }
      /> */}



      {/* 
      
          <Route path='/ajout' element={<CreateDepense/>}/>
          <Route path='/ath' element={<Auth/>}/>
          <Route path='/' element={<AuthV/>}/>
          <Route path='/candidat' element={<Candidat/>}/>
          <Route path='/depense' element={<Depence/>}/>
          <Route path='/payement' element={<Payement/>}/>
          <Route path='/resultat' element={<Resultat/>}/>
          <Route path='/accueil' element={<Accueil/>}/>
          <Route path='/create' element={<Create/>}/>
          <Route path='/update/:id' element={<Update/>}/>
          <Route path='/pageCandidat/:id' element={<PageCandidat/>}/>
          <Route path='/navbar' element={<Navbar/>}/>
          <Route path='/depense' element={<Depense/>}/>
          <Route path='/updateDepense/:id' element={<UpdateDepense/>}/>
          <Route path='/updateDepenseDir/:id' element={<UpdateDepenseDir/>}/>
          <Route path='/updatePayement/:id' element={<UpdatePayement/>}/>
          <Route path='/createPayement' element={<CreatePayement/>}/>
          <Route path='/updatePayementDir/:id' element={<UpdatePayementDir/>}/>
          <Route path='/moni' element={<MONI/>}/>
          <Route path='/presence' element={<Presence/>}/>
          <Route path='/cond' element={<Cond/>}/>
          <Route path='/candidatMoni' element={<PageCandidatMoni/>}/>
          <Route path='/condDir/:id' element={<CondDir/>}/>
          <Route path='/codeDir/:id' element={<CodeDir/>}/>
          <Route path='/voirCondDir/:id' element={<VoirCondDir/>}/>
          <Route path='/voirCodeDir/:id' element={<VoirCodeDir/>}/>
          <Route path='/ajoutPresenceDir/:id' element={<AjoutPresenceDir/>}/>
          <Route path='/ajoutCodeDir/:id' element={<AjoutCodeDir/>}/>
          <Route path='/observation/:id' element={<Observation/>}/>
          <Route path='/loader' element={<Loader/>}/>
          <Route path='/candidatDir/:id' element={<CandidatDir/>}/>
          <Route path='/optPresenceDir/:id' element={<OptPresenceDir/>}/>
          <Route path='/compoTable' element={<CompoTable/>}/>
          <Route path='/depenseDir/:id' element={<DepenseDir/>}/>
          <Route path='/payementDir/:id' element={<PayementDir/>}/>
          <Route path='/secretaireResultCode' element={<SecretaireResultCode/>}/>
          <Route path='/upResult/:id' element={<UpResult/>}/>
          <Route path='/upDirResultCode/:id' element={<UpDirResultCode/>}/>
          <Route path='/secretaireResultConduite' element={<SecretaireResultConduite/>}/>
          <Route path='/upResultConduite/:id' element={<UpResultConduite/>}/>
          <Route path='/upDirResultCond/:id' element={<UpDirResultCond/>}/>
          <Route path='/resultatDir/:id' element={<ResultatDir/>}/>
          <Route path='/dirResult/:id' element={<DirResult/>}/>
          <Route path='/dirResultCond/:id' element={<DirResultCond/>}/>
          <Route path='/moniResult' element={<MoniResult/>}/>
          <Route path='/createDepenseDir/:id' element={<CreateDepenseDir/>}/>
          <Route path='/createCandidatDir/:id' element={<CreateCandidatDir/>}/>
          <Route path='/createPayementDir/:id' element={<CreatePayementDir/>}/>
          <Route path='/depense2Dir/:id' element={<Depense2Dir/>}/> 
          <Route path='/bilan/:id' element={<Bilan/>}/>
          <Route path='/pageCandidatPaye/:id' element={<PageCandidatPaye/>}/>
          <Route path='/voirCond' element={<VoirCond/>}/>
          <Route path='/ajoutPresence' element={<AjoutPresence/>}/>
          <Route path='/oldCandidat'  element={<OldCandidat/>}/>
          <Route path='/code' element={<Code/>}/>
          <Route path='/voirCode' element={<VoirCode/>}/>
          <Route path='/ajoutCode' element={<AjoutCode/>}/>
          <Route path='/dirOldCand/:id' element={<DirOldCand/>}/>
          <Route path='/versement' element={<Versement/>}/>
          <Route path='/accueil2/:id' element={<Accueil2/>}/>
          <Route path='/pageCandidatDir/:id' element={<PageCandidatDir/>}/>
          <Route path='/pageCandidatCand/:id' element={<PageCandidatCand/>}/>
          <Route path='/retrait/:id' element={<Retrait/>}/>
          <Route path='/lesObservations/:id' element={<LesObservations/>}/>
          <Route path='/lesObservationsDir/:id' element={<LesObservationsDir/>}/>
          <Route path='/footer' element={<Footer/>}/>
          <Route path='/profile2' element={<Profil2/>}/>
          <Route path='/option2' element={<Option2/>}/>
          <Route path='/inscrit' element={<Inscrit/>}/>
          <Route path='/admis' element={<Admis/>}/>
          <Route path='/admisNon' element={<AdmisNon/>}/>
          <Route path='/statDepenses' element={<StatDepenses/>}/>
          <Route path='/statPaye' element={<StatPaye/>}/>
          <Route path='/permis' element={<Permis/>}/>
          <Route path='/admisDir/:id' element={<AdmisDir/>}/>
          <Route path='/admisNonDir/:id' element={<AdmisNonDir/>}/>
          <Route path='/permisDir/:id' element={<PermisDir/>}/>
*/}

    </Routes>
    
    
    
    
    
    
    </>
  );
}

export default App;
