
import React,{ useEffect, useState} from 'react';
import axios from 'axios';
import moment from "moment";
import moni from '../components/image/moni.png';
import Footer from '../components/footer';
import log from '../components/image/log.png';
import { Link, NavLink ,useParams, useNavigate,  useLocation} from "react-router-dom";
import Loader from "../components/loader";
import Cookies from 'js-cookie';
import { sidedataDir } from '../components/sidedataDir'
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons";
import logo from '../components/image/logo4.jpg'
import croix from './croix.png'
import 'moment/locale/fr';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { API_BASE_URL } from '../apiConfig';
import Notification from '../components/notifications';
export default function DepenseDir(){
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
      const [nomAffiche, setNomAffiche] = useState('---b') 
      const [filtreActif,setFiltreActive]=useState('')
      const [universel,setUniversel]= useState ('')
    const [sidebar, setSidebar]= useState(false)
    const showSidebar = () => setSidebar(!sidebar)
    const [lu,setLu] = useState(Cookies.get('token'))
    const [tok, setTok]= useState('')
    const [loader, setLoader]= useState(true)
    const [error, setError] = useState(null);
    const [titre,setTitre]= useState(Cookies.get('titre'))
    const [vtoken, setVtoken]= useState(Cookies.get('token'))
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
    const [select1,setSelect1]= useState(Cookies.get('select1'))
    const [select2,setSelect2]= useState(Cookies.get('select2'))
    const [old, setOld]= useState('')
    const [recup, setRecup]= useState('')
    const [new1, setNew1]= useState('')
    const [new2, setNew2]= useState('')
    const [info1, setInfo1]= useState('')
    const [info2, setInfo2]= useState('')
    var navigate= useNavigate()
    const [pop, setPop]= useState('')
    const [pop2, setPop2]= useState('')
    const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
    const [date_connexion,setDate_connexion]=useState('')
    const [viewLoader, setViewLoader] = useState(false)
    const upTok =()=>{
         setLu(Cookies.get('token'))
     
    }
    const [view, setView]= useState('')
    const mettreAJourLargeurEcran = () => {
        setLargeurEcran(window.innerWidth);
    
      };

      if(tok!= ''){
        if(tok!= lu){
            Cookies.remove('token')
            Cookies.remove('autoa')
            Cookies.remove('titre')
            Cookies.remove('gestauto')
            window.location.reload();
            navigate('/')

        }
    }

    


    
    
    const logout = () =>{
        Cookies.remove('token')
        Cookies.remove('autoa')
        Cookies.remove('titre')
        Cookies.remove('gestauto')
        Cookies.remove('default')
        Cookies.remove('default2')
        Cookies.remove('defaultCode')
        Cookies.remove('defaultCode2')
        Cookies.remove('select1')
        Cookies.remove('select2')

        window.location.reload();
       navigate('/')
    }
    useEffect(()=>{
        if(!Cookies.get('token')){
           navigate('/')
        }
   }, [])





useEffect(() => {
        
    window.addEventListener('resize', mettreAJourLargeurEcran);

   
    return () => {
      window.removeEventListener('resize', mettreAJourLargeurEcran);
      
    };
   
  }, []);
// Recuperer token
useEffect(()=>{
    

axios.post(API_BASE_URL +'/setToken', { gestauto, titre},{ headers})
    .then(res => 
        {setTok(res.data[0].token)
        
          setDate_connexion(res.data[0].date_connexion)
        })
    .catch(err => console.log(err));
}, [])
//nav en haut
    const[profil, setProfil]= useState(false)
    const [ce,setCe]= useState(Cookies.get('autoa'))
    const showProfil = () => setProfil(!profil)
    const [loga, setLoga]= useState('')
    const [valeurLogaID, setValeurLogaID]= useState('')
    const [logaId, setLogaID]= useState(0)
    const [secretaire, setSecretaire] = useState([])
  
    const [idAnnexe,setIdAnnexe]= useState('')
    const [lea,setLea]=useState([0])
    var date= new Date();
    const now= date.toLocaleDateString();
   const years= date.getFullYear()
   const day= date.getDate()
  var  month=  date.getMonth()+1
  const days= date.getDate()
  var datee= new Date(years,month-2,days)
  var autre2= datee.toISOString().split('T')[0] 
  var autre= date.toISOString().split('T')[0]
    const[value,setValue]= useState('')
    
    const [date1, setDate1]= useState('')
    const [date2, setDate2]= useState('')

    const [depensesFixe, setDepensesFixe] = useState([]);
    useEffect(()=>{
        setDate1(autre2)
        setDate2(autre)
      

    }, [])


    const[inf, setInf] = useState(false)
    const[dataLibe, setDataLibe]= useState('')
    const[dataDate, setDataDate]= useState('')
    const[dataMontant, setDataMontant]= useState('')
    const[modal, setModal]= useState(false) 
    const toggleModal = ()=> {
        setModal(!modal)
    }
    
    const togglePop =()=> {
        setInf(!inf)
    }
    let varDate, varMontant ,  varLibe
    if(pop2!= ''){
        axios.post(API_BASE_URL +'/vivooSp', {pop2},{ headers})
        .then(res => {
          setDataLibe(res.data[0].libelle)
          setDataDate(res.data[0].date)
          setDataMontant(res.data[0].montant)
         
          
        }).catch(err => console.log(err));
        if(dataLibe !='' && dataDate !='' && dataMontant !=''){
            console.log('les pop ', dataDate,dataMontant,dataLibe)
            
          
            togglePop()
            setPop2('')
        }
       
    }
    
    if(pop!= ''){
        axios.post(API_BASE_URL +'/vivoo', {pop},{ headers})
        .then(res => {
          setDataLibe(res.data[0].libelle)
          setDataDate(res.data[0].date)
          setDataMontant(res.data[0].montant)
         
          
        }).catch(err => console.log(err));
        if(dataLibe !='' && dataDate !='' && dataMontant !=''){
            console.log('les pop ', dataDate,dataMontant,dataLibe)
            
          
            togglePop()
            setPop('')
        }
       
    }





    const [annexe, setAnnexe]= useState('')
    useEffect(()=>{
        axios.get(API_BASE_URL +'/navbar?id='+gestauto+'&titre='+titre+'', { headers})
        .then(res =>{
            setSecretaire(res.data)

        } )
        .catch(err => console.log(err));
    }, [])

    const page="candidatDir";

    const [ville, setVille] = useState([])
    useEffect(()=>{
        axios.get(API_BASE_URL +'/candidatDir?id='+ce+'' , { headers})
        .then(res =>{
            setVille(res.data)
            setLoga(res.data[0].ville)
            setLogaID(res.data[0].id_annexe)
            setValeurLogaID(res.data[0].id_ecole)
            setAnnexe(res.data[0].ville)
        } )
        .catch(err => console.log(err));
    }, [])

    const [id_annexe]= ville.map((data)=>(data.id_annexe));
    
     const [query, setQuery]= useState(""); 

         
         const [depense, setDepense] = useState([])
         const [depense2, setDepense2] = useState([])
         function handleSubmit(event){
            event.preventDefault();
            
            const [id_ecole]= ville.map((data)=>(data.id_ecole));

            setViewLoader(true)
           
            axios.post(API_BASE_URL +'/candidatDir', {annexe,id_annexe,id_ecole}, { headers})
            .then(res => {
                
                
                const a= res.data[0].id_ecole
                setNomAffiche(annexe)
                setLea(res.data)
                setUniversel(a)
          if(value==''){
           
            setFiltreActive('mois')
             
            axios.post(API_BASE_URL +'/infoDepense', {a,month,years}, { headers})
            .then(res => {
                setDepense(res.data)
                setViewLoader(false)
            
          }).catch(err =>{
             console.log(err)
             setError(err)
             setViewLoader(false)
             
          });

            axios.post(API_BASE_URL +'/infoDepense2', {a,month,years}, { headers})
            .then(res => {
                setDepense2(res.data)
                setViewLoader(false)
            
          }).catch(err => {
            console.log(err)
           setError(err)
           setViewLoader(false) 
          });

          axios.post(API_BASE_URL +'/defaDepenseInfoThisInit3', {a,month,years}, { headers})
          .then(res => {
              setDepensesFixe(res.data)
              setViewLoader(false)
          
        }).catch(err => {
          console.log(err)
         setError(err)
         setViewLoader(false) 
        });
    
          } else if(value=='Enregistré cette année'){
            setFiltreActive('année')
            axios.post(API_BASE_URL +'/depYearInit',{years,a}, { headers})
            .then(res => {
                setDepense(res.data)
             setViewLoader(false)   
                
            })
            .catch(err => {
                console.log(err)
             setError(err)
             setViewLoader(false)   
            });

            axios.post(API_BASE_URL +'/depYearInit2',{years,a}, { headers})
            .then(res => {
                setDepense2(res.data)
                setViewLoader(false)
            })
            .catch(err => {
                console.log(err)
             setError(err)
             setViewLoader(false)   
            });

            axios.post(API_BASE_URL +'/depYearInit3',{years,a}, { headers})
            .then(res => {
                setDepensesFixe(res.data)
                setViewLoader(false)
            })
            .catch(err => {
                console.log(err)
             setError(err)
             setViewLoader(false)   
            });

            if(o==0){
                axios.post(API_BASE_URL +'/defaDepenseInfoThisYearsInit',{years,a}, { headers})
            .then(res => {
                setDepense(res.data)
             setViewLoader(false)   
            })
            .catch(err => {
                console.log(err)
             setError(err)   
                setViewLoader(false)
            });

            axios.post(API_BASE_URL +'/defaDepenseInfoThisYearsInit2',{years,a}, { headers})
            .then(res => {
                setDepense2(res.data)
             setViewLoader(false)
                
            })
            .catch(err => {
                console.log(err)
             setError(err)
             setViewLoader(false)   
            });

            axios.post(API_BASE_URL +'/defaDepenseInfoThisYearsInit3',{years,a}, { headers})
            .then(res => {
                setDepensesFixe(res.data)
             setViewLoader(false)
                
            })
            .catch(err => {
                console.log(err)
             setError(err)
             setViewLoader(false)   
            });
            }
        }else if(value=='Tous les Depenses'){
          setFiltreActive('tous')
            axios.post(API_BASE_URL +'/depInfoInit',{a}, { headers})
            .then(res => {
                setDepense(res.data)
             setViewLoader(false)   
            })
            .catch(err => {
                console.log(err)
             setError(err)
             setViewLoader(false)   
            });

            axios.post(API_BASE_URL +'/depInfoInit2',{a}, { headers})
            .then(res => {
                setDepense2(res.data)
             setViewLoader(false)   
            })
            .catch(err => {
                console.log(err)
                setError(err)
                setViewLoader(false)
            });

            axios.post(API_BASE_URL +'/depInfoInit3',{a}, { headers})
            .then(res => {
                setDepensesFixe(res.data)
             setViewLoader(false)   
            })
            .catch(err => {
                console.log(err)
                setError(err)
                setViewLoader(false)
            });

            if(o==0){
                axios.post(API_BASE_URL +'/defaDepInfoInit',{le,a}, { headers})
            .then(res => {
                setDepense(res.data)
             setViewLoader(false)   
            })
            .catch(err => {
                console.log(err)
             setError(err) 
             setViewLoader(false)  
            });

            axios.post(API_BASE_URL +'/defaDepInfoInit2',{le,a}, { headers})
            .then(res => setDepense2(res.data))
            .catch(err => console.log(err));
            }
            axios.post(API_BASE_URL +'/defaDepInfoInit3',{le,a}, { headers})
            .then(res => setDepensesFixe(res.data))
            .catch(err => console.log(err));
        }
          else  if(value=='Enregistré ce mois'){
            setFiltreActive('mois')
            axios.post(API_BASE_URL +'/depenseInfoThisInit',{month,years,a}, { headers})
            .then(res => {
                setDepense(res.data)
             setViewLoader(false)   
            })
            .catch(err => {
                console.log(err)
             setError(err)
             setViewLoader(false)   
            });

            axios.post(API_BASE_URL +'/depenseInfoThisInit2',{month,years,a}, { headers})
            .then(res => {
                setDepense2(res.data)
             setViewLoader(false)   
            })
            .catch(err => {
                console.log(err)
             setError(err)
             setViewLoader(false)   
            });

            axios.post(API_BASE_URL +'/defaDepenseInfoThisInit3',{month,years,a}, { headers})
            .then(res => {
                setDepensesFixe(res.data)
             setViewLoader(false)   
            })
            .catch(err => {
                console.log(err)
             setError(err)
             setViewLoader(false)   
            });

            if(o==0){
                axios.post(API_BASE_URL +'/defaDepenseInfoThisInit',{month,years,a}, { headers})
                .then(res => {
                    setDepense(res.data)
                    setViewLoader(false)
                })
                .catch(err => {
                    console.log(err)
                 setError(err)   
                    setViewLoader(false)
                });

                axios.post(API_BASE_URL +'/defaDepenseInfoThisInit2',{month,years,a}, { headers})
                .then(res => {
                    setDepense2(res.data)
                 setViewLoader(false)   
                })
                .catch(err => {
                    console.log(err)
                 setError(err)
                 setViewLoader(false)   
                    
                });

                axios.post(API_BASE_URL +'/defaDepenseInfoThisInit3',{month,years,a}, { headers})
                .then(res => {
                  console.log('defaut fixe o=0')
                    setDepensesFixe(res.data)
                 setViewLoader(false)   
                })
                .catch(err => {
                    console.log(err)
                 setError(err)
                 setViewLoader(false)   
                    
                });
            }
            }
 
            }).catch(err => {
                console.log(err)
             setError(err)
             setViewLoader(false)   
            });
         
        }
             
        
       



        const  le = lea.map((data)=>(data.id_ecole))
        
        var o=le
        const {id}= useParams();
        if (o!=0){
            o=le
        }else{
            
            o=id
        }


        if(logaId!='' && o==0){
     
            if(select1=== undefined || select2===undefined){


              setLogaID('')


                
            

             
         
          
          axios.post(API_BASE_URL +'/depParDefaut', {valeurLogaID,date1,date2}, { headers})
            .then(res => {
                
                setDepense(res.data)
                setLoader(false)
            
          }).catch(err =>{
             console.log(err)
             setError(err)
             setLoader(false)
             
          });

            axios.post(API_BASE_URL +'/depParDefaut2', {valeurLogaID,date1,date2}, { headers})
            .then(res => {
                setDepense2(res.data)
                setLoader(false)
            
          }).catch(err => {
            console.log(err)
           setError(err)
           setLoader(false) 
          }); 

          setFiltreActive('defautO')

          const [id_ecole]= ville.map((data)=>(data.id_ecole));
          axios.post(API_BASE_URL +'/candidatDir', {annexe,id_annexe,id_ecole}, { headers})
          .then(res => {
              
              
              const a= res.data[0].id_ecole
  
  
              setUniversel(a)
              axios.post(API_BASE_URL +'/defaDepenseInfoThisInit3',{month,years,a}, { headers})
              .then(res => {
                console.log('defaut fixe o=0')
                  setDepensesFixe(res.data)
                  setLoader(false)
              })
              .catch(err => {
                  console.log(err)
               setError(err)
               setLoader(false)
                  
              });
  
  
              
            }).catch(err => {
              console.log(err)
           setError(err)
           setLoader(false)
          });  
            
        }

            else if(select1 || select2){
              setFiltreActive('defautSelect')
                axios.post(API_BASE_URL +'/defa', {loga,logaId}, { headers})
                .then(res => {
                    setDate1(select1)
                    setDate2(select2) 
             
                    const a= res.data[0].id_ecole
                  
                    console.log("loga dans le cas ou select est activé1", logaId)
                    
                    setUniversel(a)
                    setLogaID('')


                    
                    console.log("loga dans le cas ou select est activé2", logaId)
                    
                    
                axios.post(API_BASE_URL +'/vVoirDep', {a,select1,select2}, { headers})
                .then(res => {
                    setDepense(res.data)
                    setLoader(false)
                
              }).catch(err =>{
                 console.log(err)
                 setError(err)
                 setLoader(false)
                 
              });
    
                axios.post(API_BASE_URL +'/vVoirDep2', {a,select1,select2}, { headers})
                .then(res => {
                    setDepense2(res.data)
                    setLoader(false)
                
              }).catch(err => {
                console.log(err)
               setError(err)
               setLoader(false) 
              });



              axios.post(API_BASE_URL +'/vVoirDep3', {a,select1,select2}, { headers})
              .then(res => {
                  setDepensesFixe(res.data)
                  setLoader(false)
              
            }).catch(err => {
              console.log(err)
             setError(err)
             setLoader(false) 
            });

            }).catch(err =>{
                console.log(err)
                    setError(err)
                    setLoader(false)
            } );
            
            }
   
        }


        useEffect(()=>{
            if(o!=0){
           
            axios.post(API_BASE_URL +'/idAnnexe', {o}, { headers})
            .then(res =>{ 
                
                setIdAnnexe(res.data[0].ville)             
            }
               
    
            ).catch(err => console.log(err));
        }
        }, [])
            var b
           
        
         
         if(idAnnexe!=''){
            b= idAnnexe
          }  else{
            b= loga
          }

        useEffect(()=>{
            if(o!=0){
                if(select1=== undefined || select2===undefined){


                    let date1=autre2
                    let date2=autre
                    const valeurLogaID= o;
                    const a= o;
                    setFiltreActive('defautO')
                    setUniversel(o)
                    axios.post(API_BASE_URL +'/depParDefaut', {valeurLogaID,date1,date2}, { headers})
                    .then(res => {
                        
                        setDepense(res.data)
                        setLoader(false)
                    
                  }).catch(err =>{
                     console.log(err)
                     setError(err)
                     setLoader(false)
                     
                  });
        
                    axios.post(API_BASE_URL +'/depParDefaut2', {valeurLogaID,date1,date2}, { headers})
                    .then(res => {
                        setDepense2(res.data)
                        setLoader(false)
                    
                  }).catch(err => {
                    console.log(err)
                   setError(err)
                   setLoader(false) 
                  });

                  axios.post(API_BASE_URL +'/defaDepenseInfoThisInit3',{month,years,a}, { headers})
                  .then(res => {
                      setDepensesFixe(res.data)
                   setViewLoader(false)   
                  })
                  .catch(err => {
                      console.log(err)
                   setError(err)
                   setViewLoader(false)   
                  });

        }else  if(select1 || select2){

            setDate1(select1)
            setDate2(select2) 
                const a= o
                setFiltreActive('defautSelect')
                setUniversel(o)
                setLogaID('')
            axios.post(API_BASE_URL +'/vVoirDep', {a,select1,select2}, { headers})
            .then(res => {
                setDepense(res.data)
                setLoader(false)
            
          }).catch(err =>{
             console.log(err)
             setError(err)
             setLoader(false)
             
          });

            axios.post(API_BASE_URL +'/vVoirDep2', {a,select1,select2}, { headers})
            .then(res => {
                setDepense2(res.data)
                setLoader(false)
            
          }).catch(err => {
            console.log(err)
           setError(err)
           setLoader(false) 
          });

                    axios.post(API_BASE_URL +'/vVoirDep3', {a,select1,select2}, { headers})
              .then(res => {
                  setDepensesFixe(res.data)
                  setLoader(false)
              
            }).catch(err => {
              console.log(err)
             setError(err)
             setLoader(false) 
            });

    
        }
            }
        }, [])
       
        
        function handlePropose(event){
            Cookies.set('select1',date1)
            Cookies.set('select2',date2)
            setFiltreActive('defautO')
            setViewLoader(true)
            event.preventDefault();
           if(o!=0){ 
            setUniversel(o)
            axios.post(API_BASE_URL +'/personDepenseDir', {date1,date2,le,o}, { headers})
            .then(res => {
               setDepense(res.data)
            setViewLoader(false)
                
                
            }).catch(err => {
                console.log(err)
             setViewLoader(false)
             setError(err)   
            });
            axios.post(API_BASE_URL +'/personDepense2Dir', {date1,date2,le,o}, { headers})
            .then(res => {
               setDepense2(res.data)
        setViewLoader(false)
                
                
            }).catch(err => {
                console.log(err)
             setError(err)   
                setViewLoader(false)
            });


            axios.post(API_BASE_URL +'/personDepense3Dir', {date1,date2,le,o}, { headers})
            .then(res => {
               setDepensesFixe(res.data)
        setViewLoader(false)
                
                
            }).catch(err => {
                console.log(err)
             setError(err)   
                setViewLoader(false)
            });
        }else if(o==0){
          setUniversel(valeurLogaID)
                axios.post(API_BASE_URL +'/defaPersonDepenseDir', {date1,date2,valeurLogaID}, { headers})
                .then(res => {
                   setDepense(res.data)
          
                    setViewLoader(false)
                    
                }).catch(err => {
                    console.log(err)
                 setError(err)
                 setViewLoader(false)   
                });
                axios.post(API_BASE_URL +'/defaPersonDepense2Dir', {date1,date2,valeurLogaID}, { headers})
                .then(res => {
                   setDepense2(res.data)
                 
                    setViewLoader(false)
                    
                }).catch(err => {
                    console.log(err)
                 setViewLoader(false)
                 setError(err)   
                });

                
                axios.post(API_BASE_URL +'/defaPersonDepense3Dir', {date1,date2,valeurLogaID}, { headers})
                .then(res => {
                   setDepensesFixe(res.data)
                 
                    setViewLoader(false)
                    
                }).catch(err => {
                    console.log(err)
                 setViewLoader(false)
                 setError(err)   
                });

            }
        }


        if(view!= ''){
            setViewLoader(true)
            axios.post(API_BASE_URL +'/vevDi', {years,month,day,view}, { headers})
            .then(res => {
               if(res.data.message== 'No match'){
                toggleModal()
                setViewLoader(false)
                    setView('')
               }else{
                setViewLoader(false)
                navigate('/updateDepenseDir/'+res.data[0].id)
               }
              
            }).catch(err => console.log(err));
            setView('')
          }     
    


//modification password



const[valid, setValid]= useState('')
              
const[valid2, setValid2]= useState('')
const[supMod, setSupMod]= useState(false) 

const toggleSup = ()=> {
  
 
  setSupMod(!supMod)
  setValid('')
 
}


if(valid!= ''){
 toggleSup()
 


} 



              

const[supMod2, setSupMod2]= useState(false) 

const toggleSup2 = ()=> {
  
 
  setSupMod2(!supMod2)
  setValid2('')
  
}


if(valid2!= ''){
 toggleSup2()
 


} 




function handleModf(event){
    const id= gestauto
    event.preventDefault();
    const currentPassword = secretaire.map((data) => data.password)[0];
    
    if(currentPassword===old){
    
        if(new1===new2){
            setInfo2('')
            axios.put(API_BASE_URL +'/upPassword/'+id, {new1,titre}, { headers})
            .then(res => {
                    
        axios.get(API_BASE_URL +'/navbar?id='+gestauto+'&titre='+titre+'', { headers })
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
                toggleSup()

            setValid2('on')
            }).catch(err => console.log(err));
        }else{
           
            setInfo2('Incorrecte')
            setTimeout(() => setInfo2(''), 3000)
        }
    }else{
        setInfo1('Incorrecte')
        setTimeout(() => setInfo1(''), 3000)
    }
}




const [menuVisible, setMenuVisible] = useState(false);

const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  
};




const [showOldPassword, setShowOldPassword] = useState(false);
const [showNewPassword, setShowNewPassword] = useState(false);
const [showConfirmPassword, setShowConfirmPassword] = useState(false);


const toggleOldPasswordVisibility = () => setShowOldPassword(!showOldPassword);
const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);
const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);



useEffect(() => {
    const handleClickOutside = (event) => {
        if (menuVisible && !document.querySelector('.carD').contains(event.target)) {
            setMenuVisible(false);
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [menuVisible]);





// Détecter si l'utilisateur est sur iOS
const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
const [IDV, setIDV]=useState('')



const[photo, setPhoto]= useState('')
              
const[ph, setPh]= useState(false) 

const toggleInscritUser = ()=> {

    setPhoto('')
    setPh(!ph)
 
}


if(photo!== ''){
 setIDV(photo)
 toggleInscritUser()
 
} 






function handleImportClickUser() {
    const fileInput = document.getElementById(`file-input-${IDV}`);
    if (fileInput) {
      fileInput.click();
    } else {
      console.error(`File input with id 'file-input-${IDV}' not found`);
    }
  }
  
  function handleImageUploadUser(event, id) {
  
    const file = event.target.files[0];
    if (file) {
        if(sidebar){
            showSidebar()
        }
        
      setViewLoader(true)
      const formData = new FormData();
      formData.append('image', file);
      formData.append('id', id);
      toggleInscritUser()
      axios.post(API_BASE_URL +'/upload-image-dg', formData)
        .then(response => {
          window.location.reload(); // Recharger la page après l'upload
        })
        .catch(error => {
          console.error("Erreur lors de l'upload de l'image:", error);
        });
    }
  }




  function openCameraUser() {
    const video = document.createElement('video');
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
  
    navigator.mediaDevices.getUserMedia({ video: true })
      .then(stream => {
        video.srcObject = stream;
        video.play();
  
        // Créer une modal personnalisée
        const modal = document.createElement('div');
        modal.className = 'camera-modal-custom';
        document.body.appendChild(modal);
        modal.appendChild(video);
  
        // Bouton pour capturer l'image
        const captureButton = document.createElement('button');
        captureButton.className = 'bouton-camera-capture';
        captureButton.innerHTML = '<i class="fas fa-camera"></i>'; // Icône de capture
        modal.appendChild(captureButton);
  
        // Bouton pour annuler
        const cancelButton = document.createElement('button');
        cancelButton.className = 'bouton-camera-annuler';
        cancelButton.innerText = 'Annuler';
        modal.appendChild(cancelButton);
  
        // Centrer l'icône de la caméra
        video.style.display = 'block';
        video.style.margin = '0 auto';
  
        // Capturer l'image
        captureButton.addEventListener('click', () => {
          canvas.width = 300;
          canvas.height = 300;
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
  
          video.style.display = 'none';
          modal.appendChild(canvas);
  
             
  
          // Bouton pour valider l'image
          const confirmButton = document.createElement('button');
          confirmButton.className = 'bouton-camera-confirmer';
          confirmButton.innerHTML = '<i class="fas fa-check"></i>';
          modal.appendChild(confirmButton);
  
          // Bouton pour reprendre la capture
          const retryButton = document.createElement('button');
          retryButton.className = 'bouton-camera-reprendre';
          retryButton.innerHTML = '<i class="fas fa-redo"></i>';
          modal.appendChild(retryButton);
  
          // Valider et envoyer l'image
          confirmButton.addEventListener('click', () => {
            canvas.toBlob(blob => {
              if (blob) {
                const formData = new FormData();
                formData.append('image', blob, 'profile-image.jpg');
                formData.append('id', IDV);
  toggleInscritUser()
  setViewLoader(true)
                axios.post(API_BASE_URL +'/upload-image-dg', formData)
                  .then(() => {
                        
  
               
                   setViewLoader(false) 
                  window.location.reload()
                  
                  })
                  .catch(error => console.error('Erreur lors de l\'upload:', error));
  
                video.srcObject.getTracks().forEach(track => track.stop());
                modal.remove();
              }
            }, 'image/jpeg');
          });
  
          // Masquer le bouton "Annuler" après capture
          cancelButton.style.display = 'none';
  
          // Reprendre la capture
          retryButton.addEventListener('click', () => {
            confirmButton.remove();
            retryButton.remove();
            canvas.remove();
             
            video.style.display = 'block';
            cancelButton.style.display = 'block'; // Réafficher le bouton "Annuler"
            captureButton.style.display = 'block'; // Faire réapparaître l'icône caméra
          });
  
          captureButton.style.display = 'none'; // Masquer l'icône caméra après capture
        });
  
        cancelButton.addEventListener('click', () => {
          video.srcObject.getTracks().forEach(track => track.stop());
          modal.remove();
        });
      })
      .catch(error => console.error('Erreur lors de l\'accès à la caméra:', error));
  }


let load

if(viewLoader==true){
    load=   <div className="avt">
    <div className="rin">
      <div className="chm">
        <Loader/>
      </div>
    </div>
  </div>
}else{
    load=''
}





const [imgP,setImgp]=useState('')
const [idP, setIdp]= useState('')

useEffect(() => {
    
   if(secretaire.length>0){
  
    setImgp(secretaire[0].image)
    setIdp(secretaire[0].id)
   }
   
  }, [secretaire]);



  function handleImportUserClick2(id) {

    if (isIOS) {
      openCameraUserIOS2(id); // Ouvrir l'appareil photo pour iOS après la confirmation
    } else {
    
      const fileInput = document.getElementById(`file-input-${id}`);
      if (fileInput) {
        fileInput.click(); // Ouvrir le gestionnaire de fichiers pour les autres plateformes
      } else {
        console.error(`File input with id 'file-input-${id}' not found`);
      }
    }

  
  }





  function openCameraUserIOS2(id) {
    if (isIOS) {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/*;capture=camera'; // Ouvre directement l'appareil photo sur iOS
      fileInput.style.display = 'none'; // Cacher l'input file visuellement
  
      // Gérer la sélection d'une image via l'appareil photo
      fileInput.onchange = function(event) {
        const file = event.target.files[0];
        if (file) {
          if(photo){
            toggleInscritUser()
           }
       showSidebar()
          setViewLoader(true)

          const formData = new FormData();
          formData.append('image', file, 'profile-image.jpg');
          formData.append('id', id); // Remplacez par votre ID de candidat
  
          axios.post(API_BASE_URL +'/upload-image-dg', formData)
            .then(response => {
              setTimeout(() => {
              
                console.log('Image uploadée avec succès');
                window.location.reload(); // Recharger la page après 3 secondes
              }, 200);
            
            })
            .catch(error => console.error('Erreur lors de l\'upload:', error));
        }
      };
  
      // Ajouter le champ input au document et le déclencher
      document.body.appendChild(fileInput);
      fileInput.click(); // Simuler un clic pour ouvrir la caméra
    }
  }









  //Menu deroulant

const [isOpen, setIsOpen] = useState(false);

const [modalActive, setModalActive] = useState(false); // Contrôle l'affichage de la modale
const handleMouseEnter = () => {
  setIsOpen(true);
};

const handleMouseLeave = () => {
  setIsOpen(false);
};

const handleClick = () => {
  if(modalActive === false){
  setIsOpen((prev) => !prev); // Basculer entre ouvert/fermé
}

};


useEffect(()=>{
if(sidebar===false){
setIsOpen(false)
}
}, [sidebar])

const [theme, setTheme] = useState("Clair"); // Gestion du thème
const [notifications, setNotifications] = useState(true); // Gestion des notifications

const toggleModalProfil = () => setModalActive(!modalActive);

const [schoolInfo, setSchoolInfo]= useState([])

useEffect(()=>{
if(modalActive===true){
setIsOpen(false)
setSidebar(false)
}
}, [modalActive])

useEffect(()=>{
if(ph=== true){
setModalActive(false)
}
}, [ph])

useEffect(()=>{
   if(secretaire.length>0){   
axios.get(API_BASE_URL +'/schoolInfoDir?id='+secretaire[0].id_autoa+'', { headers })
.then(res => setSchoolInfo(res.data))
.catch(err => console.log(err));
}
}, [secretaire])







       var bar 
       const location = useLocation();
        if(largeurEcran>= 1200){
            bar= <div className="barHaut">
 <div className="lep"  onClick={handleClick}  >
                    
                    
                 {/*    <img src={moni} className="user" onClick={showProfil} /> */}


                 <img src={imgP ? imgP  : moni} alt="Candidat" className="user" />


{load}


             {ph && ( <>
    <div className="overlay-pop"></div>
    <div className="fenetre-modal">
      <div className="modal-entete">
      
      </div>
      <div className="modal-corps">
      <div className="upload-options">
    {/* Option pour importer une image */}
    <button className="import-btn" onClick={() => handleImportClickUser()}>
      📁 Importer une image
    </button>

    {/* Option pour prendre une photo */}
    <button className="camera-btn" onClick={() => openCameraUser()}>
      📷 Prendre une photo
    </button>
  </div>
      </div>
      <div className="boutons-modal">
        <Link onClick={toggleInscritUser}>
          <button className="bouton-annuler">QUITTER</button>
        </Link>
      </div>
    </div>
  </>)} 

  <input
  type="file"
  id={`file-input-${idP}`}
  style={{ display: 'none' }}
  onChange={(event) => handleImageUploadUser(event, idP)}
/>

                    
                    {supMod && (<div className='secretModalSup'>
            <div className='secretOverlay'></div>
            <div className='secretModalContent'>
                <div className="secretContainerModal"> 
                    <h3 className="secretFormTitle">MODIFIER MOT DE PASSE</h3>
                    
                    <div className='secretSupD'>
                        <input type="text" name="nom" className="secretInputCmot" id="nom" value={secretaire.map((data) => (data.nom))} readOnly />
                        <input type="text" name="prenom" className="secretInputCmot" id="prenom" value={secretaire.map((data) => (data.prenom))} readOnly />
                    </div>
                    
                    <label htmlFor="oldPassword">Ancien mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showOldPassword ? 'text' : 'password'} name="oldPassword" className="secretInputCmot" id="oldPassword" onChange={e => setOld(e.target.value)} />
                        <span onClick={toggleOldPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showOldPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info1}</h4>
                    <br/>
                    <label htmlFor="newPassword">Nouveau mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showNewPassword ? 'text' : 'password'} name="newPassword" className="secretInputCmot" id="newPassword" onChange={e => setNew1(e.target.value)} />
                        <span onClick={toggleNewPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <label htmlFor="confirmPassword">Confirmer mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword" className="secretInputCmot" id="confirmPassword" onChange={e => setNew2(e.target.value)} />
                        <span onClick={toggleConfirmPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info2}</h4>
                    <br/>
                    <div className="secretSupD">
                        <Link onClick={handleModf}><button className='secretNm1'>Modifier</button></Link>
                        <Link onClick={toggleSup}><button className='secretNm2'>Annuler</button></Link>
                    </div>
                </div>
            </div>
        </div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)}
                    
                    <div className="hero"> 
                     <h3 className="nom">{secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h3>
                     
                    </div>
       
                    
{/* menu deroulant pc */}
{isOpen && (
                          <div className="menu-deroulant-improbable">
                            <div className="element-menu-improbable" onClick={toggleModalProfil}>
                              <i className="fas fa-user"></i> Profile
                            </div>
                            <div className="element-menu-improbable">
                              <i className="fas fa-cog"></i> Paramètres
                            </div>
                            <div className="element-menu-improbable deconnexion-improbable">
                              <button onClick={logout}>Se déconnecter</button>
                            </div>
                          </div>
                        )}
                  
                  
                  
                  
                  {modalActive && (
                          <div className="modale-profil-unique">
                            <div className="modale-overlay-unique" onClick={toggleModalProfil}></div>
                            <div className="modale-contenu-unique">
                              {/* En-tête */}
                              <div className="modale-entete-unique">
                                <h2>Paramètres du Profil</h2>
                                <button className="bouton-fermer-unique" onClick={toggleModalProfil}>
                                  &times;
                                </button>
                              </div>
                  
                              {/* Corps de la modale */}
                              <div className="modale-corps-unique">
                                {/* Section Informations personnelles */}
                                <div className="section-informations-unique">
                                  <h3>Informations personnelles</h3>
                                  <div className="info-profil-unique">
                                  <div className="image-container8">
                  
                  
                          <PhotoProvider>
                            <PhotoView src={secretaire[0].image ? secretaire[0].image  : moni}>
                            <img src={secretaire[0].image ? secretaire[0].image  : moni}  className="photo-modifiable-unique" />
                                 
                  
                            </PhotoView>
                          </PhotoProvider>
                        
                  
                                  
                  <div className="camera-icon90" onClick={  e => setPhoto(secretaire[0].id) }>
                                📷
                              </div>
                                  </div>
                                   {/*  <div>
                                   Secretaire   {secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}
                                     
                                    </div> */}
                  
                  <div className="user-info-unique">
                      <h2>{secretaire[0].nom} {secretaire[0].prenom}</h2>
                      <div className="info-item-unique">
                        <hr className="separator-unique" />
                        <span><strong>Auto-école :</strong><span> {secretaire[0].auto_ecole} </span></span>
                      
                      </div>
                  
                      <div className="info-item-unique">
                        <hr className="separator-unique" />
                        <span>
                    <strong>Statut abonnement :</strong>
                    {schoolInfo.length > 0 ? (
                      // Vérifie si la date d'expiration existe et est valide
                      new Date(schoolInfo[0].expiration_date) > new Date() ? (
                        <span style={{ color: 'green' }}>Actif</span>
                      ) : (
                        <span style={{ color: 'red' }}>Expiré</span>
                      )
                    ) : (
                      <span style={{ color: 'grey' }}>Information non disponible</span>
                    )}
                  </span>
                    
                      </div>
                  
                      <div className="info-item-unique">
                        <hr className="separator-unique" />
                        <span><strong>Date d'expiration :</strong><span>Le &nbsp; {moment(schoolInfo[0].expiration_date).utc(1).format('DD-MM-YYYY')}</span> </span>
                       
                      </div>
                   
                     
                      
                      <div className="info-item-unique">
                        <hr className="separator-unique" />
                        <span>
                    <strong>Dernière activité :</strong> 
                    {date_connexion.length > 0 ? (
                      <>
                        {/* Extraire et formater la date */}
                        <span>{new Date(date_connexion).toLocaleDateString()}
                        &nbsp;à&nbsp;
                        
                          {new Date(date_connexion).toLocaleTimeString()}
                        </span>
                      </>
                    ) : (
                      <span>Information non disponible</span>
                    )}
                  </span>
                        <hr className="separator-unique" />
                      </div>
                    </div>
                                    
                                  </div>
                                </div>
                  
                                {/* Section Sécurité */}
                                <div className="section-securite-unique">
                                  <h3>Sécurité</h3>
                                  <button className="btn-securite-unique" onClick={e => setValid('on')}>Modifier le mot de passe</button>
                                  
                                </div>
                  
                              
                  
                                {/* Section Déconnexion */}
                                <div className="section-deconnexion-unique">
                                  <button className="btn-deconnexion-unique" onClick={logout}>
                                    Se déconnecter
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                  

{/* fin menu deroulant pc */}
       
                    </div>

            <NavLink to={'/accueil2/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Accueil</button> </NavLink>
    <NavLink to={'/candidatDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Candidats</button> </NavLink>
        <NavLink to={'/depenseDir/'+id} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Depenses</button> </NavLink>
        
        
        <NavLink to={'/payementDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Recettes</button> </NavLink>
        <NavLink to={'/bilan/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Bilan Global</button> </NavLink>
        <NavLink to={'/optPresenceDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Presence</button> </NavLink>
        <NavLink to={'/resultatDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Resultats</button> </NavLink>
        <NavLink to={'/employe/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Employés</button> </NavLink>
        
        <Link onClick={logout} className="link">
                               <img src={log} className="am" />
                               <div className="deco">DECONNEXION</div>
                               
                          </Link>  
    
    </div>
    
    
        }else{
            bar=  <IconContext.Provider value={{color:'#fff'}} >
                         
            <div className="icon">
         <div>
         &nbsp;&nbsp;&nbsp;
         <img src={logo} className="userLog"  /> 
         </div>
               
         <div className="naI">
               {secretaire.map((data)=>(data.auto_ecole))} 
             </div>      
         <div className="navbar">
         
            <Link to="#" className={sidebar?  "trois": "menu-bars"}>
                <FaIcons.FaBars onClick={showSidebar} />
            </Link>
         
            &nbsp;&nbsp;&nbsp;
              
         
         </div>
        
 <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
    <ul className="nav-menu-items" >
        <li className="navbar-toggle">
            <Link to="#" className="menu-bars">
               <AiIcons.AiOutlineClose onClick={showSidebar}/>
            </Link>
        </li>
        <div className="carD">
                    <div className="profil">

       {/*  <AiIcons.AiOutlineUser className="icone-profil"   onClick={toggleMenu} /> */}

       {imgP ? (
  <img
    src={imgP}
    alt="Profil"
    className="image-profilUser"
    onClick={handleClick}
  />
) : (
  <AiIcons.AiOutlineUser className="icone-profil" onClick={handleClick} />
)}


<input
  type="file"
  id={`file-input-${idP}`}
  style={{ display: 'none' }}
  onChange={(event) => handleImageUploadUser(event, idP)}
/>

        <div className="infos-utilisateur">
           <p className="inPo"   onClick={handleClick}> {secretaire.map((data)=>(data.poste))} </p>
          <p className="inN"  onClick={handleClick}> {secretaire.map((data)=>(data.nom))}  {secretaire.map((data)=>(data.prenom))} </p>
        </div>
      </div>


        {/* menu deroulant mobile */}

  
{isOpen && (
        <div className="menu-deroulant-improbable">
          <div className="element-menu-improbable" onClick={toggleModalProfil}>
            <i className="fas fa-user"></i> Profile
          </div>
          <div className="element-menu-improbable">
            <i className="fas fa-cog"></i> Paramètres
          </div>
          <div className="element-menu-improbable deconnexion-improbable">
            <button onClick={logout}>Se déconnecter</button>
          </div>
        </div>
      )}




{modalActive && (
        <div className="modale-profil-unique">
          <div className="modale-overlay-unique" onClick={toggleModalProfil}></div>
          <div className="modale-contenu-unique">
            {/* En-tête */}
            <div className="modale-entete-unique">
              <h2>Paramètres du Profil</h2>
              <button className="bouton-fermer-unique" onClick={toggleModalProfil}>
                &times;
              </button>
            </div>

            {/* Corps de la modale */}
            <div className="modale-corps-unique">
              {/* Section Informations personnelles */}
              <div className="section-informations-unique">
               
                <div className="info-profil-unique">
                <div className="image-container8">

                <PhotoProvider>
          <PhotoView src={secretaire[0].image ? secretaire[0].image  : moni}>
          <img src={secretaire[0].image ? secretaire[0].image  : moni}  className="photo-modifiable-unique" />
               

          </PhotoView>
        </PhotoProvider>


                <div className="camera-icon90" onClick={ () => isIOS ? handleImportUserClick2(secretaire[0].id) : handleImportUserClick2(secretaire[0].id)  }>
              📷
            </div>
                </div>
                <h2>{secretaire[0].nom} {secretaire[0].prenom}</h2>
                 {/*  <div>
                 Secretaire   {secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}
                   
                  </div> */}

<div className="user-info-unique">
    
    <div className="info-item-unique">
      <hr className="separator-unique" />
      <span><strong>Auto-école :</strong><span> {secretaire[0].auto_ecole} </span></span>
    
    </div>

    <div className="info-item-unique">
      <hr className="separator-unique" />
      <span>
  <strong>Statut abonnement :</strong>
  {schoolInfo.length > 0 ? (
    // Vérifie si la date d'expiration existe et est valide
    new Date(schoolInfo[0].expiration_date) > new Date() ? (
      <span style={{ color: 'green' }}>Actif</span>
    ) : (
      <span style={{ color: 'red' }}>Expiré</span>
    )
  ) : (
    <span style={{ color: 'grey' }}>Information non disponible</span>
  )}
</span>
  
    </div>

    <div className="info-item-unique">
      <hr className="separator-unique" />
      <span><strong>Date d'expiration :</strong><span>Le &nbsp; {moment(schoolInfo[0].expiration_date).utc(1).format('DD-MM-YYYY')}</span> </span>
     
    </div>
 
   
    
    <div className="info-item-unique">
      <hr className="separator-unique" />
      <span>
  <strong>Dernière activité :</strong> 
  {date_connexion.length > 0 ? (
    <>
      {/* Extraire et formater la date */}
      <span>{new Date(date_connexion).toLocaleDateString()}
      &nbsp;à&nbsp;
      
        {new Date(date_connexion).toLocaleTimeString()}
      </span>
    </>
  ) : (
    <span>Information non disponible</span>
  )}
</span>
      <hr className="separator-unique" />
    </div>
  </div>
                  
                </div>
              </div>

              {/* Section Sécurité */}
              <div className="section-securite-unique">
                <h3>Sécurité</h3>
                <button className="btn-securite-unique" onClick={e => setValid('on')}>Modifier le mot de passe</button>
                
              </div>

            

              {/* Section Déconnexion */}
              <div className="section-deconnexion-unique">
                <button className="btn-deconnexion-unique" onClick={logout}>
                  Se déconnecter
                </button>
              </div>
            </div>
          </div>
        </div>
      )}


    {/* Fin menu deroulant mobile */}

      </div>
        {sidedataDir.map((item, index) =>{
              const isActive = location.pathname.startsWith(item.path);
          return (
           
            <li key={index} className={isActive ? `${item.cName} active` : item.cName} onClick={showSidebar}>
                 <Link to={item.path+'/'+o}>
                    <span>{item.title}</span>
                 </Link>
                
            </li>
            
          
          )
 
        })}
       <li className="nav-text">
        <Link onClick={logout}  >
             
               <span>Deconnexion</span>
               
          </Link>
          </li> 
    </ul>
 </nav>
         </div>

         {supMod && (<div className='secretModalSup'>
            <div className='secretOverlay'></div>
            <div className='secretModalContent'>
                <div className="secretContainerModal"> 
                    <h3 className="secretFormTitle">MODIFIER MOT DE PASSE</h3>
                    
                    <div className='secretSupD'>
                        <input type="text" name="nom" className="secretInputCmot" id="nom" value={secretaire.map((data) => (data.nom))} readOnly />
                        <input type="text" name="prenom" className="secretInputCmot" id="prenom" value={secretaire.map((data) => (data.prenom))} readOnly />
                    </div>
                    
                    <label htmlFor="oldPassword">Ancien mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showOldPassword ? 'text' : 'password'} name="oldPassword" className="secretInputCmot" id="oldPassword" onChange={e => setOld(e.target.value)} />
                        <span onClick={toggleOldPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showOldPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info1}</h4>
                    <br/>
                    <label htmlFor="newPassword">Nouveau mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showNewPassword ? 'text' : 'password'} name="newPassword" className="secretInputCmot" id="newPassword" onChange={e => setNew1(e.target.value)} />
                        <span onClick={toggleNewPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <label htmlFor="confirmPassword">Confirmer mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword" className="secretInputCmot" id="confirmPassword" onChange={e => setNew2(e.target.value)} />
                        <span onClick={toggleConfirmPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info2}</h4>
                    <br/>
                    <div className="secretSupD">
                        <Link onClick={handleModf}><button className='secretNm1'>Modifier</button></Link>
                        <Link onClick={toggleSup}><button className='secretNm2'>Annuler</button></Link>
                    </div>
                </div>
            </div>
        </div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)} 



         </IconContext.Provider>
                 
         }


         let totalDep1=0
         let totalDep2=0

         depense.map((data, i)=> (
           totalDep1=totalDep1+data.montant
       ))

    
       depense2.map((data, i)=> (
         totalDep2=totalDep2+data.montant
     ))



let autreDep

if(depense.length>0){
    autreDep =   <h4 className='ali'>Autres Depenses </h4>
}else{
    autreDep =''
}



let totalDep3=0

depensesFixe.map((data, i)=> (
  totalDep3=totalDep3+data.montant
))
 








/* genetrate pdf code */



const [infoAuto, setInfoAuto] = useState('')
    const [localisation, setLocalisation] = useState('')
    const [logoA, setLogoA]= useState('')
    const [rcm, setRcm] = useState('')
    const [ifu, setIfu] = useState('')
    const [autorisation, setAutorisation] = useState('')
    const [mail, setMail] = useState('')
    const [nom, setNom] = useState('')
   





    useEffect(()=>{
           
            axios.get(API_BASE_URL +'/infoAuto?id='+universel+'&titre='+titre+'', { headers })
            .then(res => {
                setInfoAuto(res.data[0].contact)
                setLocalisation(res.data[0].localisation)
               setLogoA(res.data[0].logo)
               setRcm(res.data[0].rcm)
               setIfu(res.data[0].ifu)
               setAutorisation(res.data[0].autorisation)
               setMail(res.data[0].mail)
               setNom(res.data[0].nom)

               console.log('mais les infos sont là ', res.data)
            
            })
            .catch(err => console.log(err));
        }, [universel])



        const logoUrl = logoA ? `${API_BASE_URL}/get-image?logo=${logoA}` : null;

        const loadImageAsBase64 = async (url) => {
          const response = await fetch(url);
          const blob = await response.blob();
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          });
        };



 

  // Effet pour surveiller les mises à jour de l'annexe
  useEffect(() => {
    if (idAnnexe) {
    
      setNomAffiche(idAnnexe)
    }  else {
      
      setNomAffiche(loga)
    }
  }, [idAnnexe,loga]);


  







  const generateBilanPDF = async(logo, nom,contact, localisation, mail, ifu, reference, rccm, depensesFixes, depensesVariables, depensesDirecteur, totalGeneral)  => {
    const doc = new jsPDF();
  
    // Couleurs et styles
    const titleColor = [0, 102, 204];
    const headerColor = [240, 240, 240];
    const textColor = [50, 50, 50];
    const accentColor = [100, 150, 200];
  
    // 1. En-tête avec le logo et les informations de l'auto-école
 /*    if (logo) {
      doc.addImage(logo, 'PNG', 10, 10, 40, 20);
    } */


    try {
      if (logo) {
        const base64Logo = await loadImageAsBase64(logo); // Utilisation de await pour charger l'image
        doc.addImage(base64Logo, 'PNG', 10, 6, 30, 30);
      }
    } catch (error) {
      console.error("Erreur lors du chargement de l'image :", error);
   
    }




    doc.setFontSize(16);
    doc.setTextColor(...titleColor);
/*     doc.text(`AUTO ECOLE  ${nom} ${annexe}` || 'Nom de l\'Auto-école', 10, 45); */
  doc.text(`AUTO ECOLE ${nom} ${nomAffiche} `  || 'Nom de l\'Auto-école', 10, 45);

    doc.setFontSize(10);
    doc.setTextColor(...textColor);
    if (contact) doc.text(`Contact : ${contact}`, 10, 50);
   /*  if (localisation) doc.text(`Localisation : ${localisation}`, 10, 55); */
    if (mail) doc.text(`Email : ${mail}`, 10, 55);
    if (ifu) doc.text(`IFU : ${ifu}`, 10, 60);
    if (reference) doc.text(`Référence d'autorisation : ${reference}`, 10, 65);
    if (rccm) doc.text(`N° RCCM : ${rccm}`, 10, 70);
    const maxWidth = 180; // Largeur maximale pour le texte sur une ligne
    if (localisation) {
      const localisationText = `Localisation : ${localisation}`;
      const wrappedText = doc.splitTextToSize(localisationText, maxWidth);
      doc.text(wrappedText, 10, 75); // Affiche le texte avec retour à la ligne si nécessaire
  }
    // 2. Titre principal du document
/*      doc.setFontSize(18);
    doc.setTextColor(...titleColor);
    doc.text("Bilan des Dépenses", 105, 20, null, null, "center"); */






let titreDocument = "Bilan des Dépenses";

switch(filtreActif) {
  case "mois":
      titreDocument += " - Mois en cours";
      break;
  case "année":
      titreDocument += " - Année en cours";
      break;
  case "tous":
      titreDocument += " - Toutes les Dépenses";
      break;
  case "defautSelect":
      if (select1 && select2) {
          titreDocument += ` - Du ${moment(select1).format('DD-MM-YYYY')} au ${moment(select2).format('DD-MM-YYYY')}`;
      }
      break;
         case "defautO":
      if (date1 && date2) {
          titreDocument += ` - Du ${moment(date1).format('DD-MM-YYYY')} au ${moment(date2).format('DD-MM-YYYY')}`;
      }
      break;
  default:
      // Par défaut, par exemple, les dépenses du mois précédent
      titreDocument += " - Mois Précédent";
}






/*         doc.setFontSize(18);
    doc.setTextColor(...titleColor);
    doc.text(`${titreDocument}`, 105, 20, null, null, "center"); */
let titleFontSize = 18;
if (doc.getTextWidth(titreDocument) > 50) { // 100 est une largeur approximative que vous pouvez ajuster
titleFontSize = 12; // Réduire la taille de la police si le texte est trop long
}

doc.setFontSize(titleFontSize);
doc.setTextColor(...titleColor);
doc.text(`${titreDocument}`, 105, 20, null, null, "center");








  
    // 3. Tableau des Dépenses Fixes
    if (depensesFixes.length > 0) {
      doc.setFontSize(12);
      doc.setTextColor(...accentColor);
      doc.text("Dépenses Fixes", 10, 90);
      
      doc.autoTable({
        startY: 95,
        head: [['Date', 'Libellé', 'Montant', 'Statut']],
        body: depensesFixes.map(depense => [
          moment(depense.date).utc(1).format('DD-MM-YYYY'), 
          depense.libelle, 
          `${depense.montant} FCFA`, 
          depense.statut ||  `Réglée le ${moment(depense.date).utc(1).format('DD-MM-YYYY')} `
        ]),
        theme: 'grid',
        styles: { fillColor: headerColor, textColor: textColor },
      });
    }
  
    // 4. Tableau des Dépenses Variables
    if (depensesVariables.length > 0) {
      doc.setTextColor(...accentColor);
      const startY = doc.autoTable.previous ? doc.autoTable.previous.finalY + 10 : 90;  // Valeur par défaut si pas de table précédente

      doc.text("Dépenses par la secretaire", 10, startY + 10);
  
      doc.autoTable({
        startY: startY + 15,
        head: [['Date', 'Libellé', 'Montant']],
        body: depensesVariables.map(depense => [
          moment(depense.date).utc(1).format('DD-MM-YYYY'), 
          depense.libelle, 
          `${depense.montant} FCFA`
        ]),
        theme: 'grid',
        styles: { fillColor: headerColor, textColor: textColor },
      });
    }
  
    // 5. Tableau des Dépenses par le Directeur
    if (depensesDirecteur.length > 0) {
      doc.setTextColor(...accentColor);
      const startY = doc.autoTable.previous ? doc.autoTable.previous.finalY + 10 : 90;
      doc.text("Dépenses par le Directeur", 10, startY + 10);
  
      doc.autoTable({
        startY: startY + 15,
        head: [['Date', 'Libellé', 'Montant']],
        body: depensesDirecteur.map(depense => [
          moment(depense.date).utc(1).format('DD-MM-YYYY'), 
          depense.libelle, 
          `${depense.montant} FCFA`
        ]),
        theme: 'grid',
        styles: { fillColor: headerColor, textColor: textColor },
      });
    }
  
    // 6. Total Général
    doc.setFontSize(14);
    doc.setTextColor(...titleColor);
/*     doc.text(`Total : ${totalGeneral} FCFA`, 10, doc.autoTable.previous.finalY + 20); */
    const startY = doc.autoTable.previous ? doc.autoTable.previous.finalY + 20 : 90;  // Valeur par défaut si pas de table précédente
doc.text(`Total : ${totalGeneral} FCFA`, 10, startY);

        // Vérifier l'espace disponible et ajouter une nouvelle page si nécessaire pour la signature
let finalY = doc.autoTable.previous.finalY + 40;
if (finalY > doc.internal.pageSize.height - 20) {
  doc.addPage();
  finalY = 20; // Repositionner en haut de la nouvelle page
}

// 7. Signature
doc.setFontSize(10);
doc.setTextColor(...textColor);
doc.text("Signature du Directeur ou Responsable :", 10, finalY);
doc.line(10, finalY + 2, 70, finalY + 2); // Ligne de signature
  
   
   /*  doc.setFontSize(10);
    doc.setTextColor(...textColor);
    doc.text("Signature du Directeur ou Responsable :", 10, doc.autoTable.previous.finalY + 40);
    doc.line(10, doc.autoTable.previous.finalY + 42, 70, doc.autoTable.previous.finalY + 42);  */
  
    // Enregistrement du document
    const fileName = `${titreDocument}_${nom}_${nomAffiche}_${new Date().toISOString().replace(/[-T:.Z]/g, '').slice(0, 15)}.pdf`.replace(/\s+/g, '_');

/*           const fileName = `${titreDocument}_${nom}_${nomAffiche}.pdf`.replace(/\s+/g, '_'); */
    doc.save(fileName);
  }
  









  const handlePrint = () => {
    setViewLoader(true); // Activer le loader
   
  /*   setTimeout(() => { */
      generateBilanPDF(logoUrl, nom, infoAuto, localisation, mail, ifu, autorisation, rcm, depensesFixe, depense, depense2, totalDep1 + totalDep2 + totalDep3)
            .then(() => {
                setViewLoader(false); // Désactiver le loader après la génération du PDF
            })
            .catch((error) => {
                console.error("Erreur lors de la génération du PDF :", error);
                setViewLoader(false); // Désactiver le loader même en cas d'erreur
            });
   /*  }, 100); */
};













 const [showNotification, setShowNotification] = useState(false);

     const isDateExpired = () => {
      const currentDate = new Date();
      const expirationDate = new Date(schoolInfo[0].expiration_date);
      return expirationDate < currentDate; // Retourne true si la date est dépassée
    };

    const handleExpire = (e) => {
    /*   if (isDateExpired()) {
        e.preventDefault(); // Empêche la redirection
        alert("La date d'expiration est dépassée. Veuillez renouveler votre abonnement pour ajouter un candidat.");    
      } */

        if (isDateExpired()) {
          e.preventDefault(); // Empêche la redirection
          setShowNotification(true); // Affiche le pop-up
          setTimeout(() => setShowNotification(false), 5000); // Cache le pop-up après 5 secondes
        }

    };




  useEffect(()=>{
      if(schoolInfo.length>0){

       
          const currentDate = new Date();
          const expirationDate = new Date(schoolInfo[0].expiration_date);
         
      if ( expirationDate < currentDate) {
      
        setShowNotification(true); // Affiche le pop-up
        setTimeout(() => setShowNotification(false), 5000); // Cache le pop-up après 5 secondes
      }
    }
      }, [schoolInfo])





if(loader){
    return(
        <div className="per">
        
        <div className="loader">
        <div className="overlayLo"> 
            <div className="loaderP">
            <Loader/>
            </div>
            </div>
            </div>
            </div>
    )
}else if(error){
    return (<div>Une erreur est survenue : {error.message}</div>)
}
else{

     if(largeurEcran>= 1024){
    return(
        <>
    <div className='wini'>
        


        
       {bar}




        <h1 className='win'>La page de gestion des depenses</h1><br/><br/>

        {load}

        <div className='inove'>
            <div>
                <div className='izi'>
        <div className="search">

             <input type="text" className='recherche' name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value.toLowerCase()) }  ></input>
             </div>
</div>
             <br/><br/><br/>
             <h4 className='che'> Recherche personnalisée </h4>
           <div className='person'>
           <input type='date' value={date1} className='oixe' onChange={e => setDate1(e.target.value)}></input> <input type='date' value={date2} className='oixe' onChange={e => setDate2(e.target.value)} ></input> 
           <button type='submit' onClick={handlePropose} className='butonSF6'>Appliquer</button>
           </div>
             </div>

             <div className='autre'  >       

        
             
             <select name="poste" className='autreSel' onChange={e => setAnnexe(e.target.value)} >
            <option >{b}</option>
                     
                {  
                    ville.filter((data) => data.ville!=b).map((data)=>(   
                        
                        <option value={data.ville}>{data.ville}</option>
                            
                    ))
                    
                }
           </select>   
           <select name="poste" className='autreSel'  onChange={e => setValue(e.target.value)}>
           <option value={'Enregistré ce mois'}>Enregistré ce mois</option>
                   <option value={'Tous les Depenses'}>Tous les Depenses</option>
            
            <option value={'Enregistré cette année'}>Enregistré cette année</option>
            
           
            
                </select>
                <button className='butonSF4' onClick={handleSubmit}>Appliquer</button>
           </div>
          </div>




          <br/><br/>
          <Notification
        description="Veuillez renouveler votre abonnement pour ajouter une depense fixe."
        show={showNotification}
      />
    <Link to={'/createDepenseDirFixe/'+o} onClick={handleExpire} className='yello'> <button className='fixe'>AJOUTER DEPENSE FIXE</button> </Link> 
 






<div>
  {depensesFixe.length > 0 ? (
    <div className="ocdfg">
                <div className="employ-h2-container">
  <h2 className="employ-h2">Dépenses fixes pour le mois actuel</h2>
</div>
     
      <ul className="ocdfg-list">
      {depensesFixe.map((depense) => {
          const estMoisActuel = moment(depense.date).isSame(new Date(), 'month'); // Vérifie le mois actuel

          return (
        
       
          <li key={depense.id} className="ocdfg-list-itemM">
            
    

            <span className='texte-depenseM'>
                            <span className="montant">{depense.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{depense.libelle}</span> {!depense.statut && ( <span>  <span className='txt'> dans le mois de </span>  <span className="date">{moment(depense.date).utc(1).format('MMMM YYYY')}</span></span> )}
                       
                            
                             {depense.statut && (
                    <span className="statut">{depense.statut}</span> // Affichage du statut si présent
                )}

                             </span>
                                   {estMoisActuel && ( // Affiche le lien seulement pour le mois actuel
                  <Link to={`/updateDepFixe/${depense.id}`}>
                    <span className="icone-modifie4"></span>
                  </Link>
                )}
                
            {/* <div className="ocdfg-date">Date de l'occurrence : {new Date(depense.date).toLocaleDateString()}</div> */}
          </li>
          )
     })}

<li key={depense.id} className="ocdfg-list-item">
            
    
            <div className="">
            <span className='texte-depense'>
                            <span className="montant">TOTAL FIXES: </span> {totalDep3} FCFA
                           </span>
                             </div>
            {/* <div className="ocdfg-date">Date de l'occurrence : {new Date(depense.date).toLocaleDateString()}</div> */}
          </li>




      </ul>
    </div>
  ) : (
    <div className="message-erreur">
   Aucune dépense fixe n'est prévue pour ce mois.
  </div>
   
  )}
</div>








<Notification
        description="Veuillez renouveler votre abonnement pour ajouter une depense."
        show={showNotification}
      />
          <Link to={'/createDepenseDir/'+o} onClick={handleExpire} className='yello'> <button className='ajouter'>AJOUTER UNE DEPENSE</button> </Link> <br/><br/>
   


          <div>
          <div className='forTitle'>
    <div className="employ-h2-container">
  <h2 className="employ-h2">Depenses spéciales du directeur</h2>
</div>
</div>  
          {depense2.length > 0 ? (
          <div>

      

        <div className=''>
        <div className=''>
            <div className='uncard-list'>
        {
                        
                        depense2.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                                <span className='texte-depense'>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                             <Link /* onClick={e => setView(data.id)} */  to={`/updateDepenseDir/${data.id}`}>  <span className="icone-modifier2"> </span></Link> 
                               {modal && (<div className='modal'>
    <div className='overlay'>
        
    </div>
    <div className='modal-content'>
        <h2>Attention cette depence ne peut plus être modifiée!!!</h2>
      <Link onClick={toggleModal}>  <img src={croix} className='close-modal'/></Link>
    </div>
</div>)}
                             
                          </div>
                        ))
                      }
        
        </div>
        </div>
        
    </div>
    </div>
    
  ) : (
    <div className="message-erreur">
  Aucune dépense enregistrée par le directeur n'a été trouvée.
  </div>
   
  )}
  </div>





  <div className='forTitle'>
    <div className="employ-h2-container">
  <h2 className="employ-h2">Autres Depenses</h2>
</div>
</div>

        <div className=''>
        <div className=''>
           {/*   {autreDep} */}
           {depense.length > 0 ? ( 
             <div className='uncard-list'>
             {
                        
                        depense.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                                <span className='texte-depense'>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                            
                             
                          </div>
                        ))
                      }
        </div>
            ) : (
              <div className="message-erreur">
    Aucune autre dépense trouvée.
            </div>
             
            )}

            
        </div>

        <div className='vT'>
             TOTAL : {totalDep1+totalDep2+totalDep3} FCFA
             </div>


         {/*     <button
  className='employ-action-buttonPrint'
  onClick={handlePrint}
>
Imprimer le Bilan
</button> */}

{(depense.length > 0 || depense2.length > 0 || depensesFixe.length > 0) && (
     <button
     className='employ-action-buttonPrint'
     onClick={handlePrint}
   >
   Imprimer le Bilan
   </button>
)}
        
    </div>
    <br/>
    </div>
    <Footer/>
    </>
    )
                    }else if(largeurEcran>=510){
                        return(
                            <>
                        <div className='wini'>
                            
                    
                    
                            
                           {bar}
                    
                    
                    
                    
                            <h1 className='win'>La page de gestion des depenses</h1><br/><br/>
                    
                {load}
                    
                              <div>
                {/*les là*/}
                <div className='barContener'>
                
                {/* Barre de recherche */}
                
                <input type="text"className='seLe'  name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e => setQuery(e.target.value.toLowerCase())}></input>
                <br/>
                <select name="poste"   className='rechBar' onChange={e => setAnnexe(e.target.value)} >
                <option >{b}</option>
                         
                    {  
                        ville.filter((data) => data.ville!=b).map((data)=>(   
                            
                            <option  value={data.ville}  >{data.ville}</option>
                                
                        ))
                        
                    }
                </select> 
                
                
                
                {/* Barre de sélection avec bouton Appliquer */}
                <div className='barChoixT'>
                <select name="poste" className='choosee' onChange={e => setValue(e.target.value)}>
                <option >Enregistré ce mois</option>
                                       <option >Tous les Depenses</option>
                                
                                <option>Enregistré cette année</option>
                                
                </select>
                {/* Bouton Appliquer pour le select */}
                <button className='butonSF' onClick={handleSubmit}>Appliquer</button>
                </div>
                
                {/* Barre de recherche personnalisée */}
                <div className='persBarT'>
                
                <input type='date' className='perSF' value={date1} onChange={e => setDate1(e.target.value)}></input>
                <input type='date' className='perSF' value={date2} onChange={e => setDate2(e.target.value)}></input>
                <button type='submit' className='butonSF' onClick={handlePropose}>APPLIQUER</button>
                </div>
                
                </div>
                {/*end là*/}
                
                

                <br/><br/>
                <Notification
        description="Veuillez renouveler votre abonnement pour ajouter une depense fixe."
        show={showNotification}
      />
    <Link to={'/createDepenseDirFixe/'+o} onClick={handleExpire} className='yello'> <button className='fixe'>AJOUTER DEPENSE FIXE</button> </Link> 
 






<div>
  {depensesFixe.length > 0 ? (
    <div className="ocdfg">
                <div className="employ-h2-container">
  <h2 className="employ-h2">Dépenses fixes pour le mois actuel</h2>
</div>
     
      <ul className="ocdfg-list">
      {depensesFixe.map((depense) => {
          const estMoisActuel = moment(depense.date).isSame(new Date(), 'month'); // Vérifie le mois actuel

          return (
        
       
          <li key={depense.id} className="ocdfg-list-itemM">
            
    

            <span className='texte-depenseM'>
                            <span className="montant">{depense.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{depense.libelle}</span> {!depense.statut && ( <span> <span className='txt'> dans le mois de </span>  <span className="date">{moment(depense.date).utc(1).format('MMMM YYYY')}</span></span> )}
                             {depense.statut && (
                    <span className="statut">{depense.statut}</span> // Affichage du statut si présent
                )}
                            
                             </span>
                                   {estMoisActuel && ( // Affiche le lien seulement pour le mois actuel
                  <Link to={`/updateDepFixe/${depense.id}`}>
                    <span className="icone-modifie4"></span>
                  </Link>
                )}
                
            {/* <div className="ocdfg-date">Date de l'occurrence : {new Date(depense.date).toLocaleDateString()}</div> */}
          </li>
          )
     })}

<li key={depense.id} className="ocdfg-list-item">
            
    
            <div className="">
            <span className='texte-depense'>
                            <span className="montant">TOTAL FIXES: </span> {totalDep3} FCFA
                           </span>
                             </div>
            {/* <div className="ocdfg-date">Date de l'occurrence : {new Date(depense.date).toLocaleDateString()}</div> */}
          </li>




      </ul>
    </div>
  ) : (
    <div className="message-erreur">
   Aucune dépense fixe n'est prévue pour ce mois.
  </div>
   
  )}
</div>




<Notification
        description="Veuillez renouveler votre abonnement pour ajouter une depense."
        show={showNotification}
      />
                        <Link to={'/createDepenseDir/'+o} onClick={handleExpire} className='yello'> <button className='ajouter'>AJOUTER UNE DEPENSE</button> </Link> <br/><br/>
                       
                       
                        <div className='forTitle'>
    <div className="employ-h2-container">
  <h2 className="employ-h2">Depenses spéciales du directeur</h2>
</div>
</div>         
                    
                            <div className=''>

                            {depense2.length > 0 ? (
       <div className='card-list'>                        

{
                        
                        depense2.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                                <span className='texte-depense'>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                             <Link /* onClick={e => setView(data.id)} */  to={`/updateDepenseDir/${data.id}`} >  <span className="icone-modifie"> </span></Link> 
                               {modal && (<div className='modal'>
    <div className='overlay'>
        
    </div>
    <div className='modal-content'>
        <h2>Attention cette depence ne peut plus être modifiée!!!</h2>
      <Link onClick={toggleModal}>  <img src={croix} className='close-modal'/></Link>
    </div>
</div>)}
                             
                          </div>
                        ))
                      }
        
        </div>
                         ) : (
                          <div className="message-erreur">
                        Aucune dépense enregistrée par le directeur n'a été trouvée.
                        </div>
                         
                        )}    
                        </div>

                        </div>




                    
  <div className='forTitle'>
    <div className="employ-h2-container">
  <h2 className="employ-h2">Autres Depenses</h2>
</div>
</div>
                            <div className=''>
                            <div className=''>
                       {/*  {autreDep} */}

                       {depense.length > 0 ? (  
               <div  className='card-list'>           

{
                        
                        depense.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                                <span className='texte-depense'>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                            
                             
                          </div>
                        ))
                      }
        </div>
     ) : (
      <div className="message-erreur">
Aucune autre dépense trouvée.
    </div>
     
    )}
                  
                            </div>

                            <div className='vT'>
                                 TOTAL : {totalDep1+totalDep2+totalDep3} FCFA
                                 </div>


{/* 
                                 <button
  className='employ-action-buttonPrint'
  onClick={handlePrint}
>
Imprimer le Bilan
</button> */}
{(depense.length > 0 || depense2.length > 0 || depensesFixe.length > 0) && (
     <button
     className='employ-action-buttonPrint'
     onClick={handlePrint}
   >
   Imprimer le Bilan
   </button>
)}
                            
                        </div>
                        <br/>
                        </div>
                        <Footer/>
                        </>
                        )
                    }
                    
                    else{
                        return(
                            <>
                        <div className='wini'>
                            
                    
                    
                            
                           {bar}
                    
                    
                    
                    
                            <h1 className='win'>La page de gestion des depenses</h1><br/><br/>
                    
                {load}
                    
                              <div>
                {/*les là*/}
                <div className='barContener'>
                
                {/* Barre de recherche */}
                
                <input type="text"className='seLe'  name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e => setQuery(e.target.value.toLowerCase())}></input>
                <br/>
                <select name="poste"   className='rechBar' onChange={e => setAnnexe(e.target.value)} >
                <option >{b}</option>
                         
                    {  
                        ville.filter((data) => data.ville!=b).map((data)=>(   
                            
                            <option  value={data.ville}  >{data.ville}</option>
                                
                        ))
                        
                    }
                </select> 
                
                
                
                {/* Barre de sélection avec bouton Appliquer */}
                <div className='barChoixT'>
                <select name="poste" className='choosee' onChange={e => setValue(e.target.value)}>
                <option >Enregistré ce mois</option>
                                       <option >Tous les Depenses</option>
                                
                                <option>Enregistré cette année</option>
                                
                </select>
                {/* Bouton Appliquer pour le select */}
                <button className='butonSF' onClick={handleSubmit}>Appliquer</button>
                </div>
                
                {/* Barre de recherche personnalisée */}
                <div className='persBarT'>
                
                <input type='date' className='perSF' value={date1} onChange={e => setDate1(e.target.value)}></input>
                <input type='date' className='perSF' value={date2} onChange={e => setDate2(e.target.value)}></input>
                <button type='submit' className='butonSF' onClick={handlePropose}>APPLIQUER</button>
                </div>
                
                </div>
                {/*end là*/}
                
                
                       

                <br/><br/>







                <Notification
        description="Veuillez renouveler votre abonnement pour ajouter une depense fixe."
        show={showNotification}
      />
<Link to={'/createDepenseDirFixe/'+o} onClick={handleExpire} className='yello'> <button className='fixe'>AJOUTER DEPENSE FIXE</button> </Link> 


<div>
{depensesFixe.length > 0 ? (
<div className="ocdfg">
<div className="employ-h2-container">
<h2 className="employ-h2">Dépenses fixes pour le mois actuel</h2>
</div>

<ul className="ocdfg-list">
{depensesFixe.map((depense) => {
const estMoisActuel = moment(depense.date).isSame(new Date(), 'month'); // Vérifie le mois actuel

return (
<li key={depense.id} className="ocdfg-list-item">

<div className="bilan-depense">

<span className="texte-depense">
<span className="montant">{depense.montant} FCFA</span>
<span className="txt"> pour </span>
<span className="libelle">{depense.libelle}</span>
{!depense.statut && ( <span>
<span className="txt"> dans le mois de </span>
<span className="date">
{moment(depense.date).utc(1).format('MMMM YYYY')}
</span>
</span> )}
{depense.statut && (
                    <span className="statut">{depense.statut}</span> // Affichage du statut si présent
                )}

</span>
{estMoisActuel && ( // Affiche le lien seulement pour le mois actuel
<Link to={`/updateDepFixe/${depense.id}`}>
<span className="icone-modifie3"></span>
</Link>
)}
</div>

</li>
);
})}

{/* Total des dépenses */}
<li className="ocdfg-list-item">
<div className="bilan-depense">
<span className="texte-depense">
<span className="montant">TOTAL FIXES :</span> {totalDep3} FCFA
</span>
</div>
</li>
</ul>
</div>
) : (
<div className="message-erreur">
Aucune dépense fixe n'est prévue pour ce mois.
</div>
)}
</div>









<Notification
        description="Veuillez renouveler votre abonnement pour ajouter une depense."
        show={showNotification}
      />
                        <Link to={'/createDepenseDir/'+o} onClick={handleExpire} className='yello'> <button className='ajouter'>AJOUTER UNE DEPENSE</button> </Link> <br/><br/>
                        <div className='forTitle'>
    <div className="employ-h2-container">
  <h2 className="employ-h2">Depenses spéciales du directeur</h2>
</div>
</div>
                       
{depense2.length > 0 ? (              
                    
                            <div className=''>                          

{
                        
                        depense2.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                                <span className='texte-depense'>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                             <Link /* onClick={e => setView(data.id)} */   to={`/updateDepenseDir/${data.id}`} >  <span className="icone-modifie"> </span></Link> 
                               {modal && (<div className='modal'>
    <div className='overlay'>
        
    </div>
    <div className='modal-content'>
        <h2>Attention cette depence ne peut plus être modifiée!!!</h2>
      <Link onClick={toggleModal}>  <img src={croix} className='close-modal'/></Link>
    </div>
</div>)}
                             
                          </div>
                        ))
                      }
        

                            
                        </div>


) : (
  <div className="message-erreur">
Aucune dépense enregistrée par le directeur n'a été trouvée.
</div>
 
)}


                        </div>




                        
                                         <div className='forTitle'>
    <div className="employ-h2-container">
  <h2 className="employ-h2">Autres Depenses</h2>
</div>
</div>
                            <div className=''>
                            <div className=''>
                           {/*      {autreDep} */}
                           {depense.length > 0 ? (       
                       <div>   

{
                        
                        depense.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                                <span className='texte-depense'>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                            
                             
                          </div>
                        ))
                      }
        
</div>
  ) : (
    <div className="message-erreur">
   Aucune autre dépense trouvée.
  </div>
   
  )}
                    
                                 <div className='vT'>
                                 TOTAL : {totalDep1+totalDep2+totalDep3} FCFA
                                 </div>
                            </div>



    {/*                         <button
  className='employ-action-buttonPrint'
  onClick={handlePrint}
>
Imprimer le Bilan
</button> */}

{(depense.length > 0 || depense2.length > 0 || depensesFixe.length > 0) && (
     <button
     className='employ-action-buttonPrint'
     onClick={handlePrint}
   >
   Imprimer le Bilan
   </button>
)}
                            
                        </div>
                        <br/>
                        </div>
                        <Footer/>
                        </>
                        )
                
                    }
                }
}

