import React,{ useEffect, useState} from 'react';
import axios from 'axios';
import Loader from "../components/loader";
import moment from "moment";
import moni from '../components/image/moni.png';
import log from '../components/image/log.png';
import Footer from '../components/footer';
import { Link , useParams,NavLink, useNavigate,  useLocation} from "react-router-dom";
import Cookies from 'js-cookie';
import { sidedataDir } from '../components/sidedataDir'
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons";
import logo from '../components/image/logo4.jpg'
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { API_BASE_URL } from '../apiConfig';
export default function Bilan(){
  const [loader, setLoader]= useState(true)
  const navigate = useNavigate();
  const [titre,setTitre]= useState(Cookies.get('titre'))
  const [error, setError] = useState(null);
  const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
    'credentials': 'include',
  };
  const [sidebar, setSidebar]= useState(false)
    const showSidebar = () => setSidebar(!sidebar)
    const [lu,setLu] = useState(Cookies.get('token'))
    const [tok, setTok]= useState('')
    const [ce,setCe]= useState(Cookies.get('autoa'))
    const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
  
    const [viewLoader, setViewLoader] = useState(false)
    const [select1,setSelect1]= useState(Cookies.get('select1'))
    const [select2,setSelect2]= useState(Cookies.get('select2'))
    const [date_connexion,setDate_connexion]=useState('')

    const [old, setOld]= useState('')
    const [recup, setRecup]= useState('')
    const [new1, setNew1]= useState('')
    const [new2, setNew2]= useState('')
    const [info1, setInfo1]= useState('')
    const [info2, setInfo2]= useState('')


    const mettreAJourLargeurEcran = () => {
        setLargeurEcran(window.innerWidth);
    
      };

      if(tok!= ''){
        if(tok!= lu){
            Cookies.remove('token')
            Cookies.remove('autoa')
            Cookies.remove('titre')
            Cookies.remove('gestauto')
            window.location.reload();
            navigate('/')

        }
    }

    useEffect(()=>{
         if(!Cookies.get('token')){
            navigate('/')
         }
    }, [])


    
    const logout = () =>{
        Cookies.remove('token')
        Cookies.remove('autoa')
        Cookies.remove('titre')
        Cookies.remove('gestauto')
        Cookies.remove('default')
        Cookies.remove('default2')
        Cookies.remove('defaultCode')
        Cookies.remove('defaultCode2')
        Cookies.remove('select1')
        Cookies.remove('select2')
        window.location.reload();
       navigate('/')
    }
    useEffect(()=>{
        if(!Cookies.get('token')){
           navigate('/')
        }
   }, [])





useEffect(() => {
        
    window.addEventListener('resize', mettreAJourLargeurEcran);

   
    return () => {
      window.removeEventListener('resize', mettreAJourLargeurEcran);
      
    };
   
  }, []);
// Recuperer token
useEffect(()=>{
    

axios.post(API_BASE_URL +'/setToken', { gestauto, titre},{ headers})
    .then(res => 
        {setTok(res.data[0].token)
        
          setDate_connexion(res.data[0].date_connexion)
        })
    .catch(err => console.log(err));
}, [])
//nav en haut
const[profil, setProfil]= useState(false)
    
    const showProfil = () => setProfil(!profil)
    const [idAnnexe,setIdAnnexe]= useState('')
    const [secretaire, setSecretaire] = useState([])
    useEffect(()=>{
        axios.get(API_BASE_URL +'/navbar?id='+gestauto+'&titre='+titre+'', { headers})
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
    }, [])
    const [loga, setLoga]= useState('')
    
    const [logaId, setLogaID]= useState(0)
    const [valeurLogaID, setValeurLogaID]= useState('')
    const [lea,setLea]=useState([0])
    var date= new Date();
   
   const years= date.getFullYear()
  var  month=  date.getMonth()+1
  var autre= date.toISOString().split('T')[0]
  const days= date.getDate()
  var datee= new Date(years,month-2,days)
  var autre2= datee.toISOString().split('T')[0] 
    const[value,setValue]= useState('')
    const [annexe, setAnnexe]= useState('')
    const [date1, setDate1]= useState('')
  const [date2, setDate2]= useState('')

   

    const [ville, setVille] = useState([])
    useEffect(()=>{
        axios.get(API_BASE_URL +'/candidatDir?id='+ce+'' , { headers})
        .then(res =>{ 
          setVille(res.data)
          setLoga(res.data[0].ville)
          setLogaID(res.data[0].id_annexe)
          setValeurLogaID(res.data[0].id_annexe)
          setAnnexe(res.data[0].ville)
        })
        .catch(err => console.log(err));
    }, [])

    const [id_annexe]= ville.map((data)=>(data.id_annexe));
    
     const [query, setQuery]= useState(""); 
    



         
        

         const [candidat, setCandidat] = useState([])
         const [depense, setDepense] = useState([])
         const [payement, setPayement] = useState([])
         const [depenseDir, setDepenseDir]= useState([])


         function handleSubmit(event){
            event.preventDefault();
            setViewLoader(true)
            const [id_ecole]= ville.map((data)=>(data.id_ecole));

            if(annexe=='Toutes les annexes'){
              if(value==''){
                axios.post(API_BASE_URL +'/comptePaye',{id_annexe,month,years}, { headers})
                .then(res => {
                  setPayement(res.data)
                  setViewLoader(false)
            
                }).catch(err => {
                  console.log(err)
                 setError(err)
                 setViewLoader(false) 
                });
            axios.post(API_BASE_URL +'/compteDepense',{id_annexe,month,years}, { headers})
           .then(res => {
            setDepense(res.data)
 
           setViewLoader(false)
            } ).catch(err => {
              console.log(err)
             setError(err)
             setViewLoader(false) 
            });

           axios.post(API_BASE_URL +'/compteDepense2', {id_annexe,month,years}, { headers})
           .then(res => {
            setDepenseDir(res.data)
            setViewLoader(false)
           
            } ).catch(err => {
              console.log(err)
             setError(err)
             setViewLoader(false) 
            });
           axios.post(API_BASE_URL +'/compte2', {id_annexe,month,years}, { headers})
           .then(res => setCandidat(res.data)
           
           ).catch(err => console.log(err));
              }else  if(annexe=='Toutes les annexes'){
                if(value=='Enregistré ce mois'){
                  axios.post(API_BASE_URL +'/comptePaye',{id_annexe,month,years}, { headers})
                    .then(res => {
                      setPayement(res.data)
      
                setViewLoader(false)
                    }).catch(err => {
                      console.log(err)
                      setError(err)
                      setViewLoader(false)
                     } );
                axios.post(API_BASE_URL +'/compteDepense',{id_annexe,month,years}, { headers})
               .then(res => {
                setDepense(res.data)
                setViewLoader(false)
               
                }).catch(err => {
                  console.log(err)
                 setError(err)
                 setViewLoader(false) 
                });

               axios.post(API_BASE_URL +'/compteDepense2', {id_annexe,month,years}, { headers})
               .then(res => {
                setDepenseDir(res.data)
     setViewLoader(false)
               
                } ).catch(err => console.log(err));
               axios.post(API_BASE_URL +'/compte2', {id_annexe,month,years}, { headers})
               .then(res => {
                setCandidat(res.data)
             
               setViewLoader(false)
                }).catch(err => {
                  console.log(err)
                 setError(err)
                 setViewLoader(false) 
                });
                  } else if(value=='Enregistré cette année'){
                    axios.post(API_BASE_URL +'/tousDepenseYears',{years,le,o,id_annexe}, { headers})
                    .then(res => {
                      setDepense(res.data)
                      setViewLoader(false)
                    })
                    .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
  
                    axios.post(API_BASE_URL +'/tousDepenseYears2',{years,le,o,id_annexe}, { headers})
                    .then(res => {
                      setDepenseDir(res.data)
                      setViewLoader(false)
                    })
                    .catch(err => {
                      console.log(err)
                      setError(err)
                      setViewLoader(false)
                    });
                    
                    axios.post(API_BASE_URL +'/tousCandidatYears',{years,le,o,id_annexe}, { headers})
                    .then(res => {
                      setCandidat(res.data)
                      setViewLoader(false)
                      
                  })
                    .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
  
                    axios.post(API_BASE_URL +'/tousPayementYears',{years,le,o,id_annexe}, { headers})
                    .then(res => {
                      setPayement(res.data)
                     setViewLoader(false) 
                    })
                    .catch(err => {
                      console.log(err)
                     setError(err) 
                     setViewLoader(false)
                    });
  
                }
              }
            }else{
           
            axios.post(API_BASE_URL +'/candidatDir', {annexe,id_annexe,id_ecole}, { headers})
            .then(res => {
                
             
                const a= res.data[0].id_ecole
              
                setLea(res.data)

                if(value==''){
                  axios.post(API_BASE_URL +'/infoPayement', {a,month,years}, { headers})
                  .then(res => {
                    setPayement(res.data)
                    setViewLoader(false)
                  
                }).catch(err => {
                  console.log(err)
                 setError(err)
                 setViewLoader(false) 
                  
                });
  
                  axios.post(API_BASE_URL +'/infoDepense', {a,month,years}, { headers})
                  .then(res => {
                    setDepense(res.data)
                    setViewLoader(false)
                  
                   } ).catch(err => {
                    console.log(err)
                    setError(err)
                    setViewLoader(false)
                   });
  
                  axios.post(API_BASE_URL +'/infoDepense2', {a,month,years}, { headers})
                 .then(res => {
                  setDepenseDir(res.data)
       setViewLoader(false)
                 
                }).catch(err => {
                  console.log(err)
                  setError(err)
                  setViewLoader(false)
                });
  
  
                 axios.post(API_BASE_URL +'/info', {a,month,years}, { headers})
                 .then(res => {
                  setCandidat(res.data)
                 setViewLoader(false)
                }).catch(err => {
                  console.log(err)
                 setError(err)
                 setViewLoader(false) 
                });
                }else{
                  //value chose
                  if(value=='Enregistré ce mois'){
                    axios.post(API_BASE_URL +'/depenseInfoThisInit',{month,years,a}, { headers})
                    .then(res => {
                      setDepense(res.data)
                     setViewLoader(false) 
                    })
                    .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
                    axios.post(API_BASE_URL +'/moisInit',{month,years,a}, { headers})
                    .then(res => {
                      setCandidat(res.data)
                     setViewLoader(false) 
                    })
                    .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
  
                    axios.post(API_BASE_URL +'/depenseInfoThisInit2',{month,years,a}, { headers})
                  .then(res => {
                    setDepenseDir(res.data)
                   setViewLoader(false) 
                  })
                  .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
  
                  axios.post(API_BASE_URL +'/payementInfoThisInit',{month,years,a}, { headers})
                  .then(res => {
                    setPayement(res.data)
                    setViewLoader(false)
                  })
                  .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
  
                  
                    
  
                    }
                else if(value=='Enregistré cette année'){
                    axios.post(API_BASE_URL +'/depYearInit',{years,a}, { headers})
                    .then(res => {
                      setDepense(res.data)
                     setViewLoader(false) 
                    })
                    .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
  
                    axios.post(API_BASE_URL +'/depYearInit2',{years,a}, { headers})
                    .then(res => {
                      setDepenseDir(res.data)
                     setViewLoader(false) 
                    })
                    .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
                    
                    axios.post(API_BASE_URL +'/toutInit',{years,a}, { headers})
                    .then(res => {
                      setCandidat(res.data)
                     setViewLoader(false) 
                    })
                    .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
  
                    axios.post(API_BASE_URL +'/payementInfoThisYearsInit',{years,a}, { headers})
                    .then(res => {
                      setPayement(res.data)
                      setViewLoader(false)
                    })
                    .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
  
                }else if(value=='Tous les Enregistrements'){
                    axios.post(API_BASE_URL +'/depInfoInit',{a}, { headers})
                    .then(res => {
                      setDepense(res.data)
                      setViewLoader(false)
                    })
                    .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
  
                    axios.post(API_BASE_URL +'/depInfoInit2',{a}, { headers})
                  .then(res => {
                    setDepenseDir(res.data)
                   setViewLoader(false) 
                  })
                  .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
  
                  axios.post(API_BASE_URL +'/candInit',{a}, { headers})
                  .then(res => {
                    setCandidat(res.data)
                   setViewLoader(false) 
                  })
                  .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
  
                  axios.post(API_BASE_URL +'/payInfoInit',{a}, { headers})
                  .then(res => {
                    setPayement(res.data)
                   setViewLoader(false) 
                  })
                  .catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
                }
  
  
                }

                

 
            }).catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });

           

         
          }
        }
       


        let totalPaye=0
        let totalPaye2=0
        let totalDep1=0
        let totaDep2=0
        
         
            payement.map((data, i)=> ( 
                
                      totalPaye=totalPaye+data.montant    
                
            ))
            
            candidat.map((data, i)=> ( 
                
              totalPaye2=totalPaye2+data.montant_payer    
        
    ))
            depense.map((data, i)=> (
                totalDep1=totalDep1+data.montant
            ))
          
            depenseDir.map((data, i)=> (
              totaDep2=totaDep2+data.montant
            ))
          const totalDep=totalDep1+totaDep2

          
          const  le = lea.map((data)=>(data.id_ecole))      
    
        var o=le
        const {id}= useParams();
        if (o!=0){
            o=le
        }else{
            
            o=id
        }



        

        if(logaId!='' && o==0){
          if(select1=== undefined || select2===undefined){
          axios.post(API_BASE_URL +'/defa', {loga,logaId}, { headers})
          .then(res => {
              
   
              const a= res.data[0].id_ecole
        
              setLea(res.data)

              axios.post(API_BASE_URL +'/infoPayement', {a,month,years}, { headers})
              .then(res => {
                setPayement(res.data)
                 setLoader(false)
              }
              ).catch(err =>{
                 console.log(err)
                 setError(err)
                 setLoader(false)
                });

              axios.post(API_BASE_URL +'/infoDepense', {a,month,years}, { headers})
              .then(res => {
                setDepense(res.data)
                setLoader(false)
              }
              
              ).catch(err =>{
                console.log(err)
                setError(err)
                setLoader(false)
              } );

              axios.post(API_BASE_URL +'/infoDepense2', {a,month,years}, { headers})
             .then(res => {
              setDepenseDir(res.data)
              setLoader(false)
             }
             ).catch(err =>{
               console.log(err)
               setError(err)
               setLoader(false)
              });


             axios.post(API_BASE_URL +'/info', {a,month,years}, { headers})
             .then(res => {
              setCandidat(res.data)
              setLoader(false)

             }
             ).catch(err => {
              console.log(err)
              setError(err)
              setLoader(false)
             });


          }).catch(err => console.log(err));

        }else if(select1 || select2){

          axios.post(API_BASE_URL +'/defa', {loga,logaId}, { headers})
          .then(res => {
              
   
              const a= res.data[0].id_ecole
              setDate1(select1)
                    setDate2(select2) 



                    axios.post(API_BASE_URL +'/vVoirDep', {a,select1,select2}, { headers})
                    .then(res => {
                        setDepense(res.data)
                        setLoader(false)
                    
                  }).catch(err =>{
                     console.log(err)
                     setError(err)
                     setLoader(false)
                     
                  });
        
                    axios.post(API_BASE_URL +'/vVoirDep2', {a,select1,select2}, { headers})
                    .then(res => {
                        setDepenseDir(res.data)
                        setLoader(false)
                    
                  }).catch(err => {
                    console.log(err)
                   setError(err)
                   setLoader(false) 
                  });

                  axios.post(API_BASE_URL +'/vVoirPaye', {select1,select2,a}, { headers})
                  .then(res => {
                     setPayement(res.data)
                  
                      setLoader(false)
                  }).catch(err => {
                      console.log(err)
                   setError(err)
                   setLoader(false)   
                  });


                  axios.post(API_BASE_URL +'/vVoirBilan', {a,select1,select2}, { headers})
                  .then(res =>{ 
                     
                      setCandidat(res.data) 
                      setLoader(false)            
                  }
                      
                  ).catch(err =>{
                       console.log(err)
                      setError(err)
                      setLoader(false)
                      
                      });

        
            }).catch(err => console.log(err));

           

        }

        }



        useEffect(()=>{
          if(o!=0){
          axios.post(API_BASE_URL +'/idAnnexe', {o}, { headers})
          .then(res =>{ 
              
              setIdAnnexe(res.data[0].ville)             
          }
             
  
          ).catch(err => console.log(err));
        }
      }, [])
          var b
         
   
          
         if(idAnnexe!=''){
          b= idAnnexe
        }  else{
          b= loga
        }
        

// select auto

useEffect(()=>{
  if(o!=0){
    if(select1=== undefined || select2===undefined){
  axios.post(API_BASE_URL +'/tic', {o,month,years,le}, { headers})
  .then(res =>{    
      setCandidat(res.data) 
      setLoader(false)            
  }    
  ).catch(err => {
    console.log(err)
  setError(err)
  setLoader(false)
  });
}else if(select1 || select2)
  {

    


        const a= o
        axios.post(API_BASE_URL +'/vVoirBilan', {a,select1,select2}, { headers})
        .then(res =>{ 
          setDate1(select1)
          setDate2(select2) 
            setCandidat(res.data) 
            setLoader(false)            
        }
            
        ).catch(err =>{
             console.log(err)
            setError(err)
            setLoader(false)
            
            });


  }
}
}, [])

useEffect(()=>{
  if(o!=0){
    if(select1=== undefined || select2===undefined){
  axios.post(API_BASE_URL +'/ticDep2', {o,month,years,le}, { headers})
  .then(res =>{    
      setDepenseDir(res.data) 
      setLoader(false)           
  }
  ).catch(err => {
    console.log(err)
    setError(err)
    setLoader(false)
  });
}else if(select1 || select2){

      const a= o
      axios.post(API_BASE_URL +'/vVoirDep2', {a,select1,select2}, { headers})
      .then(res => {
          setDepenseDir(res.data)
          setLoader(false)
      
    }).catch(err => {
      console.log(err)
     setError(err)
     setLoader(false) 
    });

}
  }
}, [])


useEffect(()=>{
  if(o!=0){
    if(select1=== undefined || select2===undefined){
  axios.post(API_BASE_URL +'/ticDep', {o,month,years,le}, { headers})
  .then(res =>{    
      setDepense(res.data)    
      setLoader(false)        
  }
  ).catch(err => {
    console.log(err)
    setError(err)
    setLoader(false)
  });
}else if(select1 || select2){

      const a= o
    
      
      axios.post(API_BASE_URL +'/vVoirDep', {a,select1,select2}, { headers})
      .then(res => {
          setDepense(res.data)
          setLoader(false)
      
    }).catch(err =>{
       console.log(err)
       setError(err)
       setLoader(false)
       
    });

}
  }
}, [])


useEffect(()=>{
  if(o!=0){
    if(select1=== undefined || select2===undefined){
  axios.post(API_BASE_URL +'/ticPay', {o,month,years,le}, { headers})
  .then(res =>{      
      setPayement(res.data)
      setLoader(false)           
  }    
  ).catch(err =>{ 
    console.log(err)
    setError(err)
    setLoader(false)
  });
}else if(select1 || select2){
  

      const a= o
      axios.post(API_BASE_URL +'/vVoirPaye', {select1,select2,a}, { headers})
      .then(res => {
         setPayement(res.data)
      
          setLoader(false)
      }).catch(err => {
          console.log(err)
       setError(err)
       setLoader(false)   
      });
}
  }
}, [])


          function handleTout(event){
              event.preventDefault();

              if(annexe=='Toutes les annexes'){
                if(value=='Enregistré ce mois'){
                  axios.post(API_BASE_URL +'/comptePaye',{id_annexe,month,years}, { headers})
                    .then(res => setPayement(res.data)
      
                
                ).catch(err => console.log(err));
                axios.post(API_BASE_URL +'/compteDepense',{id_annexe,month,years}, { headers})
               .then(res => setDepense(res.data)
     
               
               ).catch(err => console.log(err));

               axios.post(API_BASE_URL +'/compteDepense2', {id_annexe,month,years}, { headers})
               .then(res => setDepenseDir(res.data)
     
               
               ).catch(err => console.log(err));
               axios.post(API_BASE_URL +'/compte2', {id_annexe,month,years}, { headers})
               .then(res => setCandidat(res.data)
               
               ).catch(err => console.log(err));
                  } else if(value=='Enregistré cette année'){
                    axios.post(API_BASE_URL +'/tousDepenseYears',{years,le,o,id_annexe}, { headers})
                    .then(res => setDepense(res.data))
                    .catch(err => console.log(err));
  
                    axios.post(API_BASE_URL +'/tousDepenseYears2',{years,le,o,id_annexe}, { headers})
                    .then(res => setDepenseDir(res.data))
                    .catch(err => console.log(err));
                    
                    axios.post(API_BASE_URL +'/tousCandidatYears',{years,le,o,id_annexe}, { headers})
                    .then(res => setCandidat(res.data))
                    .catch(err => console.log(err));
  
                    axios.post(API_BASE_URL +'/tousPayementYears',{years,le,o,id_annexe}, { headers})
                    .then(res => setPayement(res.data))
                    .catch(err => console.log(err));
  
                }else if(value=='Tous les Enregistrements'){
                  axios.post(API_BASE_URL +'/tousdepInfo',{le,o,id_annexe}, { headers})
                  .then(res => setDepense(res.data))
                  .catch(err => console.log(err));

                  axios.post(API_BASE_URL +'/tousdepInfo2',{le,o,id_annexe}, { headers})
                .then(res => setDepenseDir(res.data))
                .catch(err => console.log(err));

                axios.post(API_BASE_URL +'/touscandInfo',{le,o,id_annexe}, { headers})
                .then(res => setCandidat(res.data))
                .catch(err => console.log(err));

                axios.post(API_BASE_URL +'/touspayInfo',{le,o,id_annexe}, { headers})
                .then(res => setPayement(res.data))
                .catch(err => console.log(err));
              }

              }else{
                if(value=='Enregistré ce mois'){
                  axios.post(API_BASE_URL +'/depenseInfoThis',{month,years,le,o}, { headers})
                  .then(res => setDepense(res.data))
                  .catch(err => console.log(err));
                  axios.post(API_BASE_URL +'/candidatInfoThis',{month,years,le,o}, { headers})
                  .then(res => setCandidat(res.data))
                  .catch(err => console.log(err));

                  axios.post(API_BASE_URL +'/depenseInfoThis2',{month,years,le,o}, { headers})
                .then(res => setDepenseDir(res.data))
                .catch(err => console.log(err));

                axios.post(API_BASE_URL +'/payementInfoThis',{month,years,le,o}, { headers})
                .then(res => setPayement(res.data))
                .catch(err => console.log(err));

                
                  if(o==0){
                    axios.post(API_BASE_URL +'/defaDepenseInfoThis',{month,years,valeurLogaID}, { headers})
                  .then(res => setDepense(res.data))
                  .catch(err => console.log(err));
                  axios.post(API_BASE_URL +'/defaInfoThis',{month,years,valeurLogaID}, { headers})
                  .then(res => setCandidat(res.data))
                  .catch(err => console.log(err));

                  axios.post(API_BASE_URL +'/defaDepenseInfoThis2',{month,years,valeurLogaID}, { headers})
                .then(res => setDepenseDir(res.data))
                .catch(err => console.log(err));

                axios.post(API_BASE_URL +'/defaPayementInfoThis',{month,years,valeurLogaID}, { headers})
                .then(res => setPayement(res.data))
                .catch(err => console.log(err));
                  }

                  }
              else if(value=='Enregistré cette année'){
                  axios.post(API_BASE_URL +'/depenseInfoThisYears',{years,le,o}, { headers})
                  .then(res => setDepense(res.data))
                  .catch(err => console.log(err));

                  axios.post(API_BASE_URL +'/depenseInfoThisYears2',{years,le,o}, { headers})
                  .then(res => setDepenseDir(res.data))
                  .catch(err => console.log(err));
                  
                  axios.post(API_BASE_URL +'/candidatInfoThisYears',{years,le,o}, { headers})
                  .then(res => setCandidat(res.data))
                  .catch(err => console.log(err));

                  axios.post(API_BASE_URL +'/payementInfoThisYears',{years,le,o}, { headers})
                  .then(res => setPayement(res.data))
                  .catch(err => console.log(err));

              }else if(value=='Tous les Enregistrements'){
                  axios.post(API_BASE_URL +'/depInfo',{le,o}, { headers})
                  .then(res => setDepense(res.data))
                  .catch(err => console.log(err));

                  axios.post(API_BASE_URL +'/depInfo2',{le,o}, { headers})
                .then(res => setDepenseDir(res.data))
                .catch(err => console.log(err));

                axios.post(API_BASE_URL +'/candInfo',{le,o}, { headers})
                .then(res => setCandidat(res.data))
                .catch(err => console.log(err));

                axios.post(API_BASE_URL +'/payInfo',{le,o}, { headers})
                .then(res => setPayement(res.data))
                .catch(err => console.log(err));
              }


              }
             
            }
  
            useEffect(()=>{
              setDate1(autre2)
              setDate2(autre)
          }, [])
         
            
            function handlePropose(event){
              setViewLoader(true)
                event.preventDefault();
                Cookies.set('select1',date1)
            Cookies.set('select2',date2)
                axios.post(API_BASE_URL +'/personDep2Dir', {date1,date2,le,o}, { headers})
                .then(res => {
                   setDepense(res.data)
                   setViewLoader(false)
                
                     }).catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });

                     axios.post(API_BASE_URL +'/personDep3Dir', {date1,date2,le,o}, { headers})
                .then(res => {
                   setDepenseDir(res.data)
               setViewLoader(false)
                     }).catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });

                     axios.post(API_BASE_URL +'/personPay2Dir', {date1,date2,le,o}, { headers})
                .then(res => {
                   setPayement(res.data)
           setViewLoader(false)
                     }).catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
                     axios.post(API_BASE_URL +'/personCandidatDir', {date1,date2,le,o}, { headers})
                     .then(res => {
                        setCandidat(res.data)
               setViewLoader(false)
                         
                         
                     }).catch(err => {
                      console.log(err)
                     setError(err)
                     setViewLoader(false) 
                    });
            }


//modification password



const[valid, setValid]= useState('')
              
const[valid2, setValid2]= useState('')
const[supMod, setSupMod]= useState(false) 

const toggleSup = ()=> {
  
 
  setSupMod(!supMod)
  setValid('')
 
}


if(valid!= ''){
 toggleSup()
 


} 



              

const[supMod2, setSupMod2]= useState(false) 

const toggleSup2 = ()=> {
  
 
  setSupMod2(!supMod2)
  setValid2('')
  
}


if(valid2!= ''){
 toggleSup2()
 


} 




function handleModf(event){
    const id= gestauto
    event.preventDefault();
    const currentPassword = secretaire.map((data) => data.password)[0];
    
    if(currentPassword===old){
    
        if(new1===new2){
            setInfo2('')
            axios.put(API_BASE_URL +'/upPassword/'+id, {new1,titre}, { headers})
            .then(res => {
                  
        axios.get(API_BASE_URL +'/navbar?id='+gestauto+'&titre='+titre+'', { headers })
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
                toggleSup()

            setValid2('on')
            }).catch(err => console.log(err));
        }else{
           
            setInfo2('Incorrecte')
            setTimeout(() => setInfo2(''), 3000)
        }
    }else{
        setInfo1('Incorrecte')
        setTimeout(() => setInfo1(''), 3000)
    }
}





const [menuVisible, setMenuVisible] = useState(false);

const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  
};






useEffect(() => {
    const handleClickOutside = (event) => {
        if (menuVisible && !document.querySelector('.carD').contains(event.target)) {
            setMenuVisible(false);
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [menuVisible]);

    
const [showOldPassword, setShowOldPassword] = useState(false);
const [showNewPassword, setShowNewPassword] = useState(false);
const [showConfirmPassword, setShowConfirmPassword] = useState(false);


const toggleOldPasswordVisibility = () => setShowOldPassword(!showOldPassword);
const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);
const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

    


// Détecter si l'utilisateur est sur iOS
const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
const [IDV, setIDV]=useState('')



const[photo, setPhoto]= useState('')
              
const[ph, setPh]= useState(false) 

const toggleInscritUser = ()=> {

    setPhoto('')
    setPh(!ph)
 
}


if(photo!== ''){
 setIDV(photo)
 toggleInscritUser()
 
} 






function handleImportClickUser() {
    const fileInput = document.getElementById(`file-input-${IDV}`);
    if (fileInput) {
      fileInput.click();
    } else {
      console.error(`File input with id 'file-input-${IDV}' not found`);
    }
  }
  
  function handleImageUploadUser(event, id) {
  
    const file = event.target.files[0];
    if (file) {
        if(sidebar){
            showSidebar()
        }
        
      setViewLoader(true)
      const formData = new FormData();
      formData.append('image', file);
      formData.append('id', id);
      toggleInscritUser()
      axios.post(API_BASE_URL +'/upload-image-dg', formData)
        .then(response => {
          window.location.reload(); // Recharger la page après l'upload
        })
        .catch(error => {
          console.error("Erreur lors de l'upload de l'image:", error);
        });
    }
  }




  function openCameraUser() {
    const video = document.createElement('video');
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
  
    navigator.mediaDevices.getUserMedia({ video: true })
      .then(stream => {
        video.srcObject = stream;
        video.play();
  
        // Créer une modal personnalisée
        const modal = document.createElement('div');
        modal.className = 'camera-modal-custom';
        document.body.appendChild(modal);
        modal.appendChild(video);
  
        // Bouton pour capturer l'image
        const captureButton = document.createElement('button');
        captureButton.className = 'bouton-camera-capture';
        captureButton.innerHTML = '<i class="fas fa-camera"></i>'; // Icône de capture
        modal.appendChild(captureButton);
  
        // Bouton pour annuler
        const cancelButton = document.createElement('button');
        cancelButton.className = 'bouton-camera-annuler';
        cancelButton.innerText = 'Annuler';
        modal.appendChild(cancelButton);
  
        // Centrer l'icône de la caméra
        video.style.display = 'block';
        video.style.margin = '0 auto';
  
        // Capturer l'image
        captureButton.addEventListener('click', () => {
          canvas.width = 300;
          canvas.height = 300;
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
  
          video.style.display = 'none';
          modal.appendChild(canvas);
  
             
  
          // Bouton pour valider l'image
          const confirmButton = document.createElement('button');
          confirmButton.className = 'bouton-camera-confirmer';
          confirmButton.innerHTML = '<i class="fas fa-check"></i>';
          modal.appendChild(confirmButton);
  
          // Bouton pour reprendre la capture
          const retryButton = document.createElement('button');
          retryButton.className = 'bouton-camera-reprendre';
          retryButton.innerHTML = '<i class="fas fa-redo"></i>';
          modal.appendChild(retryButton);
  
          // Valider et envoyer l'image
          confirmButton.addEventListener('click', () => {
            canvas.toBlob(blob => {
              if (blob) {
                const formData = new FormData();
                formData.append('image', blob, 'profile-image.jpg');
                formData.append('id', IDV);
  toggleInscritUser()
  setViewLoader(true)
                axios.post(API_BASE_URL +'/upload-image-dg', formData)
                  .then(() => {
                        
  
               
                   setViewLoader(false) 
                  window.location.reload()
                  
                  })
                  .catch(error => console.error('Erreur lors de l\'upload:', error));
  
                video.srcObject.getTracks().forEach(track => track.stop());
                modal.remove();
              }
            }, 'image/jpeg');
          });
  
          // Masquer le bouton "Annuler" après capture
          cancelButton.style.display = 'none';
  
          // Reprendre la capture
          retryButton.addEventListener('click', () => {
            confirmButton.remove();
            retryButton.remove();
            canvas.remove();
             
            video.style.display = 'block';
            cancelButton.style.display = 'block'; // Réafficher le bouton "Annuler"
            captureButton.style.display = 'block'; // Faire réapparaître l'icône caméra
          });
  
          captureButton.style.display = 'none'; // Masquer l'icône caméra après capture
        });
  
        cancelButton.addEventListener('click', () => {
          video.srcObject.getTracks().forEach(track => track.stop());
          modal.remove();
        });
      })
      .catch(error => console.error('Erreur lors de l\'accès à la caméra:', error));
  }


let load

if(viewLoader==true){
    load=   <div className="avt">
    <div className="rin">
      <div className="chm">
        <Loader/>
      </div>
    </div>
  </div>
}else{
    load=''
}





const [imgP,setImgp]=useState('')
const [idP, setIdp]= useState('')

useEffect(() => {
    
   if(secretaire.length>0){
  
    setImgp(secretaire[0].image)
    setIdp(secretaire[0].id)
   }
   
  }, [secretaire]);



  function handleImportUserClick2(id) {

    if (isIOS) {
      openCameraUserIOS2(id); // Ouvrir l'appareil photo pour iOS après la confirmation
    } else {
    
      const fileInput = document.getElementById(`file-input-${id}`);
      if (fileInput) {
        fileInput.click(); // Ouvrir le gestionnaire de fichiers pour les autres plateformes
      } else {
        console.error(`File input with id 'file-input-${id}' not found`);
      }
    }

  
  }





  function openCameraUserIOS2(id) {
    if (isIOS) {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/*;capture=camera'; // Ouvre directement l'appareil photo sur iOS
      fileInput.style.display = 'none'; // Cacher l'input file visuellement
  
      // Gérer la sélection d'une image via l'appareil photo
      fileInput.onchange = function(event) {
        const file = event.target.files[0];
        if (file) {
          if(photo){
            toggleInscritUser()
           }
       showSidebar()
          setViewLoader(true)

          const formData = new FormData();
          formData.append('image', file, 'profile-image.jpg');
          formData.append('id', id); // Remplacez par votre ID de candidat
  
          axios.post(API_BASE_URL +'/upload-image-dg', formData)
            .then(response => {
              setTimeout(() => {
              
                console.log('Image uploadée avec succès');
                window.location.reload(); // Recharger la page après 3 secondes
              }, 200);
            
            })
            .catch(error => console.error('Erreur lors de l\'upload:', error));
        }
      };
  
      // Ajouter le champ input au document et le déclencher
      document.body.appendChild(fileInput);
      fileInput.click(); // Simuler un clic pour ouvrir la caméra
    }
  }






                                    //Menu deroulant

const [isOpen, setIsOpen] = useState(false);

const [modalActive, setModalActive] = useState(false); // Contrôle l'affichage de la modale
const handleMouseEnter = () => {
  setIsOpen(true);
};

const handleMouseLeave = () => {
  setIsOpen(false);
};

const handleClick = () => {
  if(modalActive === false){
  setIsOpen((prev) => !prev); // Basculer entre ouvert/fermé
}

};


useEffect(()=>{
if(sidebar===false){
setIsOpen(false)
}
}, [sidebar])

const [theme, setTheme] = useState("Clair"); // Gestion du thème
const [notifications, setNotifications] = useState(true); // Gestion des notifications

const toggleModalProfil = () => setModalActive(!modalActive);

const [schoolInfo, setSchoolInfo]= useState([])

useEffect(()=>{
if(modalActive===true){
setIsOpen(false)
setSidebar(false)
}
}, [modalActive])

useEffect(()=>{
if(ph=== true){
setModalActive(false)
}
}, [ph])

useEffect(()=>{
   if(secretaire.length>0){   
axios.get(API_BASE_URL +'/schoolInfoDir?id='+secretaire[0].id_autoa+'', { headers })
.then(res => setSchoolInfo(res.data))
.catch(err => console.log(err));
}
}, [secretaire])
                            
                                     








         
            var bar 
            const location = useLocation();
            if(largeurEcran>= 1200){
                bar=    <div className="barHaut">

<div className="lep"
onClick={handleClick}
>
                    
                    
                   {/*  <img src={moni} className="user" onClick={showProfil} /> */}



                   <img src={imgP ? imgP  : moni} alt="Candidat" className="user" />


{load}


             {ph && ( <>
    <div className="overlay-pop"></div>
    <div className="fenetre-modal">
      <div className="modal-entete">
      
      </div>
      <div className="modal-corps">
      <div className="upload-options">
    {/* Option pour importer une image */}
    <button className="import-btn" onClick={() => handleImportClickUser()}>
      📁 Importer une image
    </button>

    {/* Option pour prendre une photo */}
    <button className="camera-btn" onClick={() => openCameraUser()}>
      📷 Prendre une photo
    </button>
  </div>
      </div>
      <div className="boutons-modal">
        <Link onClick={toggleInscritUser}>
          <button className="bouton-annuler">QUITTER</button>
        </Link>
      </div>
    </div>
  </>)} 

  <input
  type="file"
  id={`file-input-${idP}`}
  style={{ display: 'none' }}
  onChange={(event) => handleImageUploadUser(event, idP)}
/>

                    
                    {supMod && (<div className='secretModalSup'>
            <div className='secretOverlay'></div>
            <div className='secretModalContent'>
                <div className="secretContainerModal"> 
                    <h3 className="secretFormTitle">MODIFIER MOT DE PASSE</h3>
                    
                    <div className='secretSupD'>
                        <input type="text" name="nom" className="secretInputCmot" id="nom" value={secretaire.map((data) => (data.nom))} readOnly />
                        <input type="text" name="prenom" className="secretInputCmot" id="prenom" value={secretaire.map((data) => (data.prenom))} readOnly />
                    </div>
                    
                    <label htmlFor="oldPassword">Ancien mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showOldPassword ? 'text' : 'password'} name="oldPassword" className="secretInputCmot" id="oldPassword" onChange={e => setOld(e.target.value)} />
                        <span onClick={toggleOldPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showOldPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info1}</h4>
                    <br/>
                    <label htmlFor="newPassword">Nouveau mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showNewPassword ? 'text' : 'password'} name="newPassword" className="secretInputCmot" id="newPassword" onChange={e => setNew1(e.target.value)} />
                        <span onClick={toggleNewPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <label htmlFor="confirmPassword">Confirmer mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword" className="secretInputCmot" id="confirmPassword" onChange={e => setNew2(e.target.value)} />
                        <span onClick={toggleConfirmPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info2}</h4>
                    <br/>
                    <div className="secretSupD">
                        <Link onClick={handleModf}><button className='secretNm1'>Modifier</button></Link>
                        <Link onClick={toggleSup}><button className='secretNm2'>Annuler</button></Link>
                    </div>
                </div>
            </div>
        </div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)}
                    
                    <div className="hero"> 
                     <h3 className="nom">{secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h3>
                     
                    </div>
       
           {/* menu deroulant pc */}
{isOpen && (
                          <div className="menu-deroulant-improbable">
                            <div className="element-menu-improbable" onClick={toggleModalProfil}>
                              <i className="fas fa-user"></i> Profile
                            </div>
                            <div className="element-menu-improbable">
                              <i className="fas fa-cog"></i> Paramètres
                            </div>
                            <div className="element-menu-improbable deconnexion-improbable">
                              <button onClick={logout}>Se déconnecter</button>
                            </div>
                          </div>
                        )}
                  
                  
                  
                  
                  {modalActive && (
                          <div className="modale-profil-unique">
                            <div className="modale-overlay-unique" onClick={toggleModalProfil}></div>
                            <div className="modale-contenu-unique">
                              {/* En-tête */}
                              <div className="modale-entete-unique">
                                <h2>Paramètres du Profil</h2>
                                <button className="bouton-fermer-unique" onClick={toggleModalProfil}>
                                  &times;
                                </button>
                              </div>
                  
                              {/* Corps de la modale */}
                              <div className="modale-corps-unique">
                                {/* Section Informations personnelles */}
                                <div className="section-informations-unique">
                                  <h3>Informations personnelles</h3>
                                  <div className="info-profil-unique">
                                  <div className="image-container8">
                  
                  
                          <PhotoProvider>
                            <PhotoView src={secretaire[0].image ? secretaire[0].image  : moni}>
                            <img src={secretaire[0].image ? secretaire[0].image  : moni}  className="photo-modifiable-unique" />
                                 
                  
                            </PhotoView>
                          </PhotoProvider>
                        
                  
                                  
                  <div className="camera-icon90" onClick={  e => setPhoto(secretaire[0].id) }>
                                📷
                              </div>
                                  </div>
                                   {/*  <div>
                                   Secretaire   {secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}
                                     
                                    </div> */}
                  
                  <div className="user-info-unique">
                      <h2>{secretaire[0].nom} {secretaire[0].prenom}</h2>
                      <div className="info-item-unique">
                        <hr className="separator-unique" />
                        <span><strong>Auto-école :</strong><span> {secretaire[0].auto_ecole} </span></span>
                      
                      </div>
                  
                      <div className="info-item-unique">
                        <hr className="separator-unique" />
                        <span>
                    <strong>Statut abonnement :</strong>
                    {schoolInfo.length > 0 ? (
                      // Vérifie si la date d'expiration existe et est valide
                      new Date(schoolInfo[0].expiration_date) > new Date() ? (
                        <span style={{ color: 'green' }}>Actif</span>
                      ) : (
                        <span style={{ color: 'red' }}>Expiré</span>
                      )
                    ) : (
                      <span style={{ color: 'grey' }}>Information non disponible</span>
                    )}
                  </span>
                    
                      </div>
                  
                      <div className="info-item-unique">
                        <hr className="separator-unique" />
                        <span><strong>Date d'expiration :</strong><span>Le &nbsp; {moment(schoolInfo[0].expiration_date).utc(1).format('DD-MM-YYYY')}</span> </span>
                       
                      </div>
                   
                     
                      
                      <div className="info-item-unique">
                        <hr className="separator-unique" />
                        <span>
                    <strong>Dernière activité :</strong> 
                    {date_connexion.length > 0 ? (
                      <>
                        {/* Extraire et formater la date */}
                        <span>{new Date(date_connexion).toLocaleDateString()}
                        &nbsp;à&nbsp;
                        
                          {new Date(date_connexion).toLocaleTimeString()}
                        </span>
                      </>
                    ) : (
                      <span>Information non disponible</span>
                    )}
                  </span>
                        <hr className="separator-unique" />
                      </div>
                    </div>
                                    
                                  </div>
                                </div>
                  
                                {/* Section Sécurité */}
                                <div className="section-securite-unique">
                                  <h3>Sécurité</h3>
                                  <button className="btn-securite-unique" onClick={e => setValid('on')}>Modifier le mot de passe</button>
                                  
                                </div>
                  
                              
                  
                                {/* Section Déconnexion */}
                                <div className="section-deconnexion-unique">
                                  <button className="btn-deconnexion-unique" onClick={logout}>
                                    Se déconnecter
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                  

{/* fin menu deroulant pc */}
       
                    </div>


                <NavLink to={'/accueil2/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Accueil</button> </NavLink>
            <NavLink to={'/candidatDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Candidats</button> </NavLink>
                <NavLink to={'/depenseDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Depenses</button> </NavLink>
                
                
                <NavLink to={'/payementDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Recettes</button> </NavLink>
                <NavLink to={'/bilan/'+id} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Bilan Global</button> </NavLink>
                <NavLink to={'/optPresenceDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Presence</button> </NavLink>
                <NavLink to={'/resultatDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Resultats</button> </NavLink>
                <NavLink to={'/employe/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Employés</button> </NavLink>
                <Link onClick={logout} className="link">
                               <img src={log} className="am" />
                               <div className="deco">DECONNEXION</div>
                               
                          </Link> 
                
                
            
            </div>
            
            
              
              }else{
                bar=  <IconContext.Provider value={{color:'#fff'}} >
                             
                <div className="icon">
             <div>
             &nbsp;&nbsp;&nbsp;
             <img src={logo} className="userLog"  /> 
             </div>
             <div className="naI">
               {secretaire.map((data)=>(data.auto_ecole))} 
             </div>  
                   
             <div className="navbar">
             
                <Link to="#" className={sidebar?  "trois": "menu-bars"}>
                    <FaIcons.FaBars onClick={showSidebar} />
                </Link>
             
                &nbsp;&nbsp;&nbsp;
                  
             
             </div>
            
 <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
    <ul className="nav-menu-items" >
        <li className="navbar-toggle">
            <Link to="#" className="menu-bars">
               <AiIcons.AiOutlineClose onClick={showSidebar}/>
            </Link>
        </li>
        <div className="carD">
                    <div className="profil">

        {/* <AiIcons.AiOutlineUser className="icone-profil"   onClick={toggleMenu} /> */}

        {imgP ? (
  <img
    src={imgP}
    alt="Profil"
    className="image-profilUser"
    onClick={handleClick}
  />
) : (
  <AiIcons.AiOutlineUser className="icone-profil" onClick={handleClick} />
)}


<input
  type="file"
  id={`file-input-${idP}`}
  style={{ display: 'none' }}
  onChange={(event) => handleImageUploadUser(event, idP)}
/>

        <div className="infos-utilisateur">
           <p className="inPo"   onClick={handleClick}> {secretaire.map((data)=>(data.poste))} </p>
          <p className="inN"  onClick={handleClick}> {secretaire.map((data)=>(data.nom))}  {secretaire.map((data)=>(data.prenom))} </p>
        </div>
      </div>

 {/* menu deroulant mobile */}

  
 {isOpen && (
        <div className="menu-deroulant-improbable">
          <div className="element-menu-improbable" onClick={toggleModalProfil}>
            <i className="fas fa-user"></i> Profile
          </div>
          <div className="element-menu-improbable">
            <i className="fas fa-cog"></i> Paramètres
          </div>
          <div className="element-menu-improbable deconnexion-improbable">
            <button onClick={logout}>Se déconnecter</button>
          </div>
        </div>
      )}




{modalActive && (
        <div className="modale-profil-unique">
          <div className="modale-overlay-unique" onClick={toggleModalProfil}></div>
          <div className="modale-contenu-unique">
            {/* En-tête */}
            <div className="modale-entete-unique">
              <h2>Paramètres du Profil</h2>
              <button className="bouton-fermer-unique" onClick={toggleModalProfil}>
                &times;
              </button>
            </div>

            {/* Corps de la modale */}
            <div className="modale-corps-unique">
              {/* Section Informations personnelles */}
              <div className="section-informations-unique">
               
                <div className="info-profil-unique">
                <div className="image-container8">

                <PhotoProvider>
          <PhotoView src={secretaire[0].image ? secretaire[0].image  : moni}>
          <img src={secretaire[0].image ? secretaire[0].image  : moni}  className="photo-modifiable-unique" />
               

          </PhotoView>
        </PhotoProvider>


                <div className="camera-icon90" onClick={ () => isIOS ? handleImportUserClick2(secretaire[0].id) : handleImportUserClick2(secretaire[0].id)  }>
              📷
            </div>
                </div>
                <h2>{secretaire[0].nom} {secretaire[0].prenom}</h2>
                 {/*  <div>
                 Secretaire   {secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}
                   
                  </div> */}

<div className="user-info-unique">
    
    <div className="info-item-unique">
      <hr className="separator-unique" />
      <span><strong>Auto-école :</strong><span> {secretaire[0].auto_ecole} </span></span>
    
    </div>

    <div className="info-item-unique">
      <hr className="separator-unique" />
      <span>
  <strong>Statut abonnement :</strong>
  {schoolInfo.length > 0 ? (
    // Vérifie si la date d'expiration existe et est valide
    new Date(schoolInfo[0].expiration_date) > new Date() ? (
      <span style={{ color: 'green' }}>Actif</span>
    ) : (
      <span style={{ color: 'red' }}>Expiré</span>
    )
  ) : (
    <span style={{ color: 'grey' }}>Information non disponible</span>
  )}
</span>
  
    </div>

    <div className="info-item-unique">
      <hr className="separator-unique" />
      <span><strong>Date d'expiration :</strong><span>Le &nbsp; {moment(schoolInfo[0].expiration_date).utc(1).format('DD-MM-YYYY')}</span> </span>
     
    </div>
 
   
    
    <div className="info-item-unique">
      <hr className="separator-unique" />
      <span>
  <strong>Dernière activité :</strong> 
  {date_connexion.length > 0 ? (
    <>
      {/* Extraire et formater la date */}
      <span>{new Date(date_connexion).toLocaleDateString()}
      &nbsp;à&nbsp;
      
        {new Date(date_connexion).toLocaleTimeString()}
      </span>
    </>
  ) : (
    <span>Information non disponible</span>
  )}
</span>
      <hr className="separator-unique" />
    </div>
  </div>
                  
                </div>
              </div>

              {/* Section Sécurité */}
              <div className="section-securite-unique">
                <h3>Sécurité</h3>
                <button className="btn-securite-unique" onClick={e => setValid('on')}>Modifier le mot de passe</button>
                
              </div>

            

              {/* Section Déconnexion */}
              <div className="section-deconnexion-unique">
                <button className="btn-deconnexion-unique" onClick={logout}>
                  Se déconnecter
                </button>
              </div>
            </div>
          </div>
        </div>
      )}


    {/* Fin menu deroulant mobile */}

      </div>
        {sidedataDir.map((item, index) =>{
           const isActive = location.pathname.startsWith(item.path);
          return (
           
            <li key={index} className={isActive ? `${item.cName} active` : item.cName} onClick={showSidebar}>
                               <Link to={item.path+'/'+o}>
                    <span>{item.title}</span>
                 </Link>
                
            </li>
            
          
          )
 
        })}
       <li className="nav-text">
        <Link onClick={logout}  >
             
               <span>Deconnexion</span>
               
          </Link>
          </li> 
    </ul>
 </nav>
             </div>
             {supMod && (<div className='secretModalSup'>
            <div className='secretOverlay'></div>
            <div className='secretModalContent'>
                <div className="secretContainerModal"> 
                    <h3 className="secretFormTitle">MODIFIER MOT DE PASSE</h3>
                    
                    <div className='secretSupD'>
                        <input type="text" name="nom" className="secretInputCmot" id="nom" value={secretaire.map((data) => (data.nom))} readOnly />
                        <input type="text" name="prenom" className="secretInputCmot" id="prenom" value={secretaire.map((data) => (data.prenom))} readOnly />
                    </div>
                    
                    <label htmlFor="oldPassword">Ancien mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showOldPassword ? 'text' : 'password'} name="oldPassword" className="secretInputCmot" id="oldPassword" onChange={e => setOld(e.target.value)} />
                        <span onClick={toggleOldPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showOldPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info1}</h4>
                    <br/>
                    <label htmlFor="newPassword">Nouveau mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showNewPassword ? 'text' : 'password'} name="newPassword" className="secretInputCmot" id="newPassword" onChange={e => setNew1(e.target.value)} />
                        <span onClick={toggleNewPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <label htmlFor="confirmPassword">Confirmer mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword" className="secretInputCmot" id="confirmPassword" onChange={e => setNew2(e.target.value)} />
                        <span onClick={toggleConfirmPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info2}</h4>
                    <br/>
                    <div className="secretSupD">
                        <Link onClick={handleModf}><button className='secretNm1'>Modifier</button></Link>
                        <Link onClick={toggleSup}><button className='secretNm2'>Annuler</button></Link>
                    </div>
                </div>
            </div>
        </div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)} 


             </IconContext.Provider>
                     
             }



             let autreDep
             let totalAutreDep
             if(depense.length>0){
              autreDep =  <h4 className='alie'>Autres Depenses</h4>
              totalAutreDep =  <div className='vT'>
              TOTAL : {totalDep1} FCFA
              </div>
             }else {
              autreDep = ''
              totalAutreDep = ''
             }


    

             
if(loader){
  return(
      <div className="per">
      
      <div className="loader">
      <div className="overlayLo"> 
          <div className="loaderP">
          <Loader/>
          </div>
          </div>
          </div>
          </div>
  )
}else if(error){
  return (<div>Une erreur est survenue : {error.message}</div>)
}
else{
             if(largeurEcran>= 1024){ 

    return(
        
        <>
        
    <div className='wini'>

   

{bar}


           <br/> 
           <div>                      
        <div className='popo'  >
             <div>
             <select name="poste" className='sel' onChange={e => setAnnexe(e.target.value)}>
            <option >{b}</option>
                     
                {  
                    ville.filter((data) => data.ville!=b).map((data)=>(   
                        
                        <option value={data.ville}>{data.ville}</option>
                            
                    ))
                    
                }
                <option>Toutes les annexes</option>
           </select> <br/>
           <br/>
             <h4 className='che'> Recherche personnalisée </h4>
           <div className='person'>
           <input type='date' value={date1} className='oixe' onChange={e => setDate1(e.target.value)}></input> <input type='date' value={date2} className='oixe' onChange={e => setDate2(e.target.value)} ></input> 
           <button type='submit' onClick={handlePropose} className='butonSF6'>Appliquer</button>
           </div>
             </div>
  

           <select name="poste" className='seles' onChange={e => setValue(e.target.value)} >
             
            <option >Enregistré ce mois</option>
            <option >Tous les Enregistrements</option>
            <option>Enregistré cette année</option>
            
            
            

                     
                
           </select>
           <button className='butonSF5' onClick={handleSubmit}>Appliquer</button>
           </div> 
           
                            
           
           </div>
           
           <br/><br/>
           <div className="bilan-container">
      <div className="recettes-section">
    
      <div className='forTitle'>
    <div className="employ-h2-container">
  <h2 className="employ-h2">NOS RECETTES</h2>
</div>
</div> 

{payement.length > 0 || candidat.length > 0 ? (
  <div> 
        {
                    
                    candidat.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                        <div className="bilan-depense">
                          
                            <span className='texte-depense'>
                            <div> {data.nom} {data.prenom}</div>
                        <span className="montant">{data.montant_payer} FCFA</span><span className='txt'>
                        pour
                        </span>
                         <span className="libelle">Premier versement</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                         </span>

                     
                         
                      </div>
                    ))
                  }


{
                    
                    payement.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                        <div className="bilan-depense">
                          
                            <span className='texte-depense'>
                            <div> {data.nom} {data.prenom}</div>
                        <span className="montant">{data.montant} FCFA</span><span className='txt'>
                        pour
                        </span>
                         <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                         </span>

                     
                         
                      </div>
                    ))
                  }

            {/* 
                             <div className='vT'>
                             TOTAL : {totalPaye+totalPaye2} FCFA
                             </div> */}
                             <ul className="ocdfg-list">  
<li className="ocdfg-list-item">
          <div className="bilan-depense">
            <span className="texte-depense">
              <span className="montant">TOTAL :</span> {totalPaye+totalPaye2} FCFA
            </span>
          </div>
        </li>
        </ul>
     

                             </div>

) : (
<div className="message-erreur">
Aucune recette enregistrée pour le moment.
</div>

)}
      </div>
      <div className="depenses-section">
    
       
        
        <div className='forTitle'>
    <div className="employ-h2-container">
  <h2 className="employ-h2">MES DEPENSES</h2>
</div>
</div> 



{depenseDir.length > 0 ? (      
                    
                    <div className=''>

                            {
                        
                        depenseDir.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                                <span className='texte-depense'>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                         
            
                             
                          </div>
                        ))
                      }
                          {/*  <div className='vT'>
                             TOTAL : {totaDep2} FCFA
                             </div> */}


<ul className="ocdfg-list">                  
<li className="ocdfg-list-item">
          <div className="bilan-depense">
            <span className="texte-depense">
              <span className="montant">TOTAL :</span> {totaDep2} FCFA FCFA
            </span>
          </div>
        </li>
        </ul>
                             </div>

) : (
<div className="message-erreur">
Aucune dépense enregistrée par le directeur trouvé.
</div>

)}
     
        
        <br/>
        <div className='forTitle'>
    <div className="employ-h2-container">
  <h2 className="employ-h2">AUTRES DEPENSES</h2>
</div>
</div> 

{depense.length > 0 ? (      
                    
                    <div className=''>

                            {
                        
                        depense.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                                <span className='texte-depense'>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                            
                             
                          </div>
                        ))
                      }
    {/*   {totalAutreDep} */}


    <ul className="ocdfg-list">  
<li className="ocdfg-list-item">
          <div className="bilan-depense">
            <span className="texte-depense">
              <span className="montant">TOTAL :</span> {totalDep1} FCFA
            </span>
          </div>
        </li>
        </ul>
      </div>

) : (
<div className="message-erreur">
Aucune autre dépense trouvé.
</div>

)}  
                
      </div>
    </div>

             <br/>
                <div className='affich'>
                  <h2 className='gain'>  RESULTAT D'EXERCICE : <div className={`result-card ${(totalPaye+totalPaye2)-totalDep>=0 ? 'gain1':'gain2'}`}>{(totalPaye+totalPaye2)-totalDep} CFA</div></h2>
                  </div>
              
                  </div>
                  <Footer/>
        </>
    )
                    }

                    else if(largeurEcran>=510){
                      return(
                        <>
                    <div className='wini'>
                     {bar}
                        
                
                        <div className='barContener'>
            
            {/* Barre de recherche */}
            <div className='barChercheT'>
                <input type="text"className='rechBar'  name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e => setQuery(e.target.value.toLowerCase())}></input>
            </div>
            <br/>
<select name="poste"   className='rechBar' onChange={e => setAnnexe(e.target.value)} onClick={handleSubmit}>
<option >{b}</option>
     
{  
    ville.filter((data) => data.ville!=b).map((data)=>(   
        
        <option  value={data.ville}  >{data.ville}</option>
            
    ))
    
}
<option>Toutes les annexes</option>
</select> 

            
            {/* Barre de sélection avec bouton Appliquer */}
            <div className='barChoixT'>
                <select name="poste" className='choosee' onChange={e => setValue(e.target.value)}>
                <option >Enregistré ce mois</option>
            <option >Tous les Enregistrements</option>
            <option>Enregistré cette année</option>
                </select>
                {/* Bouton Appliquer pour le select */}
                <button className='butonSF' onClick={handleTout}>Appliquer</button>
            </div>
            
            {/* Barre de recherche personnalisée */}
            <div className='persBarT'>
                
                <input type='date' className='perSF' value={date1} onChange={e => setDate1(e.target.value)}></input>
                <input type='date' className='perSF' value={date2} onChange={e => setDate2(e.target.value)}></input>
                <button type='submit' className='butonSF' onClick={handlePropose}>APPLIQUER</button>
            </div>
            
            </div>
                 

            <br/><br/>
           <div className="bilan-container">
      <div className="recettes-section">
    

                            <div className='forTitle'>
    <div className="employ-h2-container">
  <h2 className="employ-h2">NOS RECETTES</h2>
</div>
</div> 

{payement.length > 0 || candidat.length > 0 ? (
  <div> 
           
        {
                    
                    candidat.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                        <div className="bilan-depense">
                          
                            <span className='texte-depense'>
                            <div> {data.nom} {data.prenom}</div>
                        <span className="montant">{data.montant_payer} FCFA</span><span className='txt'>
                        pour
                        </span>
                         <span className="libelle">Premier versement</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                         </span>

                     
                         
                      </div>
                    ))
                  }


{
                    
                    payement.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                        <div className="bilan-depense">
                          
                            <span className='texte-depense'>
                            <div> {data.nom} {data.prenom}</div>
                        <span className="montant">{data.montant} FCFA</span><span className='txt'>
                        pour
                        </span>
                         <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                         </span>

                     
                         
                      </div>
                    ))
                  }

            
                            {/*  <div className='vT'>
                             TOTAL : {totalPaye+totalPaye2} FCFA
                             </div> */}

<ul className="ocdfg-list">  
<li className="ocdfg-list-item">
          <div className="bilan-depense">
            <span className="texte-depense">
              <span className="montant">TOTAL :</span> {totalPaye+totalPaye2} FCFA
            </span>
          </div>
        </li>
        </ul>
                             
                             </div>

) : (
<div className="message-erreur">
Aucune recette enregistrée pour le moment.
</div>

)}
     
      </div>
      <div className="depenses-section">
    
       
        


                            <div className='forTitle'>
    <div className="employ-h2-container">
  <h2 className="employ-h2">MES DEPENSES</h2>
</div>
</div> 

{depenseDir.length > 0 ? (      
                    
                    <div className=''>

                            {
                        
                        depenseDir.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                                <span className='texte-depense'>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                         
            
                             
                          </div>
                        ))
                      }
                           {/* <div className='vT'>
                             TOTAL : {totaDep2} FCFA
                             </div> */}

<ul className="ocdfg-list">                  
<li className="ocdfg-list-item">
          <div className="bilan-depense">
            <span className="texte-depense">
              <span className="montant">TOTAL :</span> {totaDep2} FCFA FCFA
            </span>
          </div>
        </li>
        </ul>

                             </div>

) : (
<div className="message-erreur">
Aucune dépense enregistrée par le directeur trouvé.
</div>

)}
        
        <br/>
        <div className='forTitle'>
    <div className="employ-h2-container">
  <h2 className="employ-h2">AUTRES DEPENSES</h2>
</div>
</div> 

{depense.length > 0 ? (      
                    
                    <div className=''>

                            {
                        
                        depense.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                                <span className='texte-depense'>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                            
                             
                          </div>
                        ))
                      }
 {/*  {totalAutreDep} */}

 <ul className="ocdfg-list">  
<li className="ocdfg-list-item">
          <div className="bilan-depense">
            <span className="texte-depense">
              <span className="montant">TOTAL :</span> {totalDep1} FCFA
            </span>
          </div>
        </li>
        </ul>

  </div>

) : (
<div className="message-erreur">
Aucune autre dépense trouvé.
</div>

)}  
      </div>
    </div>


             <br/>
                <div className='affich'>
                  <h2 className='gain'>  RESULTAT D'EXERCICE : <h3 className={(totalPaye+totalPaye2)-totalDep>=0 ? 'gain1':'gain2'}>{(totalPaye+totalPaye2)-totalDep} CFA</h3></h2>
                  </div>


                    </div>
                
                    <Footer/>
                    
                
                    </>
                    )
                    }

                    else{
                      return(
                        <>
                    <div className='wini'>
                     {bar}
                        
                
                        <div className='barContener'>
            
            {/* Barre de recherche */}
       
                <input type="text"className='seLe'  name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e => setQuery(e.target.value.toLowerCase())}></input>
         
           <br/> 
<select name="poste"   className='rechBar' onChange={e => setAnnexe(e.target.value)} >
<option >{b}</option>
     
{  
    ville.filter((data) => data.ville!=b).map((data)=>(   
        
        <option  value={data.ville}  >{data.ville}</option>
            
    ))
    
}
<option>Toutes les annexes</option>
</select> 

            
            {/* Barre de sélection avec bouton Appliquer */}
            <div className='barChoixT'>
                <select name="poste" className='choosee' onChange={e => setValue(e.target.value)}>
                <option >Enregistré ce mois</option>
            <option >Tous les Enregistrements</option>
            <option>Enregistré cette année</option>
                </select>
                {/* Bouton Appliquer pour le select */}
                <button className='butonSF' onClick={handleSubmit}>Appliquer</button>
            </div>
            
            {/* Barre de recherche personnalisée */}
            <div className='persBarT'>
                
                <input type='date' className='perSF' value={date1} onChange={e => setDate1(e.target.value)}></input>
                <input type='date' className='perSF' value={date2} onChange={e => setDate2(e.target.value)}></input>
                <button type='submit' className='butonSF' onClick={handlePropose}>APPLIQUER</button>
            </div>
            
            </div>
            {load}
                 
                        <div className=''>
                        <div className=''>
                      
                            <br/>



                           

                                            <div className='forTitle'>
    <div className="employ-h2-container">
  <h2 className="employ-h2">NOS RECETTES</h2>
</div>
</div> 
                           
{payement.length > 0 || candidat.length > 0 ? (
  <div>   
                            {
                    
                    candidat.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                        <div className="bilan-depense">
                          
                            <span className='texte-depense'>
                            <div> {data.nom} {data.prenom}</div>
                        <span className="montant">{data.montant_payer} FCFA</span><span className='txt'>
                        pour
                        </span>
                         <span className="libelle">Premier versement</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                         </span>

                     
                         
                      </div>
                    ))
                  }


{
                    
                    payement.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                        <div className="bilan-depense">
                          
                            <span className='texte-depense'>
                            <div> {data.nom} {data.prenom}</div>
                        <span className="montant">{data.montant} FCFA</span><span className='txt'>
                        pour
                        </span>
                         <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                         </span>

                     
                         
                      </div>
                    ))
                  }

            
                           {/*   <div className='vT'>
                             TOTAL : {totalPaye+totalPaye2} FCFA
                             </div> */}

<ul className="ocdfg-list">  
<li className="ocdfg-list-item">
          <div className="bilan-depense">
            <span className="texte-depense">
              <span className="montant">TOTAL :</span> {totalPaye+totalPaye2} FCFA
            </span>
          </div>
        </li>
        </ul>

                             
                             </div>

) : (
<div className="message-erreur">
Aucune recette enregistrée pour le moment.
</div>

)}
                        </div>
                        
                    </div>
                
                    <br/>
                            



                            <div className='forTitle'>
    <div className="employ-h2-container">
  <h2 className="employ-h2">MES DEPENSES</h2>
</div>
</div> 
{depenseDir.length > 0 ? (      
                    
                    <div className=''>
                            {
                        
                        depenseDir.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                                <span className='texte-depense'>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                         
            
                             
                          </div>
                        ))
                      }
                           {/* <div className='vT'>
                             TOTAL : {totaDep2} FCFA
                             </div> */}

<ul className="ocdfg-list">                  
<li className="ocdfg-list-item">
          <div className="bilan-depense">
            <span className="texte-depense">
              <span className="montant">TOTAL :</span> {totaDep2} FCFA FCFA
            </span>
          </div>
        </li>
        </ul>
                             </div>

) : (
<div className="message-erreur">
Aucune dépense enregistrée par le directeur trouvé.
</div>

)}
        <br/>
        <div className='forTitle'>
    <div className="employ-h2-container">
  <h2 className="employ-h2">AUTRES DEPENSES</h2>
</div>
</div> 
{depense.length > 0 ? (      
                    
                    <div className=''>
                            {
                        
                        depense.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                                <span className='texte-depense'>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                            
                             
                          </div>
                        ))
                      }
        
    {/*    {totalAutreDep} */}
    <ul className="ocdfg-list">  
<li className="ocdfg-list-item">
          <div className="bilan-depense">
            <span className="texte-depense">
              <span className="montant">TOTAL :</span> {totalDep1} FCFA
            </span>
          </div>
        </li>
        </ul>


       </div>

) : (
<div className="message-erreur">
Aucune autre dépense trouvé.
</div>

)}            
                    <br/>
                
                    <div className='affich'>
                  <h2 className='gain'>  RESULTAT D'EXERCICE : <h3 className={(totalPaye+totalPaye2)-totalDep>=0 ? 'gain1':'gain2'}>{(totalPaye+totalPaye2)-totalDep} CFA</h3></h2>
                  </div>

                  <br/>
                
                    </div>
                
                    <Footer/>
                    
                
                    </>
                    )
                    }
                  }
}

