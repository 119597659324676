import React, {useEffect, useState } from "react"
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "./loader";
import moni from './image/moni.png';
import croix from './image/croix.png'
import moment from "moment";
import Cookies from 'js-cookie';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { FaPlus, FaCheck, FaExclamationTriangle  } from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from "./popup";
import ErrorModal from "./errorpopup";
import secre from './image/images.png'
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { API_BASE_URL } from '../apiConfig';
export default function Profil(){
  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
    'credentials': 'include',
  };
         const [query, setQuery]= useState(""); 

         const [titre,setTitre]= useState(Cookies.get('titre'))
         const [vtoken, setVtoken]= useState(Cookies.get('token'))
         const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
         const [dure, setDure]= useState('')
         const [langue, setLangue]= useState('')
         const [le, setLe]= useState('')
         const [name, setName]= useState('')
         const [prenom, setPrenom]= useState('')
         const [username,setUsername ]= useState('')
         const [password,setPassword ]= useState('')
         const [total, setTotal]= useState('')
         const [contact, setContact]= useState('')
         const [sexe, setSexe]= useState('')
         const [adresse, setAdresse]= useState('')
         const [age,setAge]= useState('')
         const [montant, setMontant]= useState('')
         const [statut, setStatut]= useState('')
         const [permis, setPermis]= useState('')
         const [date, setDate]= useState('')
         const [dateRetrait, setDateRetrait]= useState('')
         const [nombre, setNombre]= useState('')
         const [nombre2, setNombre2]= useState('')
         const {id}= useParams();
         const [loginStatut, setLoginStatut]= useState('')
         const navigate = useNavigate();
         const [payement, setPayement]= useState([])
         const [error, setError] = useState(null);
         const [loader, setLoader]= useState(true)
         const [showPassword, setShowPassword] = useState(false);
         const [secretaire, setSecretaire] = useState([])
         const [categorie, setCategorie] = useState('')
         const [image, setImage]= useState([])
         const [viewLoader, setViewLoader]= useState(false)
         const [email, setEmail]= useState('')
         const togglePasswordVisibility = () => {
          setShowPassword(!showPassword);
        };
        const [examDate, setExamDate]= useState('')
        const [showExamDate,setShowExamDate]= useState('')


        var date2= new Date();

        var autre= date2.toISOString().split('T')[0]  

  // Déclaration de l'état des badges
  const [badges, setBadges] = useState({
    cours: false,
    inscritExamen: false,
    admisCode: false,
    admisConduite: false,
    permisRetire: false,
  });


        const handleDelete = async (candidatId) => {
          try {
            const response = await fetch(`${API_BASE_URL}/deleteCandidat20/${candidatId}`, {
              method: 'DELETE',
              headers: {
             
                'Content-Type': 'application/json',
              },
            });
        
            if (response.ok) {
              setViewLoader(false)
              navigate(-1); 
            } else {
              const data = await response.json();
              alert(`Erreur: ${data.message}`);
            }
          } catch (error) {
            console.error("Erreur lors de la suppression :", error);
            alert("Une erreur est survenue.");
          }
        };
        



        const [showConfirm, setShowConfirm] = useState(false);

        // Fonction pour gérer la confirmation
        const confirmDelete = () => {
          setViewLoader(true)
          setShowConfirm(false);
          handleDelete(id); // Si confirmé, on supprime le candidat
        };
           

        const [activeStep, setActiveStep] = useState(0); // Pour le Stepper

        const [showDate, setShowDate] = useState(false);
         
  useEffect(()=>{
      axios.get(API_BASE_URL +'/navbar?id='+gestauto+'&titre='+titre+'', { headers })
      .then(res => setSecretaire(res.data))
      .catch(err => console.log(err));
  }, [])
  var effet
  var ob
  let lig 
  let drop = ''
  if(secretaire.map((data)=>(data.poste))=='secretaire' || secretaire.map((data)=>(data.poste))=='directeur' ){
    effet=<Link to={'/retrait/'+le} className='linko'><button className="retrait">MODIFIER</button> </Link>
    drop= <div className="dave">  <button className="prsp20" onClick={() => setShowDate(true)}>Date d'examen</button>    </div>
  }else{effet=''

    drop = <br/>
  }
 
  if(secretaire.map((data)=>(data.poste))=='directeur'){
    ob=<Link to={'/lesObservationsDir/'+le} className="linko"><button className="retrait">OBSERVATION</button></Link> 
    drop= <div className="dave"> <button className="prsp20" onClick={() => setShowDate(true)}>Date d'examen</button> <button className="prsp" onClick={() => setShowConfirm(true)}>Supprimer</button></div>
  
  }else{
    ob=<Link to={'/lesObservations/'+le} className="linko"><button className="retrait">OBSERVATION</button></Link> 
    
  }
     
         const [candidat, setCandidat] = useState([])
         useEffect(()=>{
             axios.get(API_BASE_URL +'/update/'+id, { headers })
             .then(res => { 
                 setLe(res.data[0].id)
                 setName(res.data[0].nom)
                 setPrenom(res.data[0].prenom)
                 setContact(res.data[0].contact)
                 setSexe(res.data[0].sexe)
                 setAdresse(res.data[0].adresse)
                 setAge(res.data[0].age)
                 setMontant(res.data[0].montant_payer)
                 setStatut(res.data[0].statut_payement)
                 setDate(res.data[0].date)
                 setPermis(res.data[0].statut_retrait_permis)
                 setDateRetrait(res.data[0].date_retrait)
                 setCandidat(res.data)
                 setTotal(res.data[0].montant_total)
                 setUsername(res.data[0].user_name)
                 setPassword(res.data[0].password)
                 setDure(res.data[0].dure)
                 setLangue(res.data[0].langue)
                 setCategorie(res.data[0].categorie)
                 setImage(res.data[0].image)
                 setEmail(res.data[0].email)
                 setShowExamDate(  moment(res.data[0].examen_date).utc(1).format('YYYY-MM-DD'))
                setLoader(false)
                setShow(res.data[0].examen_date)
                setBadges({
                  cours: true, // Toujours actif
                  inscritExamen: res.data[0].examen_date !== null,
                  admisCode: res.data[0].activeCode === 1,
                  admisConduite: res.data[0].activeConduite === 1,
                  permisRetire: res.data[0].statut_retrait_permis === 'oui',
                });



                 // Déterminer l'étape active
                 let currentStep = 0;
                 if (badges.inscritExamen) currentStep = 1;
                 if (badges.admisCode) currentStep = 2;
                 if (badges.admisConduite) currentStep = 3;
                 if (badges.permisRetire) currentStep = 4;
                 setActiveStep(currentStep);

             }
    
                 
                 )
             .catch(err => {
              console.log(err)
              setError(err)
              setLoader(false)
              
            });
     
         
         }, [])
       
     
     const[modal, setModal]= useState(false) 
const toggleModal = ()=> {
    setModal(!modal)
}
 

useEffect(()=>{
  setExamDate(autre)
}, [])

       
const [show, setShow] = useState('');
const [isModalOpen, setIsModalOpen] = useState(false);
const [currentId, setCurrentId] = useState(null);
const [fileInputId, setFileInputId] = useState(null); // Pour stocker l'ID du champ de fichier

const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);



       // Fonction pour ouvrir la boîte de dialogue d'importation de fichier
       function handleImportClick2(id) {
        const fileInput = document.getElementById(`file-input-${id}`);
        if (fileInput) {
          fileInput.click();
        } else {
          console.error(`File input with id 'file-input-${id}' not found`);
        }
      }
     
     // Fonction pour gérer l'upload de fichier
     function handleImageUpload(event, id) {
      setViewLoader(true)
       const file = event.target.files[0];
       if (file) {
         const formData = new FormData();
         formData.append('image', file);
         formData.append('id', id);
     
         
         axios.post(API_BASE_URL +'/upload-image', formData)
           .then(response => {
            setTimeout(() => {
             
              window.location.reload(); // Recharger la page après 3 secondes
            }, 100);
           
           })
           .catch(error => {
             console.error("Erreur lors de l'upload de l'image:", error);
           });
       }
     }
     



     const [all,setAll]=useState('')


    
useEffect(()=>{

  if (show){
    setExamDate(showExamDate)
    setAll( <div class="bande-defilante">
    Examen prévu le {moment(show).utc(1).format('DD MMMM YYYY')}
    </div>)
    
    }

}, [show])
       

// Détecter si l'utilisateur est sur iOS
const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  


// Fonction pour ouvrir la caméra sur iOS
function openCameraIOS2(id) {
  if (isIOS) {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*;capture=camera'; // Ouvre directement l'appareil photo sur iOS
    fileInput.style.display = 'none'; // Cacher l'input file visuellement

    // Gérer la sélection d'une image via l'appareil photo
    fileInput.onchange = function(event) {
      const file = event.target.files[0];
      if (file) {
        setViewLoader(true)
        const formData = new FormData();
        formData.append('image', file, 'profile-image.jpg');
        formData.append('id', id); // Remplacez par votre ID de candidat

        axios.post(API_BASE_URL +'/upload-image', formData)
          .then(response => {
            setTimeout(() => {
            
              console.log('Image uploadée avec succès');
              window.location.reload(); // Recharger la page après 3 secondes
            }, 200);
          
          })
          .catch(error => console.error('Erreur lors de l\'upload:', error));
      }
    };

    // Ajouter le champ input au document et le déclencher
    document.body.appendChild(fileInput);
    fileInput.click(); // Simuler un clic pour ouvrir la caméra
  }
}



function handleSubmit(event){
   
  setViewLoader(true)
event.preventDefault();

  axios.put(API_BASE_URL +'/examdate/'+id, {examDate}, { headers})
.then(res => {
  setViewLoader(false)
  setShowDate(false)
  window.location.reload();
}).catch(err => console.log(err));


}






const [editing, setEditing] = useState(false);
const [newEmail, setNewEmail] = useState("");
const [eror, setEror] = useState("");

const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};



const showError = (message) => {
  setEror(message);
  setTimeout(() => {
    setEror("");
  }, 3000); // Efface l'erreur après 3 secondes
};


function handleSaveEmail (event)  {

  console.log('pourtant je reconnais')
 
  event.preventDefault();

  if (!newEmail.trim()) {
    showError("L'email ne peut pas être vide.");
    return;
  }

  if (!isValidEmail(newEmail)) {
    showError("Veuillez entrer un email valide.");
    return;
  }

  setEmail(newEmail);
  axios.put(API_BASE_URL +'/upmail/'+id, {newEmail},{ headers})
  .then(response => {
    setEditing(false);
    
  })
  .catch(error => {
    console.error("Erreur lors de la mise à jour du mail", error);
  });
 
 
};



     
     
var permisInfo
var laDate
if(permis=='oui'){
  permisInfo='Retiré'
  laDate=<div className="adro">
  <div >LE</div> <div>{moment(dateRetrait).utc(1).format('DD-MM-YYYY')}</div>
  </div>
}else{
    permisInfo='Non retiré'
}
let cata 
if(categorie){
  cata=<> <div className="adro">
  <div >Categorie</div> <div>Permis {categorie}</div>
  </div><br/></>
}else{
  cata= ''
}



let load

        if(viewLoader==true){
            load=   <div className="avt">
            <div className="rin">
              <div className="chm">
                <Loader/>
              </div>
            </div>
          </div>
        }else{
            load=''
        }


if(loader){
  return(
      <div className="per">
      
      <div className="loader">
      <div className="overlayLo"> 
          <div className="loaderP">
          <Loader/>
          </div>
          </div>
          </div>
          </div>
  )
}else if(error){
  return (<div>Une erreur est survenue : {error.message}</div>)
}else{

    
    return(


        <>
        <div className="pofille">
       <div className="box">
        <br/>
        {load}
        {all}
       <div className="playProfil"> 
       <div className="image-container8">

{image && (
        <PhotoProvider>
          <PhotoView src={image}>
          
<img src={image ? `${image}` : (sexe === 'Masculin' ? moni : secre)} alt="Candidat" className="profile-image" />

          </PhotoView>
        </PhotoProvider>
      )}

{!image && (
       
          
       <img src={image ? `${image}` : (sexe === 'Masculin' ? moni : secre)} alt="Candidat" className="profile-image" />
       
             )}

<div className="camera-icon60" onClick={ () => isIOS ? openCameraIOS2(id) : handleImportClick2(id) }>
              📷
            </div>
          </div>
               
          <input
           type="file"
           id={`file-input-${id}`}
           style={{ display: 'none' }}
         onChange={(event) => handleImageUpload(event, id)}
         />    
                   
<Link  onClick={toggleModal} >     <h5 className="osi">  {name} {prenom} </h5></Link>

{drop}


<div className="arrow-container">
  <div className={`arrow ${badges.cours ? 'active' : ''}`} data-step="1">Cours</div>
  <div className={`arrow ${badges.inscritExamen ? 'active' : ''}`} data-step="2">Inscrit en ligne</div>
  <div className={`arrow ${badges.admisCode ? 'active' : ''}`} data-step="3">Admis Code</div>
  <div className={`arrow ${badges.admisConduite ? 'active' : ''}`} data-step="4">Admis Conduite</div>
  <div className={`arrow ${badges.permisRetire ? 'active' : ''}`} data-step="5">Permis Retiré</div>
</div>


{modal && (<div className='modal'>
    <div className='overlay'>
        
    </div>
    <div className='modal-content'>
        <div> <h3 className="useName">USERNAME :</h3> {username}<br/>
        <h3 className="useName">PASSWORD :</h3> {password}
        </div>
      <Link onClick={toggleModal}>  <img src={croix} className='close-modal'/></Link>
    </div>
</div>)}

{showConfirm && (
  <div className="dropeModal-overlay">
    <div className="dropeModal-content">
      <p>Êtes-vous sûr de vouloir supprimer ce candidat ?</p>
      <button className="dropeModal-button dropeModal-button-confirm" onClick={confirmDelete}>
        Confirmer
      </button>
      <button className="dropeModal-button dropeModal-button-cancel" onClick={() => setShowConfirm(false)}>
        Annuler
      </button>
    </div>
  </div>
)}



{showDate && (
  <div className="dropeModal-overlay">
    <div className="dropeModal-content">
    <FontAwesomeIcon icon={faTimes} onClick={() => setShowDate(false)} style={{ color: 'black', fontSize: '28px', cursor: 'pointer', marginLeft: '250px', marginTop: '-60px',marginBottom: '10px' }} />
           
      <p>Eregistrer ici la date de prévue pour l'examen</p>
      
      <input className="bless" type="date"     value={examDate} onChange={e => setExamDate(e.target.value)}/>
      <button className="dropeModal-button dropeModal-button-confirm" onClick={handleSubmit}>Enregistrer</button>
     
    </div>
  </div>
)}

                   </div>
                   
                   <br/>
                  
                   <div className="adro">
                  <div > SEXE</div> <div>{sexe}</div>
                  </div>
                  <br/>
                 
                   <div className="adro">

                 <div className="tes">  ADRESSE</div> <div className="tes">{adresse}</div>
                   </div>
                   <br/>
                   <div className="adro">
                  <div > CONTACT</div> <div>{contact.length === 8 ? "01"+ contact   : contact}</div>
                  </div>
                  <br/>
                  
                  <div className="adro">
                  <div > INSCRIT</div> <div>{moment(date).utc(1).format('DD-MM-YYYY')}</div>
                  </div>
                  <div></div>
                  <br/>
                  {cata}
                  
                  <div className="adro">
                  <div > Duré de la formation</div> <div>{dure ? dure.substring(0, 2) : ''} mois</div>
                  </div>
                  <br/>
                  <div className="adro">
                  <div > Langue</div> <div>{langue}</div>
                  </div>
                  <br/>
                  <div className="adro">
                  <div > Email</div> <div className="email-container">

                  {email ? (
                    <div>{email}</div>
        
      ) : editing ? (
        <div className="email-input-container">
          <input
            type="email"
            placeholder="Entrer un email"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
            className="email-input"
          />
          <button onClick={handleSaveEmail} className="save-btn">
            <FaCheck />
          </button>
        </div>
      ) : (
        <button onClick={() => setEditing(true)} className="add-email-btn">
          <FaPlus /> Ajouter un email
        </button>
      )}

{eror && (
        <div className="popup-error">
          <FaExclamationTriangle /> {eror}
        </div>
      )}


                  </div>
                  </div>
                  <br/>
                  <div className="adro">
                  <div > USERNAME</div> <div>{username}</div>
                  </div>
                  <br/>
                  <div className="adro">
                  <div > PASSWORD</div> <div>{showPassword ? password : '*'.repeat(password.length)}
               <span>   {showPassword ? (
        <FaEyeSlash onClick={togglePasswordVisibility} />
      ) : (
        <FaEye onClick={togglePasswordVisibility} />
      )}</span>
                  </div>
                  </div>
                  <br/>
                  <div className="adro">
                  <div >PERMIS </div> <div>{permisInfo}</div>
                  </div>
                  <br/>
                  {laDate}
                
                  <div className="poBut">
                  {ob}
                
                  {effet}
                  </div>
                   </div>
                   </div>
        </>
    )
}
}