
import axios from "axios";
import React, {useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import Loader from "../components/loader";
import jsPDF from 'jspdf';
import moment from "moment";
import 'moment/locale/fr';
import 'jspdf-autotable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { API_BASE_URL } from '../apiConfig';
export default function CreateCandidatDir() {
     
    const [dure, setDure]= useState('1')
    const [langue, setLangue]= useState('ADJA')
    const [titre,setTitre]= useState(Cookies.get('titre'))
    const [vtoken, setVtoken]= useState(Cookies.get('token'))
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
const [ce,setCe]= useState(Cookies.get('autoa'))
    const [montant, setMontant]= useState('')
    const navigate = useNavigate();
    const [user,setUser]= useState('')
    const [pass,setPass]= useState('')
    const [error, setError] = useState(null);
    const [categorie, setCategorie]= useState('A')
    const [annexe, setAnnexe]= useState('')
    
    const [idAnnexe,setIdAnnexe]= useState('')
    
    const[lea,setLea]= useState([])
    const [secretaire, setSecretaire] = useState([])
    const [logaId, setLogaID]= useState(0)
    const [loga, setLoga]= useState('')
    const [name, setName]= useState('')
    const [prenom, setPrenom]= useState('')
    const [total, setTotal]= useState('')
    const [contact, setContact]= useState('')
    const [sexe, setSexe]= useState('Masculin')
    const [adresse, setAdresse]= useState('')
    const [age,setAge]= useState('')
    const [loader, setLoader]= useState(false);
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
  
    const [date, setDate]= useState('')
    const [info, setInfo]= useState('')
    const [control, setControl]= useState('')
    const [dateControl, setDateControl]= useState('')
   
    const maintenant = new Date();
    const heure = maintenant.getHours();
    const minutes = maintenant.getMinutes().toString().padStart(2, '0');
    const secondes = maintenant.getSeconds().toString().padStart(2, '0');

    const [infoAuto, setInfoAuto] = useState('')
    const [localisation, setLocalisation] = useState('')
    const [logoA, setLogoA]= useState('')
    const [sign,setSign]= useState('')
    const [rcm, setRcm] = useState('')
    const [ifu, setIfu] = useState('')
    const [autorisation, setAutorisation] = useState('')
    const [mail, setMail] = useState('')
    const [NB,setNB]= useState('')
    const [time, setTime]= useState('')
    const  le = lea.map((data)=>(data.id_ecole))
  
    var o=le
    const {id}= useParams();
    if (o!=0){
        o=le
    }else{
        
        o=id
    }

   


         useEffect(()=>{
            axios.post(API_BASE_URL +'/idAnnexe', {o}, { headers})
            .then(res =>{ 
                
                setIdAnnexe(res.data[0].ville)             
            }
               
    
            ).catch(err => console.log(err));
        }, [])
            var b
   





    const [ido, setIdo]= useState('')
    useEffect(()=>{
        axios.get(API_BASE_URL +'/navbar?id='+gestauto+'&titre='+titre+'', { headers})
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
    }, [])

   



    const [ville, setVille] = useState([])
    useEffect(()=>{
        axios.get(API_BASE_URL +'/candidatDir?id='+ce+'' , { headers})
        .then(res => 
            {
                setVille(res.data)
                setLoga(res.data[0].ville)
                setLogaID(res.data[0].id_annexe)
                if(id==0){
                    setIdo(res.data[0].id_ecole)
                 }else{
                    setIdo(id)
                 }
                if(annexe==''){
                    setAnnexe(res.data[0].ville)
                }
                
            })
        .catch(err => console.log(err));
    }, [])

    var id_annexe=0;
      if(ville.map((data)=>(data.id_annexe))){
     [id_annexe]= ville.map((data)=>(data.id_annexe));
    }

    function firstSubmit(event){
        event.preventDefault();
if(id_annexe!=0){
        axios.post(API_BASE_URL +'/candidatDir', {annexe,id_annexe}, { headers})
    .then(res => {
        setLea(res.data)
        setIdo(res.data[0].id_ecole)
     
        const a= res.data[0].id_ecole
     


       

    }).catch(err => console.log(err));
    }}
           
         
           if(idAnnexe!=''){
            b= idAnnexe
          }  else{
            b= loga
          }

          const[modal, setModal]= useState(false) 
          const toggleModal = ()=> {
              setModal(!modal)
          }

         
          var username=''
          var password=''


          useEffect(()=>{
           
            axios.get(API_BASE_URL +'/infoAuto?id='+ido+'&titre='+titre+'', { headers })
            .then(res => {
                setInfoAuto(res.data[0].contact)
                setLocalisation(res.data[0].localisation)
               setLogoA(res.data[0].logo)
               setSign(res.data[0].signature)
               setRcm(res.data[0].rcm)
               setIfu(res.data[0].ifu)
               setAutorisation(res.data[0].autorisation)
               setMail(res.data[0].mail)
               setNB(res.data[0].nb)
            })
            .catch(err => console.log(err));
        }, [ido])





          function genererCredentiels() {
           

           
            username = prenom.toLowerCase() + '.' + name.toLowerCase();

            
             password = Math.random().toString(36).substring(2, 10);

             if(username && password){
                setUser(username)
                setPass(password)
             }
       
        }


        //facture



       // Fonction pour générer un identifiant aléatoire de 5 chiffres
       const generateRandomId = () => {
        let result = '';
        for (let i = 0; i < 5; i++) {
          result += Math.floor(Math.random() * 10); // Génère un chiffre aléatoire entre 0 et 9
        }
        return result;
      };
            // Fonction pour générer un timestamp de 6 caractères
    const generateTimestamp = () => {
        const timestamp = ('000000' + Date.now()).slice(-6); // Obtenir les 6 derniers caractères du timestamp
        return timestamp;
      };
    
    
     // Générer un identifiant aléatoire de 4 caractères
     const randomId = generateRandomId().padEnd(5, '0'); // Remplir avec des zéros si nécessaire
    
    
    
      const generatePaymentReference = () => {
        // Générer un timestamp avec 6 caractères
        const timestamp = generateTimestamp();
      
     
      
        // Concaténer le timestamp et l'identifiant aléatoire de manière aléatoire
      const randomNumber = Math.random();
      const paymentReference = randomNumber > 0.5 ? `${timestamp}${randomId}` : `${randomId}${timestamp}`;
    
     
      
        return paymentReference;
      };
    
      const reference = generatePaymentReference();
    



      const loadImageAsBase64 = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      };
    

      
    
   /*    const generatePDF = async(name, prenom, contact, adresse, montant, total, date,referencePaiement,imageUrl,localisation,infoAuto) => {
        // Créer une nouvelle instance de jsPDF
        const doc = new jsPDF();
        const reste = total - montant;
      
        try {
            if (imageUrl) {
              const base64Logo = await loadImageAsBase64(imageUrl); // Utilisation de await pour charger l'image
              doc.addImage(base64Logo, 'PNG', 20, 10, 30, 30);
            }
          } catch (error) {
            console.error("Erreur lors du chargement de l'image :", error);
         
          }

doc.setFontSize(12);
doc.setTextColor(0, 0, 0); // Couleur noire

doc.text(`Auto Ecole ${secretaire.map((data) => data.auto_ecole)}`, 105, 15, null, null, 'center');

if (infoAuto) {
doc.setFontSize(10);
doc.text(`TEL : ${infoAuto}`, 105, 20, null, null, 'center');
}
if (mail) {
    doc.text(`Email : ${mail}`, 105, 25,null,null,'center');

    if (localisation) {
        // Limite de caractères par ligne (ajustez si nécessaire)
        const maxLineWidth = 120; // Largeur maximale pour la ligne, ajustez selon vos marges
    
        // Découpe le texte en plusieurs lignes si nécessaire
        const locationText = ` ${localisation}`;
        const lines = doc.splitTextToSize(locationText, maxLineWidth);
    
        // Dessine chaque ligne sur le PDF
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0); // Couleur noire
        lines.forEach((line, index) => {
            doc.text(line, 105, 30 + (index * 5), null, null, 'center'); // Ajuste l'espacement entre les lignes
        });
    }
 
}else{
    if (localisation) {
        // Limite de caractères par ligne (ajustez si nécessaire)
        const maxLineWidth = 120; // Largeur maximale pour la ligne, ajustez selon vos marges
    
        // Découpe le texte en plusieurs lignes si nécessaire
        const locationText = ` ${localisation}`;
        const lines = doc.splitTextToSize(locationText, maxLineWidth);
    
        // Dessine chaque ligne sur le PDF
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0); // Couleur noire
        lines.forEach((line, index) => {
            doc.text(line, 105, 25 + (index * 5), null, null, 'center'); // Ajuste l'espacement entre les lignes
        });
    }
}

// Ajouter la localisation si disponible




        // Définir la police et la taille du texte
        doc.setFont('helvetica', 'normal');
      
        // Insérer le logo et le nom du système
        doc.setFontSize(20);
        doc.setTextColor(25, 25, 112);
        doc.text('Quittance de Paiement', 105, 48, null, null, 'center');
      
       
      
        // Date
        doc.setFontSize(11);
        doc.text(`Date: ${moment(date).utc(1).format('DD-MM-YYYY')}`, 20, 55);
        doc.text(`Heure: ${heure}:${minutes}:${secondes}`, 20, 60);

     
        if (ifu) {
            doc.setFontSize(9);
            doc.text(`IFU : ${ifu}`, 105, 55);
          
        }
        if (rcm) {
            doc.setFontSize(9);
            doc.text(`N°RCCM : ${rcm}`, 105, 60);
          
        }

        if (autorisation) {
            doc.setFontSize(9);
            doc.text(`Référence de l’autorisation : ${autorisation}`, 105, 65);
           
        }
        // Ligne de séparation
        doc.setLineWidth(0.5);
        doc.line(20, 68, 190, 68);
      
        // Nom et prénom du payeur et référence de paiement
        doc.setFontSize(11);
  
        doc.setFont('helvetica', 'bold');

doc.text(`${prenom} ${name}`, 31, 75);



doc.setFontSize(11);
doc.setTextColor(25, 25, 112);
doc.setFont('helvetica', 'normal');
doc.text(`Catégorie: Permis ${categorie}`, 20, 80);
        doc.text(`Langue de Formation: ${langue}`, 20, 85);
        doc.text(`Durée de Formation: ${dure} mois`, 20, 90)
        doc.text(`Référence de Paiement: ${referencePaiement}`, 105, 80);
      
        // Tableau des informations
        const data = [
         
          ['Frais de Formation', `${total} FCFA`],
          ['Montant payé', `${montant} FCFA`],
   
          ['Reste à payer', `${reste} FCFA`]
        ];
      
        // Utiliser autoTable pour générer le tableau
        doc.autoTable({ startY: 100,head: [['Désignation', 'Valeur']], body: data });
      // Calcul du montant restant
      doc.setFontSize(11);
doc.text(`Arrêté le present reçu à la somme de: ${montant} FCFA`, 20, doc.autoTable.previous.finalY + 10);

// Ligne de signature
doc.setFontSize(11);
doc.text('Signature directeur:', 20, doc.autoTable.previous.finalY + 20);


if(NB){
    doc.setFontSize(11);
    doc.setTextColor(255, 0, 0);
    doc.text(`NB: ${NB}`, 105, doc.autoTable.previous.finalY + 30, 'center');

  }else{
    doc.setFontSize(11);
    doc.setTextColor(255, 0, 0);
    doc.text(`NB: `, 105, doc.autoTable.previous.finalY + 30, 'center');
  }


        // Sauvegarder le PDF
        doc.save(`${prenom}_${name}_quittance.pdf`);
      };
 */




      

      const generatePDF = async (
        name, prenom, contact, adresse, montant, total, date, referencePaiement,
        imageUrl, localisation, infoAuto,signatureUrl
    ) => {
        const doc = new jsPDF();
        const reste = total - montant;
    
        // En-tête : Logo et nom de l'auto-école
        try {
            if (imageUrl) {
               
                const base64Logo = await loadImageAsBase64(imageUrl);
               
                doc.addImage(base64Logo, 'PNG', 20, 5, 30, 30);
              
            }
        } catch (error) {
            console.error("Erreur lors du chargement de l'image :", error);
        }
    
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(16);
        doc.setTextColor(0, 51, 102);
        doc.text(`Auto Ecole ${secretaire.map((data) => data.auto_ecole)} `, 105, 15, null, null, 'center');
    
        // Ajouter l'email sous le nom de l'auto-école
        if(mail){
        doc.setFontSize(10);
        doc.text(`Email : ${mail || 'Non renseigné'}`, 105, 20, null, null, 'center');
    }
        // Ajouter la localisation sous l'email
        /* doc.setFontSize(10);
        doc.text(`Localisation : ${localisation || 'Non renseignée'}`, 105, 30, null, null, 'center'); */

        if (localisation) {
        const maxLineWidth = 120; // Largeur maximale pour la ligne, ajustez selon vos marges
    
        // Découpe le texte en plusieurs lignes si nécessaire
        const locationText = ` ${localisation}`;
        const lines = doc.splitTextToSize(locationText, maxLineWidth);
    
        // Dessine chaque ligne sur le PDF
        doc.setFontSize(10);
        doc.setTextColor(0, 51, 102);
        lines.forEach((line, index) => {
            doc.text(line, 105, 25 + (index * 5), null, null, 'center'); // Ajuste l'espacement entre les lignes
        });
    }


        // Cadre pour les informations du candidat
        doc.setDrawColor(0, 51, 102);
        doc.rect(20, 40, 170, 40);
    
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);
        doc.text(`Nom : ${prenom} ${name}`, 25, 50);
        doc.text(`Contact : ${contact || 'Non renseigné'}`, 25, 55);
        doc.text(`Adresse : ${adresse || 'Non renseignée'}`, 25, 60);
    
        doc.text(`Catégorie : Permis ${categorie || 'Non renseigné'}`, 100, 50);
        doc.text(`Langue de formation : ${langue || 'Non renseignée'}`, 100, 55);
        doc.text(`Durée de formation : ${dure || 'Non renseignée'} mois`, 100, 60);
    
        // Ligne de séparation
        doc.line(20, 85, 190, 85);
    
        // Informations de paiement : Date et référence
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);
        doc.text(`Heure: ${heure}:${minutes}:${secondes}`, 25, 90);
        doc.text(`Date : ${moment(date).utc(1).format('DD-MM-YYYY')}`, 25, 95);
        doc.text(`Référence de paiement : ${referencePaiement || 'Non renseignée'}`, 25, 100);
    
        // Tableau des paiements
        const data = [
            ['Frais de Formation', `${total} FCFA`],
            ['Montant payé', `${montant} FCFA`],
            ['Reste à payer', `${reste} FCFA`],
        ];
        doc.autoTable({
            startY: 105,
            head: [['Désignation', 'Valeur']],
            body: data,
            styles: { halign: 'center' },
            headStyles: { fillColor: [0, 51, 102], textColor: [255, 255, 255] },
        });
    
        // Arrêté et Signature
        doc.text(`Arrêté le présent reçu à la somme de : ${montant} FCFA`, 20, doc.autoTable.previous.finalY + 10);
   /*      doc.text('Signature :', 20, doc.autoTable.previous.finalY + 20); */
    
        const dateFormatted = moment(date).utc(1).format('DD-MM-YYYY');
        doc.text(`Fait le ${dateFormatted} par le directeur`, 20, doc.autoTable.previous.finalY + 20);


        // Note en rouge (NB)
        if (NB) {
            doc.setTextColor(255, 0, 0);
            doc.text(`NB : ${NB}`, 20, doc.autoTable.previous.finalY + 30);
        }
        const pageWidth = doc.internal.pageSize.width; // Largeur de la page
        const signatureX = pageWidth - 50; // Décaler pour positionner vers la droite
        doc.setTextColor(0, 51, 102); // Remettre le texte en noir
        doc.text('Signature ', signatureX, doc.autoTable.previous.finalY + 45);
       







        try {
            if (signatureUrl) {
                const signatureImage = await loadImageAsBase64(signatureUrl);
                console.log(signatureImage);
                const pageWidth = doc.internal.pageSize.width; // Largeur de la page
                const signatureX = pageWidth - 66; // Position X pour placer l'image vers la droite
                const signatureY = doc.autoTable.previous.finalY + 46; // Position Y en bas du tableau
                const signatureWidth = 40; // Largeur de l'image
                const signatureHeight = 20; // Hauteur de l'image
    
                doc.addImage(signatureImage, 'JPEG', signatureX, signatureY, signatureWidth, signatureHeight);
            }
        } catch (error) {
            console.error("Erreur lors du chargement de l'image de signature :", error);
        }









        
      // Pied de page
const pageHeight = doc.internal.pageSize.height;
const footerStartY = pageHeight - 10;

// Définir la largeur du pied de page (réduite)
const footerWidth = 180; // Largeur réduite du pied de page
const marginLeft = 15;  // Marge à gauche
const marginRight = 15; // Marge à droite
const extraMarginContact = 10; // Marge supplémentaire avant le premier élément

// Dessiner le fond du pied de page avec la nouvelle largeur
doc.setFillColor(0, 51, 102);
doc.rect(0, footerStartY, 210, 30, 'F'); // Utiliser footerWidth pour la largeur

doc.setTextColor(255, 255, 255);
doc.setFontSize(10);

// Définir les informations du pied de page
const infos = [
    `Contact : ${infoAuto || ' '}`,
    `IFU : ${ifu || ' '}`,
    `RCCM : ${rcm || ' '}`,
];

// Calculer la largeur totale des informations + espacements
let totalWidth = 0;
infos.forEach(info => {
    totalWidth += doc.getTextWidth(info); // Ajouter la largeur de chaque élément
});
const totalSpacing = 50 * (infos.length - 1); // Calculer la largeur totale des espacements

// Ajuster l'espacement entre les informations si elles ne tiennent pas sur la ligne
let maxWidth = footerWidth - (marginLeft + marginRight); // Largeur disponible avec les marges
let currentX = marginLeft + extraMarginContact; // Position de départ X avec marge de gauche et marge supplémentaire
let lineSpacing = 50; // Espacement initial

// Si la largeur totale dépasse la largeur disponible, réduire l'espacement
if (totalWidth + totalSpacing > maxWidth) {
    lineSpacing = (maxWidth - totalWidth) / (infos.length - 1); // Calculer l'espacement nécessaire
}

// Ajouter les informations à la page avec l'espacement ajusté
infos.forEach((info) => {
    doc.text(info, currentX, footerStartY + 6);
    currentX += doc.getTextWidth(info) + lineSpacing; // Avancer la position X avec l'espacement ajusté
});
        // Sauvegarder le PDF
        doc.save(`${prenom}_${name}_quittance.pdf`);
    };
    























// fin facture


useEffect(()=>{
    setTime(`${heure}:${minutes}:${secondes}`);
}, [heure, minutes, secondes])


useEffect(() => {
    if (contact.length === 8) {
      setContact((prev) => '01' + prev);
    }
  }, [contact]);

 

  const [isSubmitting, setIsSubmitting] = useState(false);


    function handleSubmit(event){
        const referencePaiement = generatePaymentReference();
        setInfo('')
        setLoader(true)
        event.preventDefault();



        if (isSubmitting) return; // Empêche une nouvelle soumission

        setIsSubmitting(true); // Désactive le bouton

        if(name=="" || prenom=="" || contact=="" || sexe==""|| montant==""  || adresse=="" || total=="" ){
            setLoader(false)
            setIsSubmitting(false)
            setInfo("UN CHAMP N'EST PAS REMPLI VEILLEZ VERIFIER!!!")
           
            
         }else  if(contact.length<8){
            setLoader(false)
            setIsSubmitting(false)
            setControl('CONTACT INCORECTE')
        }else if(date>autre){
            setLoader(false)
            setIsSubmitting(false)
            setDateControl('Verifier la date')
        }
        else if(montant<=0 || total<=0 || montant-total>0){
            console.log('montant et total', montant,total)
            setLoader(false)
            setIsSubmitting(false)
            setInfo('VEILLEZ VERIFIER LA VALEUR DU MONTANT')
           
         }else  if (!/^\d+$/.test(montant) || !/^\d+$/.test(total)) {
            setLoader(false)
            setIsSubmitting(false)
            setInfo("Veuillez entrer un montant entier sans virgule.");
           

          }
         else{

            genererCredentiels()
            if(username!='' && password!=''){
                const logoUrl = logoA ? `${API_BASE_URL}/get-image?logo=${logoA}` : null;
                /* const yello = 'https://backgestauto.seg.solutions/image-1737705051973.jpg' */
                /* const yello = 'https://backgestauto.seg.solutions/image-1737709967089.jpg' */
                const signatureUrl = sign ? `${API_BASE_URL}/get-image?logo=${sign}` : null;
    if(montant== total){
        axios.post(API_BASE_URL +'/createCandidatDir20', {date,name,prenom,sexe,contact,adresse,total,montant,ido,id_annexe,username,password,dure,langue,referencePaiement,categorie,time}, { headers})
        .then(res => {
            generatePDF(name, prenom, contact, adresse, montant, total, date,referencePaiement,logoUrl,localisation,infoAuto,signatureUrl);
            console.log(res);
            setLoader(false)
            toggleModal()
          
        }).catch(err => console.log(err));
    }else{
        axios.post(API_BASE_URL +'/createCandidatDir', {date,name,prenom,sexe,contact,adresse,total,montant,ido,id_annexe,username,password,dure,langue,referencePaiement,categorie,time}, { headers})
        .then(res => {
            generatePDF(name, prenom, contact, adresse, montant, total, date,referencePaiement,logoUrl,localisation,infoAuto,signatureUrl);
            setLoader(false)
            toggleModal()
          
        }).catch(err => console.log(err));
    }
        
    }
}

    }

    const handleGoBack = () => {
        navigate('/candidatDir/'+o);
      };
    var date2= new Date();
         const now= date2.toLocaleDateString();
         
         
        var years= date2.getFullYear();
         
       var  month= date2.getMonth()+1;
        
        var day= date2.getDate();
        var autre= date2.toISOString().split('T')[0]  
        useEffect(()=>{
            setDate(autre)
        }, [])



const [schoolInfo, setSchoolInfo]= useState([])




        useEffect(()=>{
            if(secretaire.length>0){   
         axios.get(API_BASE_URL +'/schoolInfoDir?id='+secretaire[0].id_autoa+'', { headers })
         .then(res => setSchoolInfo(res.data))
         .catch(err => console.log(err));
         }
         }, [secretaire])
         




         useEffect(()=>{
            if(schoolInfo.length>0){
            const currentDate = new Date();
      const expirationDate = new Date(schoolInfo[0].expiration_date);
      if(expirationDate<currentDate){
      navigate('/candidatDir/'+o)
    }
      }
         }, [schoolInfo])



        let load

        if(loader==true){
            load=   <div className="modal">
                <div className="overlay">
    
                </div>
                   <div className="modal-contentis">
        <div className="anous">
        <Loader/>
        </div>
        </div>
            </div>
           
        }else{
            load=''
        }
    
    return(
        
    <div className="authes">
        <br/> <br/> <br/>
         
        <body className="body">
         
         <div className="container">
         <FontAwesomeIcon icon={faArrowLeft} onClick={handleGoBack} style={{ color: 'white', fontSize: '28px', cursor: 'pointer' }} />
                 <form action="#" onSubmit={handleSubmit}>
                 
                 
                 <select name="poste" className='sele' onChange={e => setAnnexe(e.target.value)} >
            <option >{b}</option>
                     
                {  
                    ville.filter((data) => data.ville!=b).map((data)=>(   
                        
                        <option value={data.ville}>{data.ville}</option>
                            
                    ))
                    
                }
           </select>   
           <button className="btn" onClick={firstSubmit}> APPLIQUER </button>


                 <div className="hai">
             
            
                 <div className="user-input-box">
                 

                 <label htmlFor="fullName" className="">Nom</label>
                 
                 <input type="text" id="fullName" name="fullName" className=""  onChange={e => setName(e.target.value)}  ></input>
                 </div>
                 <div className="user-input-box">
                 <label htmlFor="username" className="">Prenom</label>
                 <input type="text"  className="" name="username" id="username" onChange={e => setPrenom(e.target.value)}></input>
                 </div>
                 
                 <div className="user-input-box">
                 <label htmlFor="contact" className="">Contact</label>
                 <h4 className="text-danger">{control}</h4>
                 <input type="number" name="contact" id="contact" className="" onChange={e => setContact(e.target.value)}></input>
                 </div>
                
                 <div className="user-input-box">
                 <label htmlFor="adresse" className="">Adresse</label>
                 <input type="text" name="adresse" className="" id="adresse" onChange={e => setAdresse(e.target.value)}></input>
                 
                 </div>
                 
                 <div className="user-input-box">
                 <label htmlFor="montantPayer" className="">Montant Payé</label>
                 <input type="number" name="montantPayer" className="" id="montantPayer" onChange={e => setMontant(e.target.value)}></input>
                 
                 </div>
                 <div className="user-input-box">
                 <label htmlFor="fraisDeFormation" className="">Montant Total</label>
                 <input type="number" name="fraisDeFormation" className="" id="fraisDeFormation" onChange={e => setTotal(e.target.value)}></input>
                 
                 </div>
                 
                  
                  <div className="user-input-box">
                  <label htmlFor="date" className="">Date</label>
                  <h4 className="text-danger">{dateControl}</h4>
                 <input type="date" name="date" value={date} id="date" className="" onChange={e => setDate(e.target.value)}></input>
                  </div>

                  <div className="user-input-box">
                     <label htmlFor="langue" className="">Langue</label>
                     <select name="langue" className='' onChange={e => setLangue(e.target.value)} >
             
                     <option value={'ADJA'}>ADJA</option>
                     <option value={'BARIBA'}>BARIBA</option>
                     <option value={'DINDI'}>DINDI</option> 
                     <option value={'FON'}>FON</option>
                     <option value={'Français'}>FRANÇAIS</option>
                     <option value={'GOUN'}>GOUN</option>
                     <option value={'MINA'}>MINA</option>
                     <option value={'YORUBA'}>YORUBA</option>
                            
            </select>
                     </div>

                     <div className="user-input-box">
                     <label htmlFor="langue" className="">Durée de la formation</label>
                     <select name="langue" className='' onChange={e => setDure(e.target.value)} >
                     <option value={'1'}>1 Mois</option>
                     <option value={'2'}>2 Mois</option>
                     <option value={'3'}>3 Mois</option> 
                     <option value={'4'}>4 Mois</option>
                     <option value={'5'}>5 Mois</option>
                     <option value={'6'}>6 Mois</option>
                     <option value={'12'}>12 Mois</option>
                   
         
                            
            </select>
                     </div>


                     <div className="user-input-box">
                     <label htmlFor="sexe" className="">Sexe</label>
                     <select name="sexe" className='' onChange={e => setSexe(e.target.value)} >
             
             <option value={'Masculin'}>Masculin</option>
             <option value={'Feminin'}>Feminin</option>
                              
            </select>
                     </div>


                  </div> 
                  <div>
                 
                  <div className="look"> 
                     <div className="lookTZ"> Catégorie</div>
                     <div className="lookFZ">
                    
                     <select name="categorie" className='' onChange={e => setCategorie(e.target.value)} >
             
             <option value={'A'}>Permis A</option>
             <option value={'A1'}>Permis A1</option>
             <option value={'A1+B'}>Permis A1+B</option>
             <option value={'A1+C+C1'}>Permis A1+C+C1</option>
             <option value={'A3'}>Permis A3</option>
             <option value={'A3+A2'}>Permis A3+A2</option>
             <option value={'A1+A2+A3'}>Permis A1+A2+A3</option>
             <option value={'B'}>Permis B</option>
             <option value={'C'}>Permis C</option>
             <option value={'C1'}>Permis C1</option>
             <option value={'DR'}>Permis DR</option>
             <option value={'D'}>Permis D</option>
             <option value={'E'}>Permis E</option>
             <option value={'F'}>Permis F</option>
                              
            </select>
           
                    </div>
                     </div>



                    <h4 className="text-danger">{info}</h4>
                    </div>
                    <div className="form-submit-btn">
                    <input type="submit" className='' value="ENREGISTRER" disabled={isSubmitting}></input>
                    </div>
                   
                    
                 </form>
            </div> 
             <br/><br/><br/>
             {modal && (<div className='modal'>
    <div className='overlay'>
        
    </div>
    <div className='modal-content3'>
        <div> <h4 className="useName">USERNAME :</h4>  {user}
        <h4 className="useName">PASSWORD :</h4>   {pass}
        </div>
        <Link className='bnfs' to={'/candidatDir/'+o}> <button className="btn-create">D'accord</button>  </Link>
    </div>
</div>)}
             </body>
             {load}
    </div>
    )
   
}