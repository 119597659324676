import React,{ useEffect, useState} from 'react';
import axios from 'axios';
import Navbar from '../components/navbar';

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import Loader from "../components/loader";
import moni from '../components/image/moni.png';
import secre from '../components/image/images.png'
import Footer from '../components/footer';
import { API_BASE_URL } from '../apiConfig';
export default function Code(){
    const [candidat, setCandidat] = useState([])
    const [presence,setPresence]= useState([])
    const [id_cand, setId_cand]= useState([])
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
    const [message,setMessage]=('')
   
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
   
    const [know,setKnow]= useState('')
   
    const navigate = useNavigate();
    const [mie, setMie]= useState(Cookies.get('mie'))
    const [enabledSettings, setEnabledSettings] = useState([]);
    const [error, setError] = useState(null);
    const [loader, setLoader]= useState(true)
    const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
    const [viewLoader, setViewLoader] = useState(false)

    useEffect(()=>{
        axios.get(API_BASE_URL +'/condCode?id='+mie+'', { headers})
        .then(res =>{
             setCandidat(res.data)
             setLoader(false)
        })
        .catch(err =>{
             console.log(err)
             setError(err)
             setLoader(false)
        });
    }, [])
          
    
    const [secretaire, setSecretaire] = useState([])
    useEffect(()=>{
        
       
        axios.get(API_BASE_URL +'/moniInfo?id='+gestauto+'', { headers})
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
    }, [])
       
    
    
     const [id_ecole]= secretaire.map((data)=>(data.id_ecole));
    

     const [query, setQuery]= useState(""); 
    
         let exp

         var date= new Date();
         const now= date.toLocaleDateString();
        const years= date.getFullYear()
       const  month= date.getMonth()+1
        const day= date.getDate()
     
        var checkboxes = document.querySelectorAll("input[type=checkbox][name=settings]");
       
        const handleCheckboxChange = (candidateId) => {
            setEnabledSettings((prevEnabledSettings) => {
              if (prevEnabledSettings.includes(candidateId)) {
                // Si le candidat est déjà dans enabledSettings, le retire
                return prevEnabledSettings.filter((id) => id !== candidateId);
              } else {
                // Sinon, l'ajoute
                return [...prevEnabledSettings, candidateId];
              }
            });
          };

        const mettreAJourLargeurEcran = () => {
            setLargeurEcran(window.innerWidth);
        
          };
        
           
           
            
            
            useEffect(() => {      
                window.addEventListener('resize', mettreAJourLargeurEcran);      
                return () => {
                  window.removeEventListener('resize', mettreAJourLargeurEcran);        
                };      
              }, []);


        useEffect(()=>{
            axios.post(API_BASE_URL +'/dateCode', {years,month,day,mie}, { headers})
            .then(res => {
               setId_cand(res.data)
         
                setKnow(res.data.message);
                setLoader(false)
                navigate('/code');
            }).catch(err => console.log(err));
        }, [])

                 
     


            function handleSubmit(event){
                setViewLoader(true)
                event.preventDefault();
  
                var heureCourante = date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
                axios.post(API_BASE_URL +'/expCode', {enabledSettings,now,years,month,day,id_ecole,heureCourante}, { headers})
                .then(res => {
                    
                        
                  window.location.reload();
                  
        setViewLoader(false)
                    
     
                    
                    
                }).catch(err =>{
                     console.log(err)
                     setError(err)
                     setViewLoader(false)
                    }); 
               
             
            }
            var tab=[candidat.lenght]
            








            const [schoolInfo, setSchoolInfo]= useState([])


          
            
            useEffect(()=>{
                
                    axios
                    .get(`${API_BASE_URL}/schoolInfo?id=${mie}`, { headers })
                    .then((res) => setSchoolInfo(res.data))
                    .catch((err) => console.error(err));
                
             }, [mie])
            
    
      useEffect(()=>{
                if(schoolInfo.length>0){
                const currentDate = new Date();
          const expirationDate = new Date(schoolInfo[0].expiration_date);
          if(expirationDate<currentDate){
          navigate('/presence')
        }
          }
             }, [schoolInfo])










            let load

            if(viewLoader==true){
                load=   <div className="avt">
                <div className="rin">
                  <div className="chm">
                    <Loader/>
                  </div>
                </div>
              </div>
            }else{
                load=''
            }
           
            if(loader){
                return(
                    <div className="per">
                    
                    <div className="loader">
                    <div className="overlayLo"> 
                        <div className="loaderP">
                        <Loader/>
                        </div>
                        </div>
                        </div>
                        </div>
                )
            }else if(error){
                return (<div>Une erreur est survenue : {error.message}</div>)
            }
else{             
               
    if(largeurEcran>= 510){       
           if(know=='oui'){
            return(
                <>
                <div className='wini'>
                <Navbar/>
                <br/> <br/><br/><br/><br/><br/><br/><br/>
                <h2 className='af'>La liste de presence du {now} du cours de code est enregistré !!! </h2>
                
                <Link to={'/voirCode'} > <button className='ess'>VOIR PRESENCE</button></Link>
                       </div>
                       <Footer/>
                </>
              )
           }   else if(know=='non'){
            return(
                <>
                
                <div className='wini'>
                <Navbar/>
                <h1 className='win'>Liste de présence du cour de code du {now}</h1><br/><br/>
                {load}
                
                <div className="search">
                     <input type="text" className="recherche" name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value.toLowerCase())}></input>
                </div>
                <div className="searche_result">
                    
                   
                </div>
                <br/><br/>
               
            <div className=''>
                <div className=''>
                <div className="card-list"  >
                                 {
                                            candidat.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
                                                <div className="card" onClick={() => handleCheckboxChange(data.id)}>
                                                          <div className="image-container8">
                 <img src={data.image ? `${data.image}` : (data.sexe === 'Masculin' ? moni : secre)} alt="Candidat" className="profile-image" />

         
          </div>
                                          
                                             <div className='info'>
                                                <p className='name'> {data.nom} {data.prenom}</p>
                                             
                                                {/* <p className='contacts'> {data.contact}</p> */}

                                                <a
  href={`tel:+229${
    data.contact.length === 8 ? "01"+ data.contact   : data.contact
  }`}
  className="contact-link"
>
  <p className="contacts">
    
    {data.contact.length === 8 ? "01"+ data.contact   : data.contact}
  </p>
</a>
                                               
                                                <input type='checkbox' value={data.id} className='checko' name='settings' checked={enabledSettings.includes(data.id)}
    ></input>
                
                                                </div>
                                            </div>
                                        
                                            ))
                                          } 
                                 </div>
                </div>
                <h3 className='pesen'>{message}</h3>
              <button type="submit" className='enregistrercond' onClick={handleSubmit} >ENREGISTRER</button> <br/>
        
            </div>
            <br/>
            
            </div>
            <Footer/>
            </>
            )
           } else{
            <>
            <div className='wini'>
            <Navbar/>
            <br/> <br/><br/><br/><br/><br/><br/><br/>
            
                   </div>
                   <Footer/>
            </>
           }

        }else{

            if(know=='oui'){
                return(
                    <>
                    <div className='wini'>
                    <Navbar/>
                    <br/> <br/><br/><br/><br/><br/><br/><br/>
                    <h2 className='af'>La liste de presence du {now} du cours de code est enregistré !!! </h2>
                    
                    <Link to={'/voirCode'} > <button className='ess'>VOIR PRESENCE</button></Link>
                           </div>
                           <Footer/>
                    </>
                  )
               }   else if(know=='non'){
                return(
                    <>
                    
                    <div className='wini'>
                    <Navbar/>
                    <h1 className='win'>Liste de présence du cour de code du {now}</h1><br/><br/>
                    
                    <div className="search">
                         <input type="text" className="recherche" name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value.toLowerCase())}></input>
                    </div>
                    <div className="searche_result">
                        
                       
                    </div>
                    <br/><br/>
                   
                <div className=''>
                <div className="card-list"  >
                                 {
                                            candidat.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
                                                <div className="card" onClick={() => handleCheckboxChange(data.id)}>
                                                             <div className="image-container8">
                 <img src={data.image ? `${data.image}` : (data.sexe === 'Masculin' ? moni : secre)} alt="Candidat" className="profile-image" />

         
          </div>
                                          
                                          
                                             <div className='info'>
                                                <p className='name'> {data.nom} {data.prenom}</p>
                                             
                                                {/* <p className='contacts'> {data.contact}</p> */}


                                                
                                                <a
  href={`tel:+229${
    data.contact.length === 8 ? "01"+ data.contact   : data.contact
  }`}
  className="contact-link"
>
  <p className="contacts">
    
    {data.contact.length === 8 ? "01"+ data.contact   : data.contact}
  </p>
</a>
                                               
                                                <input type='checkbox' value={data.id} className='checko' name='settings' checked={enabledSettings.includes(data.id)}
    ></input>
                
                                                </div>
                                            </div>
                                        
                                            ))
                                          } 
                                 </div>
                                 


                   
                    <h3 className='pesen'>{message}</h3>
                  <button type="submit" className='enregistrercond' onClick={handleSubmit} >ENREGISTRER</button> <br/>
            
                </div>
                <br/>
                
                </div>
                <Footer/>
                </>
                )
               } else{
                <>
                <div className='wini'>
                <Navbar/>
                <br/> <br/><br/><br/><br/><br/><br/><br/>
                
                       </div>
                       <Footer/>
                </>
               }
        }
        }

        }   
     
    

       
    